import {useEffect,useState,useContext,useLayoutEffect} from 'react'
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import {SnackbarContext} from '../SnackbarProvider'



//props.notification :
////[ {notification,von,zu,nft,id} ]
function MySnackbar(props){

    const snackBarContextValue = useContext(SnackbarContext)


    useEffect(()=>{
        setState({ ...state, open: snackBarContextValue != undefined, message: snackBarContextValue, })
    },[snackBarContextValue])


       // Snackbar
       const [state, setState] = useState({ open: false, Transition: Slide, });
       const handleClose = () => { setState({ ...state, open: false }); };
       const handleClick = (Transition) => () => { setState({ open: true, Transition, })}


       return(

        <Snackbar open={state.open} onClose={handleClose} TransitionComponent={state.Transition} message={state.message} key={state.Transition.name} />

       );

}

export default MySnackbar;