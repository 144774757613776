import React,{useState,createContext} from 'react';


export const SnackbarContext = createContext();
export const ChangeSnackbarFunction = createContext();



export function SnackbarProvider(props){

    const [snackbarValue,setSnackbar] = useState()

    console.log(snackbarValue)

    function setSnackbarFunc(notification){
        setSnackbar(notification)
    }

    return <SnackbarContext.Provider value={snackbarValue}>
                <ChangeSnackbarFunction.Provider value={setSnackbarFunc}>
                    {props.children}
                </ChangeSnackbarFunction.Provider>
            </SnackbarContext.Provider>

}