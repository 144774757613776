
import {_web3} from '../web3/Web3'
var web3 = _web3.mcbWallet
const userdata = JSON.parse(sessionStorage.getItem("userdata"))
if(userdata !== null){
    userdata.metamask === true ? web3 = _web3.metamask : web3 = _web3.mcbWallet
}

const mainnet = '0x8641f03B70CadF7B90e6e0766FfE9EF9149B939a'
const sepolia = '0x599E06AcA6CB14D8C6F7164acfFE07Fe6e3B1544'

const userContractAddress = mainnet



const userContractABI=[
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "friend_addr",
				"type": "address"
			}
		],
		"name": "deleteFriend",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			}
		],
		"name": "getFriendsFrom",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "friend_addr",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "friend_name",
						"type": "string"
					}
				],
				"internalType": "struct User.Friend[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getMyFriends",
		"outputs": [
			{
				"components": [
					{
						"internalType": "address",
						"name": "friend_addr",
						"type": "address"
					},
					{
						"internalType": "string",
						"name": "friend_name",
						"type": "string"
					}
				],
				"internalType": "struct User.Friend[]",
				"name": "",
				"type": "tuple[]"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "friend_name",
				"type": "string"
			},
			{
				"internalType": "address",
				"name": "friend_addr",
				"type": "address"
			}
		],
		"name": "updateFriends",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

const UserContract = new web3.eth.Contract(userContractABI,userContractAddress);



export {userContractAddress};
export {UserContract};