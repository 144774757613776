import classes from './ChatPage.module.css';
import React, {useState,useEffect,useContext,useLayoutEffect,useRef} from 'react';
import { useHistory } from "react-router-dom";
import {getAllFriendsPromise} from '../../web3/GetAllFriends'
import FriendListElement from '../../components/chatPage/FriendListElement'
import Message from '../../components/chatPage/Message'
import sendImg from '../../images/send.png'
import backImg from '../../images/zuruck.png'
import addImg from '../../images/plus.png'
import {shortAddr} from '../../web3/LoadingFunctions'
import {loadMessagesFromDB,loadMessagesFromDBEncrypt} from '../../node/cryptoMessages'
import {sendMessageToDB,sendMessageToDBEnrcypt} from '../../node/cryptoMessages'
import {getLatestMessage} from '../../node/cryptoMessages'
import { ethers } from "ethers";
// import {crypto} from'crypto';
import {getPublicKey} from '../../node/databank'

import { Loader } from '@mantine/core';

import {encryptMessage} from '../../encryption'
import Tooltip from '@mui/material/Tooltip';

import SearchBar from './ChatPage_comps/SearchBar';
//popup
import PopupFenster from '../../components/PopupFenster/PopupFenster'
import AddFriendIntegration from '../../components/PopupFenster/AddFriendIntegration'
//mui
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'
//User Context
import {UserContext} from '../../UserProvider'

//Komponenten
import ChatFenster from './ChatPage_comps/ChatFenster'

const crypto = require('crypto');
// amount of Messages which load when load older messages Button pressed


// Message : {message,from,to,date}
function ChatPage(){

    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

    const [friends,setFriends] = useState([])
    const [searchedFriends,setSearchedFriends] = useState([])

    const [selectedFriend,setSelectedFriends] = useState({})
    const [friendIsSelected,setFriendIsSelected] = useState(false) //{friend_name:String, friend_addr:String, blockchain:Boolean, pic:String}

    const [chatOpen,setChatOpen] = useState(false)
    const [openAddModal,setOpenFriendModal] = useState(false)
    const [loading,setLoading] = useState(true)

    const userData = useContext(UserContext)



  

    useEffect(() => {
        if (chatOpen) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'auto';
        }
        return () => {
          // Aufräumen: Beim Unmount den Overflow zurücksetzen
          document.body.style.overflow = 'auto';
        };
      }, [chatOpen]);


    function setSearchedFriendsFunc(searched){
        setSearchedFriends(searched)
    }
    
    function addFriend(){
        setOpenFriendModal(true)
    }
    function closeAddFriend(){
        setOpenFriendModal(false)
        loadFriends()
    }

    function onClose_AfterAddedFriend(){
        setOpenFriendModal(false)
        loadFriends()
    }

    const chatWindowRef = useRef()

    function openChatWindow(){
        if(window.innerWidth<=1000){
            setChatOpen(true)
        }
    }
    function closeChatWindow(){
        setChatOpen(false)
    }


    // Wird aus ChatFenster aufgerufen, wenn ich selber eine Nachricht abschicke
    // Und auch wenn ich eine Nachricht über Reavtive Empfange!!
    function messageSend_toUpdateFrindsList(latestMessage){// latesMessage : {message,from,to,date,id}

        console.log("latestMessage1")

        console.log(latestMessage)

        var friendAddr_whoSent_orReceiveMessage
        if(userData.address === latestMessage.from){
            console.log("Ich habe diese Nachricht gesendet")
            friendAddr_whoSent_orReceiveMessage= latestMessage.to
        }else{
            console.log("Ich habe diese Nachricht empfangen")
            friendAddr_whoSent_orReceiveMessage= latestMessage.from
        }

        const getFriendByAddress = (friendAddr) => {
            return friends.find((friend) => friend.friend_addr === friendAddr);
          };
       
        function calcNewFriendsList(prevFriends){
            // Zuerst den ausgewählten Freund aus dem Array filtern
            const filteredFriends = prevFriends.filter(
                (friend) => friend.friend_addr !== friendAddr_whoSent_orReceiveMessage
            );
            // Den aktualisierten Freund erstellen
            const updatedFriend = {
            ...getFriendByAddress(friendAddr_whoSent_orReceiveMessage),
            latestMessage: latestMessage,
            };
            console.log("messageSend_toUpdateFrindsList")
            console.log([updatedFriend, ...filteredFriends])
            // Den aktualisierten Freund an den Anfang setzen und den Rest anhängen
            return [updatedFriend, ...filteredFriends];
        }

        // Nich Datenbank neu laden sondern State neu setzen
        setFriends((prevFriends) => { //array{friend_name, friend_addr, blockchain, latestMessage }
            return calcNewFriendsList(prevFriends)
        });
        setSearchedFriends((prevFriends) => { //array{friend_name, friend_addr, blockchain, latestMessage }
            return calcNewFriendsList(prevFriends)
        });
    }


    // Load Friend & get latest Message
    async function loadFriends(){
        const allFriendsArray = await getAllFriendsPromise() // return [{friend_name:String, friend_addr:String, blockchain:Boolean} ]
        // SORT 

        // // Never in a loop await !! Load all Asyncrone before and than wait vor allSetteld
        const latesMessageArrayPromise = allFriendsArray.map(friend => getLatestMessage(friend.friend_addr) )
        var latesMessageArray = await Promise.allSettled([...latesMessageArrayPromise])
        latesMessageArray = latesMessageArray.map(ele =>ele.value) // because Promise saves result in .value



        var array = allFriendsArray.map((ele,i)=>{
            var message = latesMessageArray[i]
            var id
            if(message.sendermessage){
                
            }
            else if(message.message===""){ // to sort friendlist, who has last message is on top
                message.id=0
            }
            return {friend_name:ele.friend_name, friend_addr:ele.friend_addr, blockchain:ele.blockchain,latestMessage:message }
        })

        // sort nach ID
        array.sort(function(a,b){return b.latestMessage.id-a.latestMessage.id;})
        // filter double. bacuse blockchain and same followfriend could be in array
        array = array.filter((v, i, a) => a.findIndex(t => (t.friend_addr === v.friend_addr)) === i);

        console.log("arrayFired")

        console.log(array)

        setFriends(array) //{friend_name:, friend_addr, blockchain:,id: ,latestMessage: }
        setSearchedFriends(array)
        if(array.length>0){
            setSelectedFriends(array[0]) //{friend_name:, friend_addr, blockchain:,id: ,latestMessage: }
            setFriendIsSelected(true)
        }
        setLoading(false)
    }

    console.log("selectedFriend")

    console.log(selectedFriend)

    useEffect(() => {
        loadFriends()
    },[])



    // when on MenuFriend clicked
    function selectFriend(pic,friend){ // friend: {friend_name:String, friend_addr:String, blockchain:Boolean}
        friend.pic=pic[0];  // add selectedFriendPicURL: // friend: {friend_name:String, friend_addr:String, blockchain:Boolean, pic:String}
        setSelectedFriends(friend)
        openChatWindow()
        //setCurrentMessages([])

    }



    var key=1
    return (

        <div style={{backgroundColor:theme.color2}} className={classes.container}>

        { openAddModal && <PopupFenster integration={<AddFriendIntegration justFollowFriend onClose_AfterAddedFriend={onClose_AfterAddedFriend} allFriends={friends} />} onCloseClicked={closeAddFriend} text={"Add Friend"}/>}

            <div style={{backgroundColor:theme.color1,border:theme.border,boxShadow: theme.boxShadow,}}  className={classes.container2}>

                {/*Menu */}
                <div style={{borderRight:theme.border}} className={classes.menu}>

                    <div style={{borderBottom:theme.border}} className={classes.menuHeader}>
                        < SearchBar friends={friends} setSearchedFriends={setSearchedFriendsFunc}/>
                        <Tooltip title={"Add Friend!"} placement="top" disableInteractive arrow>
                            <IconButton onClick={addFriend} sx={{marginRight:'10px'}}> <img style={{height:'20px',width:'auto',filter:theme.png}} src={addImg}></img> </IconButton>
                        </Tooltip>
                    </div>

                    {false && <CircularProgress color="inherit" sx={{position: 'relative',left:'50%',transform:'translateX(-50%)' }}/>}

                    {loading && <LinearProgress color="inherit" sx={{backgroundColor:'white'}}/>}
                    {searchedFriends.map(friend =><div key={friend.friend_addr} style={friend.friend_addr===selectedFriend.friend_addr? {backgroundColor:theme.color2} : {backgroundColor:theme.color1}}>
                        <FriendListElement onClick={(pic)=>{selectFriend(pic,friend)}} friend={friend} seleced={selectedFriend}/></div>)}

                </div>

                {/*ChatFenster */}
                {true && 
                    < ChatFenster 
                    chatOpen={chatOpen}
                    chatWindowRef={chatWindowRef} 
                    closeChatWindow ={closeChatWindow}
                    friendIsSelected ={friendIsSelected}
                    selectedFriend ={selectedFriend}
                    messageSend_toUpdateFrindsList={messageSend_toUpdateFrindsList}
                    
                    />
                }


            </div>



        </div>

    );


}

export default ChatPage;