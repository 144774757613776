import React, { useState, useEffect,useContext } from "react";
//ColorTheme - Night Mode
import {themes} from '../ColorTheme'
import {NightContext} from '../NightModeProvider'



export default function useNightMode() {
    const [theme,setTheme] =useState(themes.bright)

    const nightMode = useContext(NightContext)
    useEffect(()=>{ 
        nightMode 
        ? setTheme(themes.dark) 
        : setTheme(themes.bright) 
    },[nightMode])

    return theme
}
  