import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { SiEthereum } from 'react-icons/si';
import { FaUser, FaArrowRight, FaUserSlash, FaSearch } from 'react-icons/fa';

import { getAllFriendsPromise } from '../../../web3/GetAllFriends';
import { themes } from '../../../ColorTheme';
import { NightContext } from '../../../NightModeProvider';

import {getProfilePic} from '../../../customHooks/useProfilePicture'

// Funktion zum Laden der Freunde mit Profilbild
async function loadAllFriends() {
  const allFriends = await getAllFriendsPromise();
  const friendsWithPics = await Promise.all(
    allFriends.map(async (friend) => {
      const profilePicURL = await getProfilePic(friend.friend_addr);
      return { ...friend, profileImage: profilePicURL };
    })
  );
  return friendsWithPics;
}

const FriendList = () => {
  const nightMode = useContext(NightContext);
  const [theme, setTheme] = useState(themes.bright);
  useEffect(() => {
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright);
  }, [nightMode]);

  const history = useHistory();
  const [friends, setFriends] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFriends = async () => {
      const data = await loadAllFriends();
      setFriends(data);
      setLoading(false)
    };
    fetchFriends();
  }, []);

  const formatAddress = (addr) => (addr ? `${addr.slice(0, 6)}...${addr.slice(-4)}` : '');

  const navigateToFriendsPage = () => {
    history.push('/friends');
  };

  const goToProfile = (friend_addr) => {
    history.push(`/profile/${friend_addr}`);
  };

  // **Filtert die Freunde basierend auf dem Suchbegriff**
  const filteredFriends = friends.filter((friend) =>
    friend.friend_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    friend.friend_addr.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div style={{ width: '100%', fontFamily: 'Arial, sans-serif' }}>
      {/* Header mit Navigation */}
      <div
        onClick={navigateToFriendsPage}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          marginBottom: '10px',
        }}
      >
        <h2 style={{ fontSize: '18px', color: theme.font, margin: 10, marginBottom: 0 }}>
          Friends
        </h2>
        <FaArrowRight style={{ color: theme.font, margin: 10, marginBottom: 0 }} size={25} />
      </div>

      {/* Suchfeld */}
      <div style={{width:"calc(100% - 45px)", position: 'relative', marginBottom: '10px' }}>
        <FaSearch
          style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: theme.fontGrey }}
        />
        <input
          type="text"
          placeholder="Search by name or address..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
          style={{
            backgroundColor: theme.boxColor,
            width: '100%',
            padding: '8px 10px 8px 35px',
            borderRadius: '4px',
            border: 'none',
            outline: 'none',
            fontSize: '14px',
            color: theme.font,

          }}
        />
      </div>

 
      <style>
      {`
        .search-input::placeholder {
          color: ${theme.fontGrey}; /* Hier die gewünschte Farbe */
          opacity: 1;
        }
      `}
    </style>

  


      {/* Freundesliste */}
      <div
        style={{
          
          height: '250px',
          borderRadius: '4px',
          padding: '10px',
          overflowY: friends.length > 0 ? 'auto' : 'hidden',
          display: filteredFriends.length === 0 ? 'flex' : 'block',
          alignItems: filteredFriends.length === 0 ? 'center' : 'initial',
          justifyContent: filteredFriends.length === 0 ? 'center' : 'initial',
        }}
      >
        {filteredFriends.length === 0 ? (
          loading ? "Loading..." :
          <FaUserSlash size={50} color={theme.font} title="No friends found" />
        ) : (
          filteredFriends.map((friend, index) => (
            <div
              key={index}
              onClick={() => goToProfile(friend.friend_addr)}
              style={{
                color: theme.font,
                display: 'flex',
                alignItems: 'center',
                marginBottom: '10px',
                paddingBottom: '10px',
                borderBottom: theme.border ? theme.border : '1px solid #f0f0f0',
                cursor: 'pointer',
              }}
            >
              {/* Profilbild */}
              {friend.profileImage ? (
                <img
                  src={friend.profileImage}
                  alt="Profilbild"
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginRight: '10px',
                    objectFit: 'cover',
                  }}
                />
              ) : (
                <div
                  style={{
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    marginRight: '10px',
                    backgroundColor: '#ccc',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FaUser color="#fff" size={20} />
                </div>
              )}
              
              {/* Name und Adresse */}
              <div style={{ flex: 1 }}>
                <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                  {friend.friend_name}
                </div>
                <div style={{ fontSize: '11px', color: '#666' }}>
                  {formatAddress(friend.friend_addr)}
                </div>
              </div>

              {/* Ethereum Icon, falls auf Blockchain */}
              {friend.blockchain && (
                <div title="Saved on Blockchain">
                  <SiEthereum size={20} color="#627eea" />
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FriendList;
