import React, { useState, useEffect, useRef } from 'react';
import NFTFormatEasyOnePage from "../../../components/NFT/NFTFormatEasyOnePage";
import classes from './NFTGallery.module.css';

// Importiere die Bilder als Dateien – die Namen entsprechen den TokenIDs
import cyberWolf from '../../../images/cyberWolf.jpeg';
import moonCritter1 from '../../../images/moonCritter1.jpeg';
import sloth1 from '../../../images/sloth1.jpeg';
import terraCube1 from '../../../images/terraCube1.jpeg';
import terraCube7 from '../../../images/terraCube7.jpeg';
import terraCube10 from '../../../images/terraCube10.jpeg';







// Mapping: TokenID -> importiertes Bild (als URL)
const backgroundImages = {
  "96564728407385792749352005868156158": cyberWolf,
  "1273083555519558940372876645461851338": moonCritter1,
  "104966651827558606329990017359519527": sloth1,
  "377996525810424942086859237288699889": terraCube1,
  "255036050932923760243580297048772145": terraCube7,
  "306333936363846745069533748853794653": terraCube10,


};

const NFTCarousel = (props) => {
  const initialNFTs = [
    "96564728407385792749352005868156158",
    "1273083555519558940372876645461851338",
    "104966651827558606329990017359519527",
    "377996525810424942086859237288699889",
    "255036050932923760243580297048772145",
    "306333936363846745069533748853794653"
  ];

  const [itemsToShow, setItemsToShow] = useState(window.innerWidth < 768 ? 2 : 5);
  const clonesCount = itemsToShow;
  const realCount = initialNFTs.length;
  const [currentIndex, setCurrentIndex] = useState(clonesCount);

  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);
  const [slideWidth, setSlideWidth] = useState(0);
  const autoScrollTimer = useRef(null);

  const [currentBg, setCurrentBg] = useState('');
  const [nextBg, setNextBg] = useState('');
  const nextBgRef = useRef(null);

  const startXRef = useRef(null);

  // Ref zur Messung des ersten echten NFT-Elements
  const firstNFTRef = useRef(null);
  const [nftHeight, setNftHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const newItems = window.innerWidth < 768 ? 2 : 5;
      setItemsToShow(newItems);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCurrentIndex(itemsToShow);
  }, [itemsToShow]);

  const extendedNFTs = [
    ...initialNFTs.slice(-clonesCount),
    ...initialNFTs,
    ...initialNFTs.slice(0, clonesCount)
  ];

  useEffect(() => {
    const updateSlideWidth = () => {
      if (sliderWrapperRef.current) {
        const containerWidth = sliderWrapperRef.current.clientWidth;
        const newSlideWidth = (containerWidth - (itemsToShow - 1) * 7) / itemsToShow;
        setSlideWidth(newSlideWidth);
      }
    };
    updateSlideWidth();
    window.addEventListener("resize", updateSlideWidth);
    return () => window.removeEventListener("resize", updateSlideWidth);
  }, [itemsToShow]);

  // Messe die tatsächliche Höhe des NFT-Elements (natürliche Höhe)
  useEffect(() => {
    const updateNFTHeight = () => {
      if (firstNFTRef.current) {
        setNftHeight(firstNFTRef.current.clientHeight);
      }
    };
    updateNFTHeight();
    window.addEventListener("resize", updateNFTHeight);
    return () => window.removeEventListener("resize", updateNFTHeight);
  }, [slideWidth, itemsToShow]);

  const scheduleAutoScroll = () => {
    if (autoScrollTimer.current) clearTimeout(autoScrollTimer.current);
    autoScrollTimer.current = setTimeout(() => {
      setCurrentIndex(prev => prev + 1);
      scheduleAutoScroll();
    }, 5000);
  };

  const nextSlide = () => {
    setCurrentIndex(prev => prev + 1);
    scheduleAutoScroll();
  };

  const prevSlide = () => {
    setCurrentIndex(prev => prev - 1);
    scheduleAutoScroll();
  };

  const handleTransitionEnd = () => {
    if (currentIndex >= realCount + clonesCount) {
      if (sliderRef.current) sliderRef.current.style.transition = "none";
      setCurrentIndex(clonesCount);
      setTimeout(() => {
        if (sliderRef.current) sliderRef.current.style.transition = "transform 0.5s ease";
      }, 50);
    }
    if (currentIndex < clonesCount) {
      if (sliderRef.current) sliderRef.current.style.transition = "none";
      setCurrentIndex(realCount + clonesCount - 1);
      setTimeout(() => {
        if (sliderRef.current) sliderRef.current.style.transition = "transform 0.5s ease";
      }, 50);
    }
  };

  const totalSliderWidth = extendedNFTs.length * slideWidth + (extendedNFTs.length - 1) * 7;

  useEffect(() => {
    const leftTokenId = extendedNFTs[currentIndex];
    const newBg = backgroundImages[leftTokenId] || "";
    if (newBg && newBg !== currentBg) {
      setNextBg(newBg);
    }
  }, [currentIndex, extendedNFTs, currentBg]);

  useEffect(() => {
    if (nextBg && nextBgRef.current) {
      nextBgRef.current.style.opacity = 1;
      const handleFadeEnd = () => {
        setCurrentBg(nextBg);
        setNextBg("");
      };
      nextBgRef.current.addEventListener("transitionend", handleFadeEnd, { once: true });
    }
  }, [nextBg]);

  useEffect(() => {
    scheduleAutoScroll();
    return () => {
      if (autoScrollTimer.current) clearTimeout(autoScrollTimer.current);
    };
  }, [slideWidth, itemsToShow]);

  const handleTouchStart = (e) => {
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (startXRef.current === null) return;
    const diff = e.changedTouches[0].clientX - startXRef.current;
    if (Math.abs(diff) > 50) {
      diff > 0 ? prevSlide() : nextSlide();
    }
    startXRef.current = null;
  };

  const handleMouseDown = (e) => {
    startXRef.current = e.clientX;
  };

  const handleMouseUp = (e) => {
    if (startXRef.current === null) return;
    const diff = e.clientX - startXRef.current;
    if (Math.abs(diff) > 50) {
      diff > 0 ? prevSlide() : nextSlide();
    }
    startXRef.current = null;
  };

  const handleMouseLeave = () => {
    startXRef.current = null;
  };


  
// ...
return (
    <div className={classes.carouselContainer } style={props.verschoben? {position: "relative",top: "-70px", marginBottom: "-70px" }:{}}>
      <div className={classes.background}>
        {currentBg && (
          <img src={currentBg} alt="Background NFT" className={classes.backgroundImage} />
        )}
        {nextBg && (
          <img
            ref={nextBgRef}
            src={nextBg}
            alt="Background NFT"
            className={`${classes.backgroundImage} ${classes.nextBackground}`}
            style={{ opacity: 0 }}
          />
        )}
      </div>
  
      <div className={classes.overlay}>
        <div className={classes.carousel}>
          {/* Button zum Vorherigen – einfaches SVG-Pfeil-Icon */}
          <div
            onClick={prevSlide}
            className={classes.button}
            style={{ height: nftHeight ? `${nftHeight}px` : 'auto' }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="15 18 9 12 15 6" />
            </svg>
          </div>
          <div
            className={classes.sliderWrapper}
            ref={sliderWrapperRef}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <div
              className={classes.slider}
              ref={sliderRef}
              style={{
                width: `${totalSliderWidth}px`,
                transform: `translateX(-${currentIndex * (slideWidth + 7)}px)`,
                transition: "transform 0.5s ease"
              }}
              onTransitionEnd={handleTransitionEnd}
            >
              {extendedNFTs.map((tokenId, index) => (
                <div
                  key={`${tokenId}-${index}`}
                  className={classes.nftWrapper}
                  style={{ width: `${slideWidth}px` }}
                  ref={index === clonesCount ? firstNFTRef : null}
                >
                  <NFTFormatEasyOnePage tokenId={tokenId} />
                </div>
              ))}
            </div>
          </div>
          {/* Button zum Nächsten – einfaches SVG-Pfeil-Icon */}
          <div
            onClick={nextSlide}
            className={classes.button}
            style={{ height: nftHeight ? `${nftHeight}px` : 'auto' }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                 stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <polyline points="9 18 15 12 9 6" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
  // ...
  
};

export default NFTCarousel;
