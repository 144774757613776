import React from 'react';
import { Send, ArrowDownUp, Upload, History } from 'lucide-react';
import classes from './WalletAuswahl.module.css';

import useNightMode from '../../customHooks/useNightMode';

import HistoryView from './HistoryView';
import AmountView from './AmountView';
import DepositView from './DepositView'
import SendViewAuswahl from './SendViewAuswahl';
import SendView from './SendView';

import {shortAddr} from '../../web3/LoadingFunctions';









const WalletAuswahl = (props) => {

    function onClickBack(){
      props.setCurrentView(prev => ({current: false, before: prev.current}))
    }

    function backToSendViewAuswahl(){
      props.setCurrentView(prev => ({current:<SendViewAuswahl onClickBack={onClickBack} onFriendClick={onFriendClick}/>, before: prev.current}))

    }


    function onFriendClick(friend){ // wird aus SendViewAuswahl aufgerufen

      props.setCurrentView(prev => ({current:<SendView onClickBack={backToSendViewAuswahl} longAddr={friend.friend_addr} addr={shortAddr(friend.friend_addr)} friendName={friend.friend_name} text={`Send Ether to ${friend.friend_name}`} />
      , before: prev.current}))
  }

    function handleClick(text){
        if(text=== "history"){
          props.setCurrentView(prev =>({current:<HistoryView onClickBack={onClickBack}/>, before: prev.current}))
        }
        if(text=== "deposit"){
          props.setCurrentView(prev =>({current:<DepositView onClickBack={onClickBack}/>, before: prev.current}))
        }
        if(text=== "send"){
          props.setCurrentView(prev =>({current:<SendViewAuswahl onClickBack={onClickBack} onFriendClick={onFriendClick}/>, before: prev.current}))

      }

        

    }

    
  return (
    <div className={classes.container}>
       <AuswahlButton onClick={handleClick}   
                        icon={<Send size={24} />} 
                        text={"send"} />  

       <AuswahlButton   onClick={handleClick}   
                        icon={<ArrowDownUp size={24} />} 
                        text={"swap"} />  

       <AuswahlButton   onClick={handleClick}   
                        icon={<Upload size={24} />} 
                        text={"deposit"} />     

       <AuswahlButton   onClick={handleClick}   
                        icon={<History size={24} />} 
                        text={"history"} />            
    </div>
  );
};


function AuswahlButton(props) {
    const theme = useNightMode()

return(
    <div className={classes.actionButton} >
        <button onClick={()=>{props.onClick(props.text)}} className={classes.squareButton} style={{backgroundColor: theme.color2, color: theme.font}}>
          {props.icon}
        </button>
        <span className={classes.actionLabel} style={{ color: theme.font}} >{props.text}</span>
      </div>
)

}

export default WalletAuswahl;
