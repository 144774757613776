import React, { useState, useEffect } from "react";
import {getProfilePicURL} from '../node/images'

import getEthereumAvatar from "./useEthereumBlockies";
import { useMemo } from 'react';
import jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import StandartProfilPic from '../images/background.jpeg';
import blockies from 'ethereum-blockies';



//address = address or = "me"
export default function useProfilePic(address) {

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const [profilePic,setProfilePic] = useState()

    useEffect(()=>{

        const avatar = getEthereumAvatar(address)
        getProfilePicURL(address).then(url =>{  
            if(url === StandartProfilPic)  {
                setProfilePic(url)   
            }else{
                setProfilePic(url)   
            }   
        })


    },[address])

    return profilePic
}
  


export async function getProfilePic(address) {
    const userdata = JSON.parse(sessionStorage.getItem("userdata"))

    if(!address){
        return StandartProfilPic
    }
    
    const avatar = getEthereumAvatar(address === "me" ? userdata.address : address)


    const profilePic = await getProfilePicURL(address === "me" ? userdata.address : address)

    if(profilePic == StandartProfilPic){
        return profilePic
    }else{
        return profilePic
    }
}





