import classes from './FriendListElement.module.css';
import React, { Component,useContext } from 'react';
import {useState,useEffect,useRef,useLayoutEffect} from 'react';
import useProfilePicture from '../../customHooks/useProfilePicture';
import useNightMode from '../../customHooks/useNightMode';
import {shortAddr} from '../../web3/LoadingFunctions'

//material UI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';

//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

// props.message {friend_name:, friend_addr:, blockchain:,id: ,latestMessage: }

 function FriendListElement(props){

    const theme = useNightMode()
    const pic = useProfilePicture(props.friend.friend_addr)

        
    return(
        <div onClick={()=>props.onClick(pic)} style={{borderBottom:theme.border}} className={classes.container}>

            <Avatar sx={{height:'33px',width:'33px',marginLeft:'10px',marginRight:'10px'}} src={pic} />

            <div className={classes.wrapper1}>

                <div style={{display:"flex",gap:"4px"}}>
                <div style={{fontWeight:'bold',fontSize:'11px',color:theme.font}}>{props.friend.friend_name.slice(0,20) } </div>
                <div style={{fontSize:'9px',color:theme.fontGrey}}> {shortAddr(props.friend.friend_addr)}</div>
                </div>
                <div style={{fontSize:'11px',color:theme.font}}>
                    {props.friend.latestMessage.sendermessage ? "???" : props.friend.latestMessage.message.slice(0,25) } 
                    
                </div>

            </div>

            <div style={{color:theme.font}} className={classes.date}>
                {props.friend.latestMessage.date}
            </div>
        </div>
    );
}

export default FriendListElement;