import classes from './Marketplace.module.css';
import {useState,useEffect,useContext} from 'react'

import NFTUbersicht_Market from './Marketplace/NFTUbersicht_Market'

import ImpressumShort from '../../components/start/startPageComponents/Impressum'




import useNightMode from '../../customHooks/useNightMode';

import NFTGallery from './Marketplace/NFTGallery';


function Marketplace(){
    
    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    const theme = useNightMode()
        
        return(
            <>
            <div className={classes.container}>
                {true && <NFTGallery verschoben/> }

                {true && <NFTUbersicht_Market header = "Marketplace"/> }


            </div>
            
            </>
    );

}

export default Marketplace;