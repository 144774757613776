import React, { useState, useEffect, useContext } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { themes } from '../../../ColorTheme';
import { NightContext } from '../../../NightModeProvider';

const NewsLearnWidget = () => {
  // Night Mode
  const nightMode = useContext(NightContext);
  const [theme, setTheme] = useState(themes.bright);
  useEffect(() => {
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright);
  }, [nightMode]);

  // Entries for the News / Learn Widget with title, description, and external link
  const entries = [

    {
      title: "How does MyCryptoBook work?",
      description: "Learn about the inner workings of MyCryptoBook on Gitbooks.",
      link: "https://mycryptobook.gitbook.io/untitled", // Replace with your actual Gitbooks link
    },
    {
      title: "Blockchain News",
      description: "Stay updated with the latest blockchain news from CoinDesk.",
      link: "https://www.coindesk.com",
    },
    {
      title: "Crypto Learning",
      description: "Expand your knowledge with crypto insights from Cointelegraph.",
      link: "https://www.cointelegraph.com",
    },
  ];

  return (
    <div
      style={{
        width: '100%',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      {/* Non-clickable header */}
      <h2 style={{ fontSize: '18px', color: theme.font, margin: 10, marginBottom: '10px' }}>
        News / Learn
      </h2>
      <div
        style={{
          maxHeight: '250px',
          overflowY: 'auto',
          padding: '10px',
        }}
      >
        {entries.map((entry, index) => (
          <div
            key={index}
            onClick={() => window.open(entry.link, '_blank')}
            style={{
              color: theme.font,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginBottom: '10px',
              paddingBottom: '10px',
              borderBottom: `1px solid ${theme.border ? theme.border : '#f0f0f0'}`,
              cursor: 'pointer',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <span style={{ fontSize: '14px', fontWeight: 'bold' }}>{entry.title}</span>
              <FaExternalLinkAlt size={16} color={theme.font} />
            </div>
            <div style={{ fontSize: '12px', color: '#666', marginTop: '4px' }}>
              {entry.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsLearnWidget;
