import classes from './NoMetaMaskPage.module.css';



function NoMetaMaskPage(){
    //test


    return (
        <div className={classes.container}>

            No MetaMask
        </div>

    );


}

export default NoMetaMaskPage;