import classes from './NFTFormatEasyOnePage.module.css';
import herz from '../../images/herz.png';
import ethereum from '../../images/ethereum.png';
import offchainPic from '../../images/fokus.png';
import {useHistory,useLocation} from 'react-router-dom';
import {useState,useEffect} from 'react';
import redHerz from '../../images/redherz.png';
import blackHerz from '../../images/backherz.png';
import {getNFTLikes,likeNFT,dislikeNFT,doILike} from '../../node/NFTLikes';
import {getTokenUri,getAllMetadataFromURI,checkIfTokenExists} from '../../web3/NFTContractHelper'
import {getTokenURIDB,getPreisOfNFT, nftFormat_api} from '../../node/NFTData'
import React,{useContext,useRef} from "react";
import {getNFTImageServerURL} from '../../node/images.js'

//material UI
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';



import useNightMode from '../../customHooks/useNightMode';

import {ChangeSnackbarFunction} from '../../SnackbarProvider'

//popup
import PopupFenster from '../PopupFenster/PopupFenster'
//import LoginIntegration from '../PopupFenster/LoginIntegration'
import LoginIntegration from '../../components/aufklappMenu/LoginIntegration'


// Überarbeitung nur eine einzige DB Abfrage!: 
// NFTFormat braucht: Name, Collection, iLike, On/Offchain/ Image(tokenId)
// Tabellen: nftinfo, likes


// input just token ID as props: props.tokenId
// loads image from server 
//props.disableClick
function NFTFormatEasyOnePage(props){ // das einzelne NFT

    const renderCount = useRef(0);
    renderCount.current += 1;

    useEffect(() => {
        console.log(`Komponente gerendert: ${renderCount.current} Mal`);
    });

    const changeSnackbar = useContext(ChangeSnackbarFunction)
    const theme = useNightMode()
    const history =useHistory();
    const location = useLocation();
    const [loginPageIsOpen,setloginPageIsOpen] = useState(false)
    const [allData,setAllData] = useState({ name:"", 
                                            collection:"", 
                                            liked_by_me:false,
                                            like_count: 0,
                                            tokenid:"",
                                            metaurl:"",
                                            offchain: true,
                                            imageIsLoaded: false,
                                            imageURL: false
                                        });

    async function loadAllData(){
        var [res, onchain, imageUrl] = await Promise.all([
            nftFormat_api(props.tokenId), // nftinfo // Liefert {collection, name, metaurl, liked_by_me, like_count, tokenid}
            checkIfTokenExists(props.tokenId), // onchain? from blockchain
            getNFTImageServerURL(props.tokenId) // imageURL from server
        ]);
        res.offchain = !onchain
        res.imageIsLoaded = true
        res.imageURL = imageUrl
        setAllData(res)
    }
    useEffect(()=>{
        loadAllData()
    },[props.tokenId])

 

    function openThisNFTPage(){
        if (props.disableClick) {return}
        history.push({
            pathname:"/thisNFT/"+props.tokenId,
        });
    }
    function checkIfLogin(){
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        return userdata !== null
    }

    // like, dislike
    function likeNFTFunc(){

        if(!checkIfLogin()){ // if not login
            setloginPageIsOpen(true)
            return
        }

        likeNFT(props.tokenId); // db



        setAllData(prev =>({...prev, liked_by_me:true, like_count: parseInt(prev.like_count)+1}))

        if(props.iLikedNFT){
            props.iLikedNFT()
        }

    }
    function dislikeNFTFunc(){
        // check if login
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        if(userdata === null || userdata === undefined){return}
        dislikeNFT(props.tokenId); // db

        setAllData(prev =>({...prev, liked_by_me:false, like_count: parseInt(prev.like_count)-1 }))


        if(props.iDisLikedNFT){ // for oneNFTPage
            props.iDisLikedNFT()
        }
        

    }


  

    return (

        <div className={classes.container} >

<div>

        </div>

           { loginPageIsOpen && <PopupFenster integration={<LoginIntegration nextPage={location.pathname} onCloseClicked={()=>{setloginPageIsOpen(false)}} />} onCloseClicked={()=>{setloginPageIsOpen(false)}} text={"Connect Wallet"}/>   }


            {/*NFT IMAGE */}
            {!allData.imageIsLoaded && <div className={classes.placeholder}>  </div>}
           {allData.imageIsLoaded && <img src={allData.imageURL} className={classes.NFTimage} onClick={openThisNFTPage}></img>   }
            {/*NFT IMAGE */}


            <div style={{backgroundColor: theme.color2}} className={classes.bottom}>

                <div style={{color: theme.font}} className={classes.nameAndFrom}> {allData?.name}</div>
                <div style={{color: theme.font}} className={classes.nameAndNumber}>{allData?.collection }</div>


                

            </div>

            <div  style={{backgroundColor: theme.color3}} className={classes.bottom2}>




                    <Tooltip title={allData.offchain===false ? "Ethereum":"Offchain"} placement="top" disableInteractive arrow>
                            <IconButton sx={{filter: theme.png}}  size="small">
                                   <img src={allData.offchain===false ? ethereum: offchainPic} style={{height:'15px', width:'auto'}}></img>
                            </IconButton>
                    </Tooltip>




                <div className={classes.likesWrapper}>

                <Tooltip title={!allData.liked_by_me ? "like":"dislike"} placement="top" disableInteractive arrow>
                        <IconButton onClick={!allData.liked_by_me ? likeNFTFunc : dislikeNFTFunc} size="small" sx={{opacity:'0.5', "&:hover":{opacity:'1'} }}>
                               <img src={!allData.liked_by_me ? blackHerz: redHerz} style={{height:'15px', width:'auto'}}></img>
                        </IconButton>
                </Tooltip>

                    <div style={{color: theme.font}} className={classes.numberlikes}> {allData.like_count} </div>
                </div>

            </div>


        </div>





    );




}

export default NFTFormatEasyOnePage;