import classes from './Message.module.css';
import React, { Component,useContext } from 'react';
import {useState,useEffect,useRef,useLayoutEffect} from 'react';

//material UI
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';

import LockToggle from '../../pages/home/ChatPage_comps/LockToggle';

import {decryptedMessage_Metamask,decryptMessage_MCBWallet} from '../../encryption'

//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

// props: date, message, person

// props.encryptAllWithPassword --> password wurde in ChatFenster.js gesetzt 
// props.message.justSendEncrypted_butUnlocked = true
// 
 function Message(props){

    console.log(props)


        // Night Mode
        const nightMode = useContext(NightContext)
        const [theme,setTheme] =useState(themes.bright)
        useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

        const [decryptMessage,setDecryptMessage] =useState({sender:"", receiver:""})
        const [amIDecrypted,setAmIDecrypted] =useState(!props.item.sendermessage)
        const [anzeigeNachricht,setAnzeigeNachricht] =useState("")


        useEffect(()=>{
            //bin ich verschlüsselt?
            if(props.item.sendermessage){ // if bin verschlüsselt
                setAnzeigeNachricht("???")

                if(props.encryptAllWithPassword){ // Ich werde entschlüsselt //
                    console.log(props.encryptAllWithPassword)
                    setDecryptedMessages(props.encryptAllWithPassword) // enthält passwort und startet wenn Popupwindow mit pw closed
                    setAmIDecrypted(true)
                }
            }else{
                setAnzeigeNachricht(props.message)
            }
        },[props.encryptAllWithPassword])
         
        useEffect(()=>{
            //bin ich verschlüsselt?
            if(props.metamaskDecryptonClicked){
                setDecryptedMessages("kein Passwort, da Metamask")
                

            }
            
        },[props.metamaskDecryptonClicked])


   




        // props.item : {sendermessage, receivermessage, von, zu, message ...}
        async function setDecryptedMessages(password){
            const userdata = JSON.parse(sessionStorage.getItem("userdata"))
            const myAddress = userdata.address /// HIER FEHERLRLERLRLEL
            
            
            if(props.person === "me" && props.item.sendermessage){ // Diese nachricht habe ich gesendet (Sendermessage)
                
                var senderMessage
                if(userdata.metamask){ // Metamask
                    senderMessage = await decryptedMessage_Metamask(props.item.sendermessage, myAddress)
                    setAmIDecrypted(true)
                }else{ // MCB WALLET
                    senderMessage = await decryptMessage_MCBWallet(props.item.sendermessage, myAddress,password) // die Sender nachricht. Also meine anchricht wird mit meinen privateKEy entschlüsselt
                }
                setDecryptMessage(prev => ({...prev, sender:senderMessage}) )
                setAnzeigeNachricht(senderMessage)

            }else if (props.person !== "me" && props.item.receivermessage){ // Diese nachricht  empfange ich (Receiver-message)
                var receiverMessage 
                if(userdata.metamask){ // Metamask
                    receiverMessage = await decryptedMessage_Metamask(props.item.receivermessage, myAddress)
                    setAmIDecrypted(true)
                }else{ // MCB WALLET
                    receiverMessage = await decryptMessage_MCBWallet(props.item.receivermessage, myAddress,password)
                }
                setDecryptMessage(prev => ({...prev, receiver:receiverMessage}) )
                setAnzeigeNachricht(receiverMessage)
            }
        }

        var alignStyle="start"
        var background='rgba(0, 0, 0, 0.051)'
        if(props.person==="me"){
            alignStyle = "end"
            var background='rgba(0, 110, 253, 0.151)'
        }


    return(
        <div className={classes.container} style={{border:theme.border,alignSelf:alignStyle,backgroundColor:background}}>

            <div className={classes.top} style={{borderBottom:theme.border,color:theme.font}}>

                {props.person==="me" ? "me": props.person} {"on " +props.date}
                
                 {(props.item.sendermessage || props.item.justSendEncrypted_butUnlocked) && <LockToggle decryptButtonClicked={props.decryptButtonClicked} state={!amIDecrypted}/>}


            </div>

            <div className={classes.bottom} style={{color:theme.font}}>
                <div>  {anzeigeNachricht}  </div>
            </div>



        </div>

    );
}

export default Message;