import {useState,useEffect,useContext,useRef} from 'react'

//props.iAmVisible()


// MyVisible erhöht Offset. Offstet wird hier verwaltet
const MyVisible = (props) => {
  const elementRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false); // Zustand für Scroll-Erkennung

  useEffect(() => {
    // Funktion zur Scroll-Erkennung
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setHasScrolled(true);
        window.removeEventListener("scroll", handleScroll);
      }
    };

    window.addEventListener("scroll", handleScroll);

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasScrolled) {
          console.log("Element ist sichtbar!"); // Hier deine Funktion aufrufen
          props.iAmVisible()
        }
      },
      { threshold: 0.5 } // 50% des Elements müssen sichtbar sein
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]); // Achtung: useEffect wird erneut ausgeführt, wenn `hasScrolled` sich ändert

  return (
    <div style={{ height: "2px", width: "50px", padding: "" }}>
      <div ref={elementRef} style={{ background: "lightblue", height: "2px", textAlign: "center" }}>
        
      </div>
    </div>
  );
};



export default MyVisible;