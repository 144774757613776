
import classes from './App.module.css';
import { Route, Switch } from 'react-router-dom';
// Start
import StartPage from './pages/StartPage';
import NavBar from './components/start/StartNavBar';
//Home
import Home from './pages/home/Home';
//import NavBarHome from './components/home/NavBarHome';
import NavBarHome from './components/home/Navbar4';
import Profil from './pages/home/Profil';
import FriendsPage from './pages/home/FriendsPage';
import OneNFTPage from './pages/home/OneNFTPage';
import FriendProfil from './pages/home/FriendProfil';
import MyNftPage from './components/NFT/MyNftPage'
import CreateNFT from './components/NFT/createNFT/CreateNFT';
import FinishedNFT from './components/NFT/finishedNFTModal/FinishedNFT';
import CollectionPage from './pages/home/CollectionPage';
import Marketplace from './pages/home/Marketplace'
import NoMetaMaskPage from './pages/home/NoMetaMaskPage'
import ChatPage from './pages/home/ChatPage'
import LoginPage from './pages/home/LoginPage'
import ImpressumShort from './components/start/startPageComponents/Impressum'
import Impressum from './components/start/startPageComponents/Impressum'
import MySnackbar from './components/MySnackbar'

import Overview from './pages/home/Overview';

//Wallet 
import Wallet from './components/wallet/Wallet'
import SendView from './components/wallet/SendView'
import ApprovalView from './components/wallet/ApprovalView'

//Night Mode
import {NightModeProvider} from './NightModeProvider'
import {UserProvider} from './UserProvider'
import {SnackbarProvider} from './SnackbarProvider';
import {OpenLoginProvider} from './OpenLoginProvider';


function App() {

  const userdata = JSON.parse(sessionStorage.getItem("userdata"))

  return(
    <div>
      <UserProvider>
      <NightModeProvider>
      <SnackbarProvider>
      <OpenLoginProvider>

      <MySnackbar message="asd"/>

      <Switch>
        <Route path='/' exact>
        <NavBar/>
          <StartPage />
        </Route>

        <Route path='/home'>
        <NavBarHome/>


        <Overview />
        < ImpressumShort />

        </Route>

        <Route path='/me'>
        <NavBarHome/>
          <Profil />
      < ImpressumShort />

        </Route>

        <Route path='/friends/'>

        <NavBarHome/>
          <FriendsPage />
      < ImpressumShort />

        </Route>

        <Route path='/profile/:address'>
        <NavBarHome/>
          <FriendProfil/>
      < ImpressumShort />

        </Route>

        <Route path='/MyNft'>
          <NavBarHome/>
          <MyNftPage/>
          <ImpressumShort />
        </Route>

        <Route path='/createNFT'>
          <NavBarHome/>
          <CreateNFT />
          <ImpressumShort />
        </Route>

        <Route path='/finishedNFT'>
          <NavBarHome/>
          <FinishedNFT />
        </Route>

        <Route path='/thisNFT/:tokenId'>
        { /* JSON.parse(sessionStorage.getItem("userdata"))===null ? <NavBar/> : <NavBarHome/> */}
          <NavBarHome/>
          <OneNFTPage />
          < ImpressumShort />
        </Route>

        <Route path='/collection/:collectionName'>
          <NavBarHome/>
          <CollectionPage />
          < ImpressumShort />
        </Route>

        <Route path='/marketplace/'>
          <NavBarHome/>
          <Marketplace />
          <ImpressumShort />
        </Route>


        <Route path='/chats'>
          <NavBarHome/>
          <ChatPage />
        </Route>


        <Route path='/login'>
          <LoginPage />
        </Route>
      </Switch>



      </OpenLoginProvider>
      </SnackbarProvider>
      
      </NightModeProvider>
      </UserProvider>
    </div>
  );

}

export default App;

