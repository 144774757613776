import React, { useState ,useContext,useEffect} from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import mailsafe from '../../../images/mailsafe.png'
import maillock from '../../../images/maillock.png'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'



function EncryptionToggleButton({ onToggle,encryptionMode }) {
  const [enabled, setEnabled] = useState(encryptionMode);

  useEffect(()=>{
    setEnabled(encryptionMode)
  },[])

    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  const handleToggle = () => {
    const newValue = !enabled;
    setEnabled(newValue);
    if (onToggle) {
      onToggle(newValue);
    }
  };

  // Basisstil für den Button
  const baseButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '5px ',
    backgroundColor: '#f5f5f5',
    border: '2px solid #ccc',
    borderRadius: '8px',
    cursor: 'pointer',
    transition: 'background-color 0.3s, border-color 0.3s',
    fontSize: '11px',

  };

  // Stil, wenn der Button aktiviert ist
  const enabledStyle = {
    backgroundColor: '#c8e6c9',
    borderColor: '#66bb6a',
  };

  // Kombinierter Stil (hier könnte man auch weitere Zustände berücksichtigen)
  const buttonStyle = enabled
    ? { ...baseButtonStyle, ...enabledStyle }
    : baseButtonStyle;

  // Stil für den Text neben dem Icon
  const textStyle = {
    marginLeft: '8px',
    fontWeight: 'bold',
  };


  return (
    // <button style={buttonStyle} onClick={handleToggle}>
    //   {enabled ? <FaLock style={{fontSize:"20px"}}/> : <FaLockOpen style={{fontSize:"20px"}}/>}
    //   <span style={textStyle}>
    //     {enabled ? 'Send encrypted message' : 'Send normal message'}
    //   </span>
    // </button>


<div style={{backgroundColor: theme.color1,display:"flex",alignItems:"center",justifyContent:"center"}}>
  <Tooltip title={enabled ? "Sent messages will be encrypted ":"Sent messages will be not encrypted"} placement="top" disableInteractive arrow>
    <IconButton onClick={handleToggle} style={{fontSize: "28px",height: "50px",width: "50px"}}> {enabled ? <FaLock style={{color:"green",marginBottom:"7px"}} />: <FaLockOpen style={{color:theme.font,marginBottom:"7px"}}/>} </IconButton> 
  </Tooltip>
</div>

  );
}

export default EncryptionToggleButton;
