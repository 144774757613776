import classes from './LikesIntegration.module.css';

import ListElement from './ListElement';

function LikesIntegration(props){

    console.log("props")


    console.log(props.likesList)

   //[{address:},{address:}]

    return (

        <div className={classes.integration}>

            {props.likesList.map(item => <ListElement closePopup={props.closePopup} likesItem={item} />   )}

        </div>

    );


}

export default LikesIntegration;