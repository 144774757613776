import React, { useState, useRef, useEffect,useContext } from 'react';
import classes from './NFTFilter.module.css';

import filterImg from '../../../images/filter.png'
import sortierenImg from '../../../images/sortieren.png'





import {getAllSingles,getTokenIdFromSearchLimit,
    getAllSinglesOnChain,
    getAllSinglesOffChain,
    getAllSinglesMyLikes,
    getAllSinglesMostLikes,
    getAllSinglesRecency
} from '../../../node/NFTData';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'

const LIMIT_LOAD = 15

//alignment = single, collection

const NFTFilterSort = (props) => {
      // Night Mode
      const nightMode = useContext(NightContext)
      const [theme,setTheme] =useState(themes.bright)
      useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
  // Zustände für ausgewählte Optionen
  const [filter, setFilter] = useState(null); // onChain, offChain, myLikes
  const [sort, setSort] = useState(null); // mostLikes, recency
  const [prevFilter, setPrevFilter] = useState(null); // onChain, offChain, myLikes
  const [prevSort, setPrevSort] = useState(null); // onChain, offChain, myLikes


  // Steuerung, ob Dropdowns geöffnet sind
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);


    const [tokenIdList, setTokenIdList] = useState([]);


  //filter Change --> tokenIdList = []
  // offset: tokenIdList setNFTs(NFTs => [...NFTs,...result])

  useEffect(()=>{

    check()


  },[sort,filter, props.loadOffset])



  async function check(){
    var newLoaded
    if(filter === null && sort === null){
        newLoaded =  await getAllSingles(LIMIT_LOAD,props.loadOffset)
    }
    else if(filter === "onChain" && sort === null){
        newLoaded = await getAllSinglesOnChain(LIMIT_LOAD,props.loadOffset)
    }
    else if(filter === "offChain" && sort === null){
        newLoaded = await getAllSinglesOffChain(LIMIT_LOAD,props.loadOffset)
    }
    else if(filter === "myLikes" && sort === null){
        newLoaded = await getAllSinglesMyLikes(LIMIT_LOAD,props.loadOffset)
    }
    else if(sort === "mostLikes"){
        newLoaded = await getAllSinglesMostLikes(LIMIT_LOAD,props.loadOffset)
    }
    else if(sort === "recency"){
        newLoaded =  await getAllSinglesRecency(LIMIT_LOAD,props.loadOffset)
    }

    // If Filter- Switch --> Array von vorne füllen. Sonst bei offset erhöhung hinten drauf machen auf array
    if(prevFilter !== filter ||  sort !== prevSort || props.loadOffset === 0){
        setTokenIdList(newLoaded)
        props.setTokenList(newLoaded)
        setPrevFilter(filter)
        setPrevSort(sort)
        // offset = 0 setzten
        props.setLoadOffset(0)
    }
 
    else{
        setTokenIdList(prev => [...prev,...newLoaded])
        props.setTokenList(prev => [...prev,...newLoaded])
    }

    props.setLoading(false)
  }





  const containerRef = useRef(null);

  const toggleFilter = () => {
    setIsFilterOpen((prev) => {
      const newState = !prev;
      if (newState) setIsSortOpen(false);
      return newState;
    });
  };

  const toggleSort = () => {
    setIsSortOpen((prev) => {
      const newState = !prev;
      if (newState) setIsFilterOpen(false);
      return newState;
    });
  };

  const handleFilterSelect = (value) => {
    setFilter((prev) => (prev === value ? null : value));
    setSort(null);
    props.setSort(null)
    // Dropdown bleibt offen – es schließt sich nur, wenn außerhalb geklickt wird
  };

  const handleSortSelect = (value) => {
    setSort((prev) => (prev === value ? null : value));
    props.setSort((prev) => (prev === value ? null : value))

    setFilter(null);
    // Dropdown bleibt offen
  };

  const handleReset = () => {
    setFilter(null);
    setSort(null);
    props.setSort(null)

  };

  // Schließt Dropdowns, wenn außerhalb des Containers geklickt wird
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsFilterOpen(false);
        setIsSortOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div ref={containerRef} className={classes.container}>
      <div className={classes.topRow}>
        {/* Filter-Container */}
        <div className={classes.buttonContainer}  >
          <button style={{backgroundColor:theme.color2}} className={classes.button} onClick={toggleFilter}>
            
            
          <img style={{height:"31px", filter: theme.png}} src ={filterImg}></img>

          </button>
          {isFilterOpen && (
            <div className={`${classes.dropdown2} ${isFilterOpen ? classes.open : ''}`}>
              <ul className={classes.list}>
                <li
                  className={`${classes.listItem} ${filter === 'onChain' ? classes.active : ''}`}
                  onClick={() => handleFilterSelect('onChain')}
                >
                  OnChain 
                </li>
                <li
                  className={`${classes.listItem} ${filter === 'offChain' ? classes.active : ''}`}
                  onClick={() => handleFilterSelect('offChain')}
                >
                  OffChain 
                </li>
                <li
                  className={`${classes.listItem} ${filter === 'myLikes' ? classes.active : ''}`}
                  onClick={() => handleFilterSelect('myLikes')}
                >
                  My Likes
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Sort-Container */}
        <div className={classes.buttonContainer}>
          <button  style={{backgroundColor:theme.color2}} className={classes.button} onClick={toggleSort}>
            
            <img style={{height:"24px", filter: theme.png}} src ={sortierenImg}></img>
          </button>
          {isSortOpen && (
            <div className={`${classes.dropdown} ${isSortOpen ? classes.open : ''}`}>
              <ul className={classes.list}>
                <li
                  className={`${classes.listItem} ${sort === 'mostLikes' ? classes.active : ''}`}
                  onClick={() => handleSortSelect('mostLikes')}
                >
                  Most Likes
                </li>
                <li
                  className={`${classes.listItem} ${sort === 'recency' ? classes.active : ''}`}
                  onClick={() => handleSortSelect('recency')}
                >
                  Recency
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* Reset-Button */}
        {false && <div className={classes.resetContainer}>
          <button className={classes.button} onClick={handleReset}>
            Reset
          </button>
        </div>}
      </div>
    </div>
  );
};

export default NFTFilterSort;
