import React, { useState, useEffect } from "react";
import {getOptions} from '../node/databank';
import {fetchi} from '../globalData'



export default function useMyFollowerList() {
    const [myFollowerList,setMyFollowerList]= useState([]);

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const myAddress = userdata.address

    useEffect(()=>{
        getFollowList()
    },[])

    async function getFollowList(){
        fetch(fetchi + "/databank",getOptions("getFollowList",{person: myAddress}))
        .then(res => {return res.json()}).then(res=>{
            console.log("getFollowList");
            console.log(res);
            if(res==="error"){
                setMyFollowerList([]);
            }
            setMyFollowerList(res[0]);
        })
    }

    return myFollowerList
}
  