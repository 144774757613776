
import classes from './Collections.module.css';


import NFTCollectionFormat from '../../../components/NFT/NFTCollectionFormat';
import {useState,useEffect,useRef} from 'react'
import {getAllCollections,searchCollections,getAllCollectionsOfPerson,searchCollectionsOfPerson,
    getAllCollectionsRecency,
    getAllCollectionsMostLikes

} from '../../../node/NFTData';


const LIMIT_LOAD = 15


// props.searchValue input.  "" show all
function Collections(props){


    const [searchResult,setSearchResult] = useState([])
    const [prevSearch,setPrevSearch] = useState("")
    const [NFTs,setNFTs] = useState([])


    const prevSortRef = useRef(null)
    const [prevSort,setPrevSort] = useState(null)


    console.log("props.sort in COllect")

    console.log(props.sort)
    console.log(props.sort === "recency")


    // Show All NFTs __ and mix the array random
    async function showAllCollections(){
        // get All Collections from DB and shuffle
        var result

        if(props.user){
            result = await getAllCollectionsOfPerson(props.user,LIMIT_LOAD,props.loadOffset);
        }else{
            result = await getAllCollections(LIMIT_LOAD,props.loadOffset);

            
            if(props.sort === "recency"){
                result = await getAllCollectionsRecency(LIMIT_LOAD,props.loadOffset);

            }else if(props.sort === "mostLikes"){
                result = await getAllCollectionsMostLikes(LIMIT_LOAD,props.loadOffset);
            }

            

            console.log(props.sort)
            console.log(prevSortRef.current)


            if(props.sort !== prevSortRef.current){

                setNFTs(result)
                prevSortRef.current = props.sort
                props.setLoading(false)
                //props.setLoadOffset(0)
                return
            }




            
        }

        //result.sort((a, b) => 0.5 - Math.random() );

        setNFTs(NFTs => [...NFTs,...result])
        props.setLoading(false)

    }



    // search in nftInfo Database
    async function search(searchValue){

        console.log("SEARCH!")
        console.log(searchValue)


        if(searchValue === ""){ return }
  

        var result
        // Check if Marketplace or User
        if(props.user){
            result = await searchCollectionsOfPerson(props.user,searchValue,LIMIT_LOAD,props.loadOffset)
        }else{
            result = await searchCollections(searchValue,LIMIT_LOAD,props.loadOffset)


            console.log("result")
            console.log(result)
        }

        // Check if neue Suche
        if(searchValue !== prevSearch ){ // if neue Suche von vorne anhängen
            setSearchResult(result)
            setPrevSearch(searchValue)

        }
        else{ // if offload erhöhr und keine neue Suche dann hinten anhängen
            setSearchResult(prev => [...prev,...result])
        }




    }

    console.log("propsi")

    console.log(props)

    useEffect(()=>{
        if(props.searchValue === "" ){
            setSearchResult([])
            showAllCollections();
        }else{
            setNFTs([])
            search(props.searchValue)
        }

    },[props.loadOffset,props.searchValue, props.sort])





    return (

        props.searchValue === "" ?

        NFTs.map(  element =>  <NFTCollectionFormat key={element.collection}  collection={element.collection}/>  )

        :

        searchResult.map(  element => <NFTCollectionFormat key={element.collection}  collection={element.collection}/>  )

        );


}

export default Collections;




