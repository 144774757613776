import React, { useState, useEffect, useContext, useRef } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { themes } from '../../../ColorTheme';
import { NightContext } from '../../../NightModeProvider';
import NFTFormatEasyOnePage from '../../../components/NFT/NFTFormatEasyOnePage';
import { getAllMyTokenIDs_On_Off_chain } from '../../../node/NFTData';

const MyNFTsWidget = ({ onSelect, disableClick }) => {
  const nightMode = useContext(NightContext);
  const [theme, setTheme] = useState(themes.bright);
  const [tokenIds, setTokenIds] = useState([]);
  const [displayedTokens, setDisplayedTokens] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const batchSize = 6; // Anzahl der NFTs, die pro Klick nachgeladen werden

  const listRef = useRef(null); // Referenz für das Scrollen

  useEffect(() => {
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright);
  }, [nightMode]);

  useEffect(() => {
    const fetchTokenIds = async () => {
      try {
        const myAddress = JSON.parse(sessionStorage.getItem("userdata")).address;
        const tokens = await getAllMyTokenIDs_On_Off_chain(myAddress);
        setTokenIds(tokens.reverse()); // Neueste zuerst
        setDisplayedTokens(tokens.slice(0, batchSize)); // Erst die ersten 6 anzeigen
        setCurrentIndex(batchSize);
      } catch (error) {
        console.error("Fehler beim Laden der Token IDs:", error);
      }
    };

    fetchTokenIds();
  }, []);

  // Lädt 6 weitere NFTs beim Klick auf den Button und scrollt etwas nach unten
  const loadMoreNFTs = () => {
    const nextTokens = tokenIds.slice(currentIndex, currentIndex + batchSize);
    setDisplayedTokens([...displayedTokens, ...nextTokens]);
    setCurrentIndex(currentIndex + batchSize);

    setTimeout(() => {
      if (listRef.current) {
        listRef.current.scrollBy({ top: 100, behavior: 'smooth' }); // Scrollt ein kleines Stück nach unten
      }
    }, 200);
  };

  return (
    <div style={{ width: '100%', fontFamily: 'Arial, sans-serif' }}>
      {/* Container mit fester Höhe für Scrollbarkeit */}
      <div
        ref={listRef}
        style={{
          maxHeight: 'calc(2 * 150px)', // Höhe für 2,5 NFTs (ca. 150px pro NFT)
          overflowY: 'auto',
          padding: '10px',
          borderBottom: theme.border ? theme.border : '1px solid #f0f0f0',
        }}
      >
        {/* NFT Anzeige */}
        <div
          style={{
            display: displayedTokens.length === 0 ? 'flex' : 'grid',
            gridTemplateColumns: displayedTokens.length === 0 ? undefined : 'repeat(3, 1fr)',
            alignItems: displayedTokens.length === 0 ? 'center' : undefined,
            justifyContent: displayedTokens.length === 0 ? 'center' : undefined,
            gap: displayedTokens.length === 0 ? undefined : '1rem',
          }}
        >
          {displayedTokens.length === 0 ? (
            <FaExclamationCircle size={50} color={theme.font} title="No NFTs available" />
          ) : (
            displayedTokens.map((tokenId) => (
              <div 
                key={tokenId} 
                onClick={() => onSelect && onSelect(tokenId)}
                style={{ cursor: disableClick ? 'pointer' : 'default' , width: '85px',}}
              >
                <NFTFormatEasyOnePage tokenId={tokenId} disableClick={disableClick} />
              </div>
            ))
          )}
        </div>
      </div>

      {/* Button zum Nachladen von NFTs */}
      {currentIndex < tokenIds.length && (
        <button
          onClick={loadMoreNFTs}
          style={{
            width: '100%',
            marginTop: '10px',
            padding: '10px',
            backgroundColor: theme.color2,
            color: theme.font,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold',
            
          }}
          onMouseOver={e => (e.currentTarget.style.backgroundColor = theme.color1)}
          onMouseOut={e => (e.currentTarget.style.backgroundColor = theme.color2)}
        >
          Load more NFTs
        </button>
      )}
    </div>
  );
};

export default MyNFTsWidget;
