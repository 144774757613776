import classes from './PopupFenster.module.css';
import closePic from '../../images/close.png';
import {useRef} from 'react'


// This is a PopupFenster Gerüst. 

//props.onCloseClicked to Close PopupFenster
// props.integration    <PopupFenster integration={<Integration /> } />
function PopupFenster(props){

    
    const fenster_ref = useRef()
    const backdrop_ref = useRef()

    // Rollout Effect than Delay than close
    function onCloseClickedAndBevoreRollOutEffect(){

        fenster_ref.current.classList.add(classes.driveOut_trigger)
        backdrop_ref.current.classList.add(classes.backdropOpacityTrigger)

        const delay = ms => new Promise(
            resolve => setTimeout(resolve, ms)
          );
        
        delay(200).then(()=>{
            props.onCloseClicked()
        })
    }

    return (
        <div  className={classes.komplett}> 

            {/*Backdrop*/ }
            <div ref={backdrop_ref} onClick={onCloseClickedAndBevoreRollOutEffect} className={classes.backdrop}> 

            </div>

            {/*Fenster*/ }
            <div ref={fenster_ref} className={classes.fenster}>

                <img onClick={props.onCloseClicked} src={closePic} className={classes.close}></img>

                <div className={classes.top}>
                    {props.text}
                </div>

                {/* Integration */}
                {props.integration}

            </div>

        </div>
    );


}

export default PopupFenster;