

import {BuyNFTContract,BuyNFTContractAddress} from './BuyNFT'
import {getPreisOfNFT,buyOffChainNFT_deleteCreator} from '../node/NFTData'
import {buyTokenOffInfura,buyTokenOnInfura} from './SendEtherInfura'
import {handlingMetamaskFunctionsMitErrorResponse} from './handlingMetamaskFunctionsMitErrorResponse'

import {_web3} from '../web3/Web3'
var web3 = _web3.mcbWallet
const userdata = JSON.parse(sessionStorage.getItem("userdata"))
if(userdata !== null){
    userdata.metamask === true ? web3 = _web3.metamask : web3 = _web3.mcbWallet
}

async function buyNFTOff(metadataURI,tokenId,seller, changeSnackbar){
    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const _from = userdata.address
    const _metadataURI =metadataURI;
    const _tokenId =tokenId;
    const _seller =seller;
    const _preis = await getPreisOfNFT(tokenId); // get preis from db


    if(_preis ===""){return}

    // Metamask ______________________________ Metamask
    if(userdata.metamask === true){
        // Das war die ursprüngliche FUnktion. Wurde mit dem Handler ersetzt
        //BuyNFTContract.methods.buyTokenOff(_metadataURI,_tokenId,_seller).send({from:_from, value: web3.utils.toWei(_preis,"ether")})
        const ele1 = [_metadataURI,_tokenId,_seller]
        const ele2 = {from:_from, value: web3.utils.toWei(_preis,"ether") }
        return await handlingMetamaskFunctionsMitErrorResponse(ele1, ele2, BuyNFTContract.methods.buyTokenOff, changeSnackbar, "Buy NFT Offchain")
        // buyNFTOnMetamask
   
    }else{ // MCB Wallet ________________ MCB Wallet
        var tx = await buyTokenOffInfura(userdata.privatekey, _from,_metadataURI,_tokenId,_seller,_preis) //from,_metadataURI,_tokenId,_seller,_preis
        console.log(tx)
        // if mcb just return TX, it will be signed in Approval View in Wallet
        return tx
    }


}


async function buyNFTOn(tokenId,seller,creator,changeSnackbar){



    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const _from = userdata.address
    const _tokenId =tokenId;
    const _seller =seller;
    const _creator =creator;

    const _preis = await getPreisOfNFT(tokenId); // get preis from db
    if(_preis ===""){return}

    if(userdata.metamask === true){// Metamask
        // Das war die ursprüngliche FUnktion. Wurde mit dem Handler ersetzt
        //BuyNFTContract.methods.buyTokenOn(_seller,_tokenId,_creator).send({from:_from, value: web3.utils.toWei(_preis,"ether") }).then(console.log).catch(console.log);
        
        //return await buyNFTOnMetamask(_tokenId,_seller,_creator,_preis,changeSnackbar)

        // hier wird die Funktion handlingMetamaskFunctionsMitErrorResponse verwendet
        const ele1 = [_seller,_tokenId,_creator]
        const ele2 = {from:_from, value: web3.utils.toWei(_preis,"ether") }
        return await handlingMetamaskFunctionsMitErrorResponse(ele1, ele2, BuyNFTContract.methods.buyTokenOn, changeSnackbar, "Buy NFT Onchain")
        // buyNFTOnMetamask

    }else{ 
        
        // MCB Wallet
        var tx = await buyTokenOnInfura(userdata.privatekey, _from,_tokenId,_seller,_creator,_preis) //from,_tokenId,_seller,_preis
        console.log(tx)
        // if mcb just return TX, it will be signed in Approval View in Wallet
        return tx
    }
}

// diese FUnktion habe ich durch handlingMetamaskFunctionsMitErrorResponse ersetzt
async function buyNFTOnMetamask(_tokenId,_seller,_creator,_preis,changeSnackbar){ // changeSnackbar ist eine Funktion
    


    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const _from = userdata.address

    try {

        const tx = await BuyNFTContract.methods.buyTokenOn(_seller,_tokenId,_creator).send({from:_from, value: web3.utils.toWei(_preis,"ether") })

        .on('transactionHash', function(hash){
            console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
            changeSnackbar("Transaktion wurde in MetaMask bestätigt.")
            //onCloseClick()
        })
    
        console.log("Transaktion erfolgreich (NFT Send):", tx);
        return {netzwerk: "metamask", success: true, error:"kein error",tx: tx}; // Erfolgreiche Transaktion

    } catch (error) {
        console.error("Fehler beim Senden der NFT:", error);

        // ❌ Benutzer hat die Transaktion abgelehnt
        if (error.code === 4001) {
            return {netzwerk: "metamask", success: false, error:"Transaktion abgelehnt. Bitte bestätige die Transaktion in MetaMask."}; 
        }
        // ❌ Ungenügendes Gas oder unzureichende Mittel
        else if (error.message.includes("insufficient funds")) {
            return {netzwerk: "metamask", success: false, error:"Nicht genug ETH für die Gasgebühren. Bitte lade dein Wallet auf."}; 
        }
        // ❌ Ungültige Adresse
        else if (error.message.includes("invalid address")) {
            return {netzwerk: "metamask", success: false, error:"Ungültige Empfängeradresse."}; 
        }
        // ❌ Andere Fehler
        else {
            return {netzwerk: "metamask", success: false, error: error.message}; 
        }

    }
}










export{buyNFTOff}
export{buyNFTOn}