import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import {NFTContractAddress} from "../web3/NFTContract"
import {web3_infura_Link} from "../web3/Web3"

import {currentNetwork} from "../globalData"

const NFT_CONTRACT_ADDRESS = NFTContractAddress;
const INFURA_RPC_URL = web3_infura_Link;
const BUY_SMART_CONTRACT = "0x1E161a8901D0f81F92f748eC4387618326754414";
const BUY_METHOD = "0x0ee94581";




function NFTTransactionHistory({ tokenId, userAddress ,metaData}) {
    const [transactions, setTransactions] = useState([]);
    const [mintedBy, setMintedBy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showDateTime, setShowDateTime] = useState(false);
    const history = useHistory();

    useEffect(() => {
        async function fetchTransactionHistory() {
            try {
                setLoading(true);
                setError(null);

                if (!tokenId || !userAddress) {
                    setError("Fehler: Ungültige Token-ID oder Adresse.");
                    setLoading(false);
                    return;
                }

                const provider = new ethers.providers.JsonRpcProvider(INFURA_RPC_URL);
                const transferTopic = ethers.utils.id("Transfer(address,address,uint256)");
                const tokenIdHex = ethers.utils.hexZeroPad(ethers.BigNumber.from(tokenId).toHexString(), 32);

                const logs = await provider.getLogs({
                    fromBlock: "earliest",
                    toBlock: "latest",
                    address: NFT_CONTRACT_ADDRESS,
                    topics: [transferTopic, null, null, tokenIdHex]
                });

                const txs = await Promise.all(logs.map(async (log) => {
                    const block = await provider.getBlock(log.blockNumber);
                    const timestamp = block.timestamp;

                    const fromAddress = "0x" + log.topics[1].slice(26);
                    const toAddress = "0x" + log.topics[2].slice(26);
                    const isMint = fromAddress === "0x0000000000000000000000000000000000000000";

                    if (isMint) setMintedBy(toAddress);

                    const transaction = await provider.getTransaction(log.transactionHash);
                    const isBuy = transaction.to.toLowerCase() === BUY_SMART_CONTRACT.toLowerCase() && transaction.data.startsWith(BUY_METHOD);

                    return {
                        hash: log.transactionHash,
                        blockNumber: log.blockNumber,
                        from: fromAddress,
                        to: toAddress,
                        timestamp: timestamp,
                        action: isMint ? "Mint" : isBuy ? "Buy" : "Transfer"
                    };
                }));

                setTransactions(txs);
            } catch (err) {
                setError("Fehler beim Abrufen der Transaktionshistorie.");
                console.error(err);
            } finally {
                setLoading(false);
            }
        }

        fetchTransactionHistory();
    }, [tokenId, userAddress,metaData]);

    function getTimeAgo(timestamp) {
        const now = Math.floor(Date.now() / 1000);
        const diff = now - timestamp;

        if (diff < 60) return `${diff} sec ago`;
        if (diff < 3600) return `${Math.floor(diff / 60)} min ago`;
        if (diff < 86400) return `${Math.floor(diff / 3600)} hr ago`;
        return `${Math.floor(diff / 86400)} days ago`;
    }

    const modifiedTransactions = [...transactions];

    if (mintedBy && mintedBy.toLowerCase() !== userAddress.toLowerCase()) {
        modifiedTransactions.unshift({
            hash: "Offchain",
            blockNumber: "-",
            from: userAddress,
            to: "-",
            timestamp: null,
            action: "Mint (Offchain)"
        });
    }

    function handleAddressClick(address) {
        if (address !== "-") {
            history.push(`/profile/${address}`);
        }
    }

    return (
        <div className="transaction-history">
            {error && <p className="error">{error}</p>}

            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Hash</th>
                            <th className="hide-on-mobile">Block</th>
                            <th 
                                onClick={() => setShowDateTime(!showDateTime)} 
                                className="clickable"
                            >
                                <span className="underlined">{showDateTime ? "Date Time" : "Age"}</span>
                            </th>
                            <th>Action</th>
                            <th>From</th>
                            <th>To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {modifiedTransactions.map(tx => (
                            <tr key={tx.hash}>
                                <td className="normal-text">
                                    {tx.hash === "Offchain" ? "Offchain" : (
                                        <a href={`${currentNetwork.etherscanTx}${tx.hash}`} target="_blank" rel="noopener noreferrer">
                                            {tx.hash.slice(0, 6)}
                                        </a>
                                    )}
                                </td>
                                <td className="hide-on-mobile">{tx.blockNumber}</td>
                                <td className="normal-text">
                                    {tx.timestamp ? (
                                        showDateTime
                                            ? dayjs.unix(tx.timestamp).format("YYYY-MM-DD HH:mm:ss")
                                            : getTimeAgo(tx.timestamp)
                                    ) : "-"}
                                </td>
                                <td className={tx.action.includes("Mint") ? "mint-action" : "transfer-action"}>
                                    {tx.action}
                                </td>
                                <td 
                                    className={`address clickable ${tx.from === "-" ? "disabled-link" : ""}`}
                                    onClick={() => handleAddressClick(tx.from)}
                                >
                                    <span className="desktop-view">{tx.from.slice(0, 6)}...{tx.from.slice(-4)}</span>
                                    <span className="mobile-view">{tx.from.slice(0, 6)}</span>
                                </td>
                                <td 
                                    className={`address clickable ${tx.to === "-" ? "disabled-link" : ""}`}
                                    onClick={() => handleAddressClick(tx.to)}
                                >
                                    {tx.to === "-" ? "-" : (
                                        <>
                                            <span className="desktop-view">{tx.to.slice(0, 6)}...{tx.to.slice(-4)}</span>
                                            <span className="mobile-view">{tx.to.slice(0, 6)}</span>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default NFTTransactionHistory;
