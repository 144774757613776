import classes from './SendView.module.css';
import sendImg from '../../images/send.png';
import {useState,useContext,useEffect} from 'react';
import equalImg from'../../images/equal.png';
import {sendEtherInfura,sendERC20Infura} from '../../web3/SendEtherInfura'

//material UI 
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';


//img
import backButton from '../../images/zuruck.png'




//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

import ApprovalView from './ApprovalView'


//Custom Hooks
import useWeb3 from '../../customHooks/useWeb3';
import useNightMode from '../../customHooks/useNightMode';
import useEthBalance from '../../customHooks/useEthBalance'
import {ChangeSnackbarFunction} from '../../SnackbarProvider'

import IntegrationWrapper from './IntegrationWrapper';


const ethPrice = require('eth-price');


//props.token // {amount,tokenName,tokenURL,contractAddress} // if true erc20 if false ether
//props: longAddr
// to close: closeWalletFunc()
function SendView(props){

    const [txObj,setTxObj] =useState(false);
    const [sendBalance,setSendBalance] = useState();
    const theme = useNightMode()
    const web3 = useWeb3()
    const changeSnackbar = useContext(ChangeSnackbarFunction)
    console.log("props")

    const ethBalance = useEthBalance("me")


    function setMaxBalance(){
        // bei ethereum muss noch die gasfees abgezogen werden
        if(props.token){
            setSendBalance(props.token.amount)
        }else{
            setSendBalance(ethBalance)
        }
    }


    function send_ether_Metamask(){

    // FOR ERC 20
    const ERC20_ABI = [
        {
        constant: false,
        inputs: [
            { name: "_to", type: "address" },
            { name: "_value", type: "uint256" }
        ],
        name: "transfer",
        outputs: [{ name: "", type: "bool" }],
        type: "function"
        }
    ];
    var data
    if(props.token){
    const tokenContractAddress = props.token.contractAddress;
    const recipientAddress = props.longAddr; 
    const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenContractAddress);
    const decimals = 18; // Adjust if your token uses a different number of decimals
    const tokenAmount = web3.utils.toBN(web3.utils.toWei(sendBalance.toString(), 'ether')).div(web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals)));
    data = tokenContract.methods.transfer(recipientAddress, tokenAmount.toString()).encodeABI();
    }

        web3.eth.sendTransaction({
            from:window.web3.currentProvider.selectedAddress,
            to:props.token ? props.token.contractAddress : props.longAddr,
            value: props.token? "0x0" : web3.utils.toWei(sendBalance.toString()),
            data: props.token? data : undefined
        })
        .on('transactionHash', function(hash){
            console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
            changeSnackbar("Transaction can take up to 1 min.") 
        })
        .then(receipt => {
            console.log("Transaktion erfolgreich!", receipt);
            changeSnackbar("Transaction was successfull!") 
        })
        .catch(error => {
            console.log("Transaktion abgelehnt oder ein Fehler ist aufgetreten", error);
            changeSnackbar("Transaction denied or an error occured.") 
        })
    }

    async function send_ether_MCBWallet(userdata){
        // Create Tx and then send to Approval View to sign
        try{
            var tx
            if(props.token){
                tx = await sendERC20Infura(  userdata.address, props.longAddr, userdata.privatekey, web3.utils.toWei(sendBalance.toString()),props.token.contractAddress  ) //(from,to,privateKey,value)
            }else{
                tx = await sendEtherInfura(  userdata.address, props.longAddr, userdata.privatekey, web3.utils.toWei(sendBalance.toString())  ) //(from,to,privateKey,value)
            }
            
            console.log(tx)
            setTxObj(tx) //open ApprovalView 
        }catch (error){ console.log(error)}
    }

    async function send(){
        if(sendBalance === 0){
            return
        }
        // if Metamask is conncted userdata = {address} if MCB Wallet userdata={address,privkey,pubkey,....}
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        if(userdata.metamask === true){// Metamask

            send_ether_Metamask()

        }else{// MCB Wallet

            send_ether_MCBWallet(userdata)
        }
    }


    // calc the USD price
    function calc(){


        var inputValue = document.getElementById("inputValue");
        setSendBalance(inputValue.value);

        if(props.token)return

        var usdValue = document.getElementById("usdValue");

        var ethValue =inputValue.value;
        if(inputValue.value === ""){
            inputValue.value="0";
            ethValue=0;
        }


        ethPrice("usd").then(res=>{
            const ethPrice=parseFloat(res.toString().split(":").pop())
            const calc =(ethPrice*parseFloat(ethValue)).toFixed(2);
            usdValue.value=calc;
        })
    }

    function calcFromUSD(){
        if(props.token)return

        var inputValue = document.getElementById("inputValue");
        var usdValue = document.getElementById("usdValue");

        var usdValue2 =usdValue.value;
        if(usdValue.value === ""){
            usdValue.value="0";
            usdValue2=0;
        }

        ethPrice("usd").then(res=>{
            const ethPrice=parseFloat(res.toString().split(":").pop())
            const calc =(parseFloat(usdValue2)/ethPrice).toFixed(10);
            inputValue.value=calc;
            setSendBalance(calc);
        })
    }

    function oneEtherCalc(){
        if(props.token)return

        ethPrice("usd").then(res=>{
            const ethPrice=parseFloat(res.toString().split(":").pop())
            var usdValue = document.getElementById("usdValue");
            usdValue.placeholder = ethPrice.toString() + " usd";
        })

    }
    useEffect(()=>{
        oneEtherCalc();
    },[])


    


return (

< IntegrationWrapper noBackButton={props.noBackButton}  onClickBack={props.onClickBack} headerText={props.text}>

            { txObj && <ApprovalView closeWalletFunc={props.closeWalletFunc} type="send Ether" tx={txObj}  />  }
            { !txObj &&


<div className={classes.box}>


        <div style={{color:theme.font,fontSize:'20px'}}> </div>

        <div className={classes.inputMaxWrapper} style={{marginTop:"15px"}}>
            <input style={{color:theme.font,backgroundColor:theme.color1}} id="inputValue" value={sendBalance} onChange={calc} type="text" placeholder={props.token? props.token.tokenName:"1 ether"} className={classes.textInput}></input>
            <Button variant="outlined" sx={{}} onClick={setMaxBalance}>  max  </Button>
        </div>

        

        {!props.token && <>
        <img style={{filter:theme.png}} src={equalImg} className={classes.equalimg}></img>
        <div className={classes.inputMaxWrapper}>
        <input style={{color:theme.font,backgroundColor:theme.color1}} id="usdValue" onChange={calcFromUSD} type="text"  className={classes.textInput}></input>
        </div>
        
        </>}


        <div className={classes.inputMaxWrapper}>

        <Tooltip title="send" disableInteractive arrow placement="top">
            <Button variant="contained" sx={{width:'100%',height:'40px',marginTop:'15px',marginBottom:"15px"}} onClick={send}>  < img src={sendImg} style={{height: '30px',width: 'auto',filter:theme.png}}></img>   </Button>
        </Tooltip>
        </div>

</div>
}
</IntegrationWrapper>
);

}


// function SendViewAnzeige(props){

//     return(
// < IntegrationWrapper noBackButton={props.noBackButton}  onClickBack={props.onClickBack} headerText={"send"}>

// { txObj && <ApprovalView closeWalletFunc={props.closeWalletFunc} type="send Ether" tx={txObj}  />  }
// { !txObj &&
// <>

//         <div style={{color:theme.font,fontSize:'20px'}}> {props.text} </div>

//         <input style={{color:theme.font,backgroundColor:theme.color1}} id="inputValue" onChange={calc} type="text" placeholder={"1 ether"} className={classes.textInput}></input>

//         {!props.token && <>
//         <img style={{filter:theme.png}} src={equalImg} className={classes.equalimg}></img>

//         <input style={{color:theme.font,backgroundColor:theme.color1}} id="usdValue" onChange={calcFromUSD} type="text"  className={classes.textInput}></input>
        
//         </>}



//         <Tooltip title="send" disableInteractive arrow placement="top">
//             <Button variant="contained" sx={{width:'100%',height:'40px',marginTop:'10px'}} onClick={send}>  < img src={sendImg} style={{height: '30px',width: 'auto'}}></img>   </Button>
//         </Tooltip>

// </>
// }
// </IntegrationWrapper>


//     )
// }

export default SendView;