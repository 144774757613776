import classes from './OneNFTPage.module.css';
import {useLocation,useHistory,useParams} from 'react-router-dom';
import {useState,useEffect,useContext} from 'react';
import {getAllMetadataFromURI,getTokenUri} from '../../web3/NFTContractHelper';
import {getOwnerOfTokenId,sendNFT,checkIfTokenExists} from '../../web3/NFTContractHelper'
import {shortAddr} from '../../web3/LoadingFunctions'
import NFTFormatEasyOnePage from '../../components/NFT/NFTFormatEasyOnePage';
import {getOptions} from '../../node/databank';
import {getTokenURIDB,getPreisOfNFT,getNFTInfoFromTokenId} from '../../node/NFTData'
import {buyNFTOff,buyNFTOn} from '../../web3/BuyNFTContractHelper'
//img
import black_herz from '../../images/black_herz.png';
import shareImg from '../../images/share.png';
import sendImg from '../../images/send.png';
import profilePic from '../../images/profilepic.png';
import linkImg from '../../images/link.png';
import detailImg from '../../images/datei.png';
import desImg from '../../images/description.png';
import etherSign from '../../images/ethereum.png'
import historyImg from '../../images/geschichte.png'
import bankbarImg from '../../images/dankbar.png';
import collectionImg from '../../images/collectionImg.png';


import NFTSilder from './NFT/NFTSlider'
//Links
import {NFTContractAddress} from "../../web3/NFTContract"
import {web3_infura_Link} from "../../web3/Web3"
import { ethers } from "ethers";
//popup
import PopupFenster from '../../components/PopupFenster/PopupFenster'
import SendNFTIntegration from '../../components/wallet/SendNFTIntegration' // now integration from wallet
import LikesIntegration from '../../components/PopupFenster/LikesIntegration'
import SetNFTPriceIntegration from '../../components/PopupFenster/SetNFTPriceIntegration'
// material UI
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
//Fetchi
import {fetchi} from '../../globalData'
//wallet approve
import Wallet from '../../components/wallet/Wallet'
import ApprovalView from '../../components/wallet/ApprovalView';
//Snackbar
import {ChangeSnackbarFunction} from '../../SnackbarProvider'
import {UserContext} from '../../UserProvider'

import NFTTransactionHistory from '../../components/NFTTransactionHistory'
import NFTTransactionHistory2 from '../../components/NFTDetails'

//popup
//import LoginIntegration from '../PopupFenster/LoginIntegration'
import LoginIntegration from '../../components/aufklappMenu/LoginIntegration'

//ColorTheme - Night Mode
import {theme, themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

import useNightMode from '../../customHooks/useNightMode';
const ethPrice = require('eth-price');

const userdata = JSON.parse(sessionStorage.getItem("userdata"))


function OneNFTPage(){        
    const theme = useNightMode()
    const changeSnackbar = useContext(ChangeSnackbarFunction)
    const userContext = useContext(UserContext)
    const history = useHistory();
    const {tokenId} = useParams();
    const contractAddress ="0x7D66B92831bc5A7ae77541719526d4693FD9DC35"
    useEffect(() => {
        window.scrollTo(0,0)
    },[tokenId])


    

    const [owner,setOwner]=useState({ownerAddress:undefined,shortOwnerAddress:undefined});
    const [amIOwner,setAmIOwner]=useState(false);

    const [isOffchain,setIsOffchain] = useState(false);
    const [preis,setPreis] = useState({loading:true,preis:""});
    const [preisUSD,setPreisUSD] = useState("");

    const [metaData,setMetadata]=useState([]); // {collection,creator,description,extLink,image,name,tokenId,tokenUri}
    const [NFTLikes,setNFTLikes]=useState(0);
    const [likesList,setLikesList]=useState(false);
    const [NFTLikesArrayForList,setNFTLikesArrayForList] = useState([]);
    const [sendOneNFTModal,setSendOneNFTModal]=useState(false);
    const [NFTPriceModel,setNFTPriceModal] =useState(false);
    const [txObj,setTxObj] =useState(false);
    const [copyClicked,setCopyClicked] =useState(false);
    const [loginPageIsOpen,setLoginPageIsOpen] =useState(false);



    

    console.log("metaata")

console.log(metaData)

    // wird aus ApprovalView und SendNFTIntegration aufgerufen zum Inhalt neuladen
    function transactFinishAndReload(netzwerk){
        if(netzwerk ==="metamask"){
            //window.location.reload()
        }  
        console.log("DURCH META NEU LADEN")
        loadMetadata(tokenId);

    }

    useEffect(()=>{
        ethToUSD()
    },[preis])

    async function ethToUSD(){
        if(preis.preis === ""){return ""}

        ethPrice("usd").then(res=>{
            const ethPrice=parseFloat(res.toString().split(":").pop())
            const calc =(ethPrice*parseFloat(preis.preis)).toFixed(2);
            setPreisUSD(calc); // in usd
            return calc
        })


    }





    async function loadMetadata(tokenId){
        // try get from blockchain, if error is offchain--> db
        //const tokenURI = await getTokenURIDB(tokenId);

        var nftInfo = await getNFTInfoFromTokenId(tokenId); //
        nftInfo = nftInfo[0][0]
        const tokenURI = nftInfo.metaurl

        setIsOffchain(!await checkIfTokenExists(tokenId)); //token not exist = offchain

        var allMetaData = await getAllMetadataFromURI(tokenURI,tokenId)

        allMetaData.datum = nftInfo.datum


        getOwner(allMetaData)
        getPreisOfNFT(tokenId).then(p =>{setPreis({loading:false, preis:p})});
        setMetadata( allMetaData )
        return await allMetaData
    }
    useEffect(() => {
        loadMetadata(tokenId)
    },[tokenId])

    console.log("owner")
    console.log(amIOwner)



    async function getOwner(meta){
        const response = await getOwnerOfTokenId(tokenId) // from Blockchain
        const isOnchain = await checkIfTokenExists(tokenId)
        // if not on blockchain owner == creator. 
        const ownerAddress = isOnchain ? response : meta.creator
        const shortOwnerAddress = shortAddr(ownerAddress)
        setOwner({ownerAddress:ownerAddress,shortOwnerAddress:shortOwnerAddress})
        const myAddress = userContext.address
        setAmIOwner(ownerAddress === myAddress)
        return
    }

    function shortURI(uri){
        if( uri === undefined){return }
        return uri.toString().slice(0,12) +"..."
    }
    function openURI(){
        window.open(metaData.tokenUri);
    }
    function openImageURI(){
        window.open(metaData.image);
    }

    function goToProfile(e){
        const person = e.target.id;
        console.log(person);

        history.push({
            pathname:"/profile/"+person
        })

    }

    function openErc721(){
        window.open("https://ethereum.org/en/developers/docs/standards/tokens/erc-721/")
    }

    function getNFTLikes(){
        fetch(fetchi+"/databank",getOptions("getNFTLikes",{tokenId: tokenId}))
        .then(res => {return res.json()}).then(res=>{
            if(res==="error"){
                setNFTLikes(0);
            }
            setNFTLikes(res[0][0].count);
        })
    }
    useEffect(() => {getNFTLikes()},[tokenId]);


    function copyURL(){
        setCopyClicked(true)
        navigator.clipboard.writeText(window.location.href);
        setTimeout(()=>{
            setCopyClicked(false)
        },2000)
    }
    function openLikesList(){
        setLikesList(true);
    }
    function closeLikesList(){
        setLikesList(false);
    }

    function getLikesList(){
        fetch(fetchi+"/databank",getOptions("getLikesList",{tokenId: tokenId}))
        .then(res => {return res.json()}).then(res=>{

            if(res==="error"){
                setNFTLikesArrayForList([]);
            }else{
                setNFTLikesArrayForList(res[0]);
            }
            
        })
    }
    useEffect(() => {getLikesList()},[tokenId]);

    function openSend(){
        setSendOneNFTModal(true)
    }
    function closeSend(){
        setSendOneNFTModal(false)
    }
    function openEtherContract(){
        window.open("https://etherscan.io/address/" + contractAddress );
    }
    function openCollection(){
        history.push("/collection/"+metaData.collection)
    }


    function checkIfLogin(){

        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        return userdata !== null
    }

    async function buyButtonClicked(){

        if(!checkIfLogin()){
            setLoginPageIsOpen(true)
            return
        }

        if(amIOwner){
            changeNFTPrice();
            return
        }

        if(preis.preis === ""){
            return
        }
        

        // Check if Metamask or MCB Wallet. IF MCB Open Wallet and Approve transaction with PW to decrypt PrivKey
        
        // MCB Wallet __________________
        if(!userdata?.metamask){ 
            // check if on or offchain sell
            getOwnerOfTokenId(tokenId).then(response =>{
                if(response === "error"){
                    // buy OFFCHAIN __________ MCB Wallet
                   buyNFTOff(metaData.tokenUri,tokenId,owner.ownerAddress).then(tx=>{
                    console.log(tx)
                    setTxObj(tx) // set TX OBJ and Open Approve Wallet
                   })

                }else{
                    //buy ONCHAIN  __________ MCB Wallet
                    buyNFTOn(tokenId,owner.ownerAddress,metaData.creator).then(tx=>{
                        console.log(tx)
                        setTxObj(tx) // set TX OBJ and Open Approve Wallet
                    })
                }
            })

            return 

        } // else if metamask do nothing

        //METAMASK ___

        // check if on or offchain sell
        getOwnerOfTokenId(tokenId).then(response =>{
            if(response === "error"){
                // buy offchain
                buyNFTOff(metaData.tokenUri,tokenId,owner.ownerAddress ,changeSnackbar)
                .then(response =>{
                    console.log(response)
                    // METAMASK Netzwerk
                    if(response.netzwerk =="metamask"){
                        if(response.success){
                            changeSnackbar("NFT was minted and bought successfully!")
                            // Seite erneuern
                            transactFinishAndReload("metamask")
                        }
                        if(!response.success){
                            changeSnackbar(response.error)
                        }
                    }
                })    

            }else{
                //buy onchain
                buyNFTOn(tokenId,owner.ownerAddress,metaData.creator,changeSnackbar)
                //response: {netzwerk: "metamask", success: true, error:"kein error",tx: tx}
                .then(response =>{
                    console.log(response)
                    // METAMASK Netzwerk
                    if(response.netzwerk =="metamask"){
                        if(response.success){
                            changeSnackbar("NFT was bought successfully!")
                            // Seite erneuern
                            transactFinishAndReload("metamask")
                        }
                        if(!response.success){
                            changeSnackbar(response.error)
                        }
                    }
                })        
                
            }
        })
    }


    function changeNFTPrice(){
        setNFTPriceModal(true);
    }
    function closeSetPrice(){
        setNFTPriceModal(false);
    }
    function nftpriceChanged(preis){
        setPreis(prev => ({...prev,preis:preis}) )
    }
    function iLikedNFT(){
        const myAddress = JSON.parse(sessionStorage.getItem("userdata")).address
        setNFTLikesArrayForList(prev => [...prev, {address: myAddress}])
        setNFTLikes(prev => Number(prev) +1 )
    }
    function iDisLikedNFT(){
        const myAddress = JSON.parse(sessionStorage.getItem("userdata")).address
        setNFTLikesArrayForList(prev => prev.filter(item => item.address != myAddress))
        setNFTLikes(prev => Number(prev) -1)
    }


    

    async function fetchNFTDataHolderAmountandTotalTransactions(tokenId) {
        try {
            const provider = new ethers.providers.JsonRpcProvider(web3_infura_Link);
            const contract = new ethers.Contract(NFTContractAddress, [
                "function ownerOf(uint256) view returns (address)",
                "function name() view returns (string)",
                "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"
            ], provider);
    
            // Fetch NFT name
            const name = await contract.name();
    
            // Fetch transaction history
            const transferTopic = ethers.utils.id("Transfer(address,address,uint256)");
            const tokenIdHex = ethers.utils.hexZeroPad(ethers.BigNumber.from(tokenId).toHexString(), 32);
            
            const logs = await provider.getLogs({
                fromBlock: "earliest",
                toBlock: "latest",
                address: NFTContractAddress,
                topics: [transferTopic, null, null, tokenIdHex]
            });
            
            const totalTransactions = logs.length;
    
            // Extract unique holders
            const holders = new Set();
            logs.forEach(log => {
                const fromAddress = "0x" + log.topics[1].slice(26);
                const toAddress = "0x" + log.topics[2].slice(26);
                if (fromAddress !== "0x0000000000000000000000000000000000000000") {
                    holders.add(fromAddress);
                }
                holders.add(toAddress);
            });
    
            const totalHolders = holders.size;
    
            return {
                name,
                totalTransactions,
                totalHolders
            };
        } catch (err) {
            console.error("Fehler beim Abrufen der NFT-Daten:", err);
            return {
                name: "Fehler",
                totalTransactions: 0,
                totalHolders: 0
            };
        }
    }
    

    return (

        <div style={{backgroundColor:theme.color1}} className={classes.container}>


           { loginPageIsOpen && <PopupFenster integration={<LoginIntegration nextPage={"/home"} onCloseClicked={()=>{setLoginPageIsOpen(false)}} />} onCloseClicked={()=>{setLoginPageIsOpen(false)}} text={"Connect Wallet"}/>   }


            {/* Send NFT with Approve Wallet - Nur beim Senden geöffnet - Für MCB & Metamask.  Senden geht nur onchain*/}

            {/*Send MCB-Wallet: ->SendNFTIntegration -> ApprovalView  */}
            {sendOneNFTModal &&
            <Wallet closeWalletFunc={()=>{setTxObj(false)}}
            integration ={<SendNFTIntegration transactFinishAndReload={transactFinishAndReload} onCloseClick={closeSend} tokenId={tokenId} text={`Send NFT: ${metaData.name}`} />}
            />
                
        
            }

            {/* Buy NFT with Approve Wallet - Wird nur beim Kaufen geöffnet - buy off and on Chain - Nur Für MCB WALLET - Durch buyButtonClicked geöffnet*/}
            {txObj &&
            <Wallet closeWalletFunc={()=>{setTxObj(false)}}
            integration={<ApprovalView type="buy NFT" tx={txObj} transactFinishAndReload={transactFinishAndReload} />}
            />
                
            }

            {/*left */}
            <div className={classes.left}>

                <div className={classes.NFTWrapper}>
                     <NFTFormatEasyOnePage metaData={metaData} rerender={metaData.tokenId} iLikedNFT={iLikedNFT} iDisLikedNFT={iDisLikedNFT} tokenId={tokenId}/>
                </div>


                

            </div>


            {/*right */}
            <div  style={{backgroundColor:theme.color1}} className={classes.right}>

                {/* SetPrice Popup */}
                { NFTPriceModel && <PopupFenster integration={<SetNFTPriceIntegration nftpriceChanged={nftpriceChanged} onCloseClick={closeSetPrice} tokenId={tokenId}/>} text={"Set NFT price"} onCloseClicked={closeSetPrice} />  }

                {/* SendNFT Popup */}
                {false && <PopupFenster integration={<SendNFTIntegration transactFinishAndReload={transactFinishAndReload} onCloseClick={closeSend} tokenId={tokenId}/>} onCloseClicked={closeSend} text={`Send NFT: ${metaData.name}`}/>}

                {/* Likes Popup */}
                {likesList && <PopupFenster onCloseClicked={closeLikesList} integration={<LikesIntegration likesList={NFTLikesArrayForList}/>}  text={"Favorited by"} />  }

                <div className={classes.box}>

                <div className={classes.buyButtonWrapper}>

                    <ButtonGroup orientation="vertical" variant="outlined" aria-label="outlined button group" >

                        <Tooltip title={copyClicked ? "Copied!" : "Copy Link"} disableInteractive arrow placement="left">
                            <Button onClick={copyURL}>< img src={shareImg} style={{height: '20px',width: 'auto',filter:theme.png}}></img></Button>
                        </Tooltip>



                        {amIOwner &&
                        <Tooltip title="profile pic" disableInteractive arrow placement="left">
                            <Button ><img src={profilePic} style={{height: '20px',width: 'auto',filter:theme.png}}></img></Button>
                        </Tooltip>
                        }

                        { !isOffchain && amIOwner &&
                        <Tooltip title="send NFT" disableInteractive arrow placement="left">
                            <Button onClick={openSend}><img src={sendImg} style={{height: '20px',width: 'auto',filter:theme.png}}></img></Button>
                        </Tooltip>
                        }


                    </ButtonGroup>

                </div>
                    
                    <div className={classes.nameUndLikesWrapper}>
                    {/* name + collection */}
                    <div style={{color: theme.font}} className={classes.h2}>{metaData.name }</div>
                    <div onClick={openCollection} className={classes.h1}>{metaData.collection  }</div>


                    {/* liked */}
                        <div className={classes.likesWrapper}>
                        <img style={{filter:theme.png}} src={black_herz} className={classes.herz}></img>
                        <div style={{color: theme.font}} onClick={openLikesList} className={classes.text}> {NFTLikes + " favorites"} </div>
                    </div>
                    </div>

                {/* Description Box*/}
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>

         

                    <div className={classes.h4}> 

                        <div style={{color:theme.fontGrey}}>
                            Current Price
                        </div>

                        <div style ={{display:"flex", gap:"10px"}} className={classes.ethPriceWrapper}>
                        <div style={{color:theme.font}} className={classes.ethPrice}>
                            {  preis.preis !=="" && <img src={etherSign} style={{height: '20px',width: 'auto'}}></img> }
                            
                            {!preis.loading && preis.preis !=="" && (preis.preis + " ETH" )|| "Not for sale"}
                            </div>
                            <div style={{color:theme.fontGrey}}>
                            {  preis.preis !=="" && ("$ "+ preisUSD) }
                            </div>
                        </div>

                        <div className={classes.buyOfferButtonWrapper} style={{display:"flex",gap:"10px"}}>
                        <Button disabled={preis.preis=== "" && !amIOwner} onClick={buyButtonClicked}  sx={{width:"100%",height:"40px"}} variant="contained">{amIOwner ? "set Price" : "Buy now"}</Button>
                        <Button sx={{width:"100%",height:"40px"}} variant="outlined">Make offer</Button>
                        </div>

                        
                        
                        <div className={classes.supportsCreator} style={{color:theme.fontGrey}}>
                            <img style={{filter:theme.png,height:"20px",marginRight:"8px"}} src={bankbarImg}></img>
                            Supports Creator: This item is paying the creator 1% of the sold price.
                        </div>
                    
                    </div>
                </div>
                


                {/* Description Box*/}
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>

                    <div style={{borderBottom:theme.border}} className={classes.topBox}>
                        <img style={{filter: theme.png}} src={desImg} className={classes.descriptImg}></img>
                        <div style={{color: theme.font}} className={classes.h3}>Description:</div>
                    </div>

                    <div className={classes.h4}> {metaData.description}</div>
                </div>


                {/* Detail Box*/}
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>

                    <div style={{borderBottom:theme.border}} className={classes.topBox}>
                        <img style={{filter: theme.png}} src={detailImg} className={classes.descriptImg}></img>
                        <div style={{color: theme.font}} className={classes.h3}>Details:</div>
                    </div>


                    <div
                        style={{
                            margin:"10px",
                            display: 'grid',
                            gridTemplateColumns: 'repeat(4, 1fr)', // 2 Spalten
                            gap: '10px', // Abstand zwischen den Boxen
                            overflowX:"scroll",
                        }}
                        >

                    <OwnerBox   header={"Owner"} 
                                body={owner.ownerAddress === userContext.address ? `You (${owner.shortOwnerAddress})` : owner.shortOwnerAddress}
                                onClick={goToProfile} id={owner.ownerAddress}
                                clickAble
                                />
                   
                    <OwnerBox   header={"Creator"} 
                                body={userContext.address === metaData.creator ? `You (${shortAddr(userContext.address)})` : shortAddr(metaData.creator)}
                                onClick={goToProfile} id={metaData.creator}
                                clickAble
                                />

                    <OwnerBox   header={"Created on"} 
                                body={metaData?.datum?.slice(0,10)}
                                
                                />
                    <OwnerBox   header={"Contract Address"} 
                                body={shortAddr(contractAddress)}
                                onClick={openEtherContract}
                                clickAble
                                />
                    <OwnerBox   header={"Token Type"} 
                                body={"ERC721"}
                                onClick={openErc721}
                                clickAble
                                />

                    <OwnerBox   header={"Metadata"} 
                                body={shortURI(metaData.tokenUri)}
                                onClick={openURI}
                                clickAble
                                />


                    <OwnerBox   header={"Image"} 
                                body={shortURI(metaData.image)}
                                onClick={openImageURI}
                                clickAble
                                />


                    <OwnerBox   header={"TokenId"} 
                                body={shortURI(metaData.tokenId)}
                                />


                    </div>


                </div>


                {/* EXT Link*/}
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>
                    <div style={{borderBottom:theme.border}} className={classes.topBox}>
                    <img style={{filter: theme.png}} src={linkImg} className={classes.descriptImg}></img>
                        <div style={{color: theme.font}} className={classes.h3}>Link from Creator:</div>
                    </div>
                    <div onClick={() => window.open("https://"+metaData.extLink, '_blank',"noopener,noreferrer")} className={classes.link}> {metaData.extLink}</div>

                </div>


                {/* More From Collection*/}
                {metaData.collection !== "" && <>
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>
                    <div style={{borderBottom:theme.border}} className={classes.topBox}>
                    <img style={{filter: theme.png}} src={collectionImg} className={classes.descriptImg}></img>

                            <div style={{color: theme.font}} className={classes.h3}>{"More from "} </div>

                            <div onClick={openCollection} className={classes.h3} style={{color:"blue",cursor:"pointer"}}>{metaData.collection  }</div>
                    </div>
                    <NFTSilder collectionName={metaData.collection} />
                </div>
                </>}


                {/* History*/}
                <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.niceBoxes}>
                    <div style={{borderBottom:theme.border}} className={classes.topBox}>
                    <img style={{filter: theme.png}} src={historyImg} className={classes.descriptImg}></img>
                        <div style={{color: theme.font}} className={classes.h3}>Transaction-History:</div>
                    </div>
                    <NFTTransactionHistory metaData={metaData}  userAddress={metaData.creator} tokenId={tokenId}/>
                </div>
                


                

                </div>



            </div>



        </div>

    );

}


const OwnerBox = (props) => {
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        style={{
        border:theme.border,
          padding: '8px',
          borderRadius: '4px',
          display: 'inline-block',
          minWidth:"120px"
          

        }}
      >
        <div style={{ marginBottom: '4px', fontWeight: 'bold',color:theme.fontGrey ,fontSize:"10px", }}>{props.header}</div>

        <div style={{ minHeight: '1.2em',fontSize:"15px",
                    cursor: props.clickAble ? "pointer":"",
                    color: !isHovered 
                    ? (props.clickAble ? "blue": theme.font)
                    : (props.clickAble ? "rgb(46, 46, 129)" : theme.font)

                    }} 
            onClick={props.onClick} id={props.id}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}  
        > 
        {props.body ? props.body : '\u00A0'}
      
      </div>

      </div>
    );
};
  


export default OneNFTPage;