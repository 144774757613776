
import { create } from 'ipfs-http-client'
import { useHistory } from "react-router-dom";
import {NFTContract,NFTContractAddress} from '../../../web3/NFTContract';
import {mintNFTInfura} from '../../../web3/SendEtherInfura'
import {handlingMetamaskFunctionsMitErrorResponse} from '../../../web3/handlingMetamaskFunctionsMitErrorResponse'




//ipfs upload
async function ipfsUpload(metaData,file){ //  MetaData json: {itemName, collection, description, extLink}
    
    // This is old. Infura changed: now you have to auth so you need projectId and secret

    

    const projectId = '2El3apLGqvfQW4KIlNWEpc6pUrj';   // <---------- your Infura Project ID
    const projectSecret = '0b4d9df7b83996fdcc1789724cb57e2d';  // <---------- your Infura Secret
    const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
    const client = create({
        host: 'ipfs.infura.io',
        port: 5001,
        protocol: 'https',
        headers: {
            authorization: auth,
        },
    });

    //first upload image
    try {

        const added = await client.add(file)
        // url changed, before was: https://ipfs.infura.io
        const url = `https://ipfs.io/ipfs/${added.path}`
        console.log("image URL: " + url);

        //{itemName, collection, description, extLink} + image: url
        metaData.image = url;

        const JSONMetadata=JSON.stringify(metaData);

        // and then JSON STRING with Image and Metadata
        try {
          const added = await client.add(JSONMetadata)
          // url changed, before was: https://ipfs.infura.io
          var metaDataURL = `https://ipfs.io/ipfs/${added.path}`
          console.log("metadata:    " + metaDataURL);

          return metaDataURL;

        } catch (error) {
          console.log('Error uploading file: ', error)
        }
    } catch (error) {
        console.log('Error uploading file: ', error)
    }

}




async function createNFT(metaDataURL,id,changeSnackbar){ // onchain

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const owner = userdata.address
    if(userdata.metamask === true){// Metamask


        const ele1 = [owner,metaDataURL,id]
        const ele2 = {from: owner,to: NFTContractAddress}
    
        const response = await handlingMetamaskFunctionsMitErrorResponse(ele1,ele2,NFTContract.methods.mintToken, changeSnackbar, "Mint NFT")
        //return {netzwerk: "metamask", success: true, error:"kein error",tx: tx}
        return response


    }else{// MCB Wallet

        console.log("MCB WALLET")

        const tx = await mintNFTInfura(owner, userdata.privatekey, id, metaDataURL)
        console.log(tx)

        
        return {netzwerk: "mcbwallet", success: true, error:"kein error",tx: tx};

    }
        //const tokenId = "braucht man nichtmehr weil tokenId schon davor bestimmt wird"
        //const txHash = "..."

    
}

export {ipfsUpload};
export {createNFT};