import React, { useState, useEffect } from "react";
import {_web3} from '../web3/Web3'



export default function useWeb3() {
    const [web3,setWeb3]= useState(_web3.mcbWallet);

    useEffect(()=>{
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        setWeb3 (userdata.metamask ? _web3.metamask : _web3.mcbWallet)
    },[])

    return web3
}
  