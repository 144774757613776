
const Web3 =require('web3');




const web3_infura_Link = 'https://sepolia.infura.io/v3/13185221b99744cda86c46e02a3ded8f'
const web3_infura_Link_mainnet = "https://mainnet.infura.io/v3/13185221b99744cda86c46e02a3ded8f"


const web3_infura = new Web3(web3_infura_Link);


const alchemy = 'https://eth-sepolia.g.alchemy.com/v2/x28uYs8hGw3RS0qMd6n_7XWicg3GaP3y'
const alchemy_mainnet = 'https://eth-mainnet.g.alchemy.com/v2/x28uYs8hGw3RS0qMd6n_7XWicg3GaP3y'


const web3_alchemy = new Web3(alchemy_mainnet);




var web3 = new Web3(window.ethereum);


const _web3 = {metamask:web3,mcbWallet:web3_alchemy}
export {_web3};
export {web3_infura_Link}

// export window.ethereum & alchemi api.
