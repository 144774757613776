
import {NFTContract} from './NFTContract';
import {useContext} from 'react';

import {NFTContractAddress} from './NFTContract';
import {sendNFTInfura} from './SendEtherInfura'


async function checkIfTokenExists(tokenId) {

    try {
        // Versuche, die tokenURI für die gegebene Token ID zu holen
        const tokenURI = await NFTContract.methods.tokenURI(tokenId).call();

        // Falls die Anfrage erfolgreich war, existiert der Token
        console.log(`Token ${tokenId} existiert mit URI: ${tokenURI}`);
        return true;
    } catch (error) {
        console.error(`Fehler beim Abrufen von Token ${tokenId}:`, error.message);

        // Falls die Methode einen "revert" oder einen "invalid opcode" Fehler wirft,
        // bedeutet das meistens, dass die Token ID nicht existiert
        if (error.message.includes("execution reverted") || error.message.includes("invalid opcode")) {
            console.log(`Token ${tokenId} existiert NICHT auf der Blockchain.`);
            return false;
        }

        // Falls ein anderes Problem auftritt, werfe den Fehler erneut
        throw error;
    }
}


async function getTokenUri(tokenId){
    //const owner = await NFTContract.methods.ownerOf(tokenId).call()

    const response = await NFTContract.methods.tokenURI(tokenId).call();
    console.log("response234")

    console.log(response)
    return response

}

async function getAllMetadataFromURI(uri,tokenId) {

    try{
        const response = await fetch(uri);
        var edit = await response.json();
        edit.tokenId = tokenId
        edit.tokenUri = uri
        
        return await edit;

    }catch(error){
        console.log('Error getAllMetadataFromURI in NFTContractHelper.js: ', error)

    }
}

async function getMetadataFromURI(uri,tokenId) {

    try{
        const response = await fetch(uri);
        const json = await response.json();
        console.dir(json)
        return [json.image, json.name,tokenId];

    }catch(error){
        console.log('Error getMetadataFromURI in NFTContractHelper.js: ', error)

    }
}




// async function getAllTokensMetadataArray(owner){
//     try{
//         if(owner ==="me"){
//             owner = await window.ethereum.request({method: 'eth_accounts'});
//             owner = owner[0];
//         }
//         // else wurde schon übergeben von FriendsElement
//         const ownersTokenIds = await NFTContract.methods.ownersTokenIds(owner).call();
//         var array =[];
//         // wenn versendet wird und man 0 hat zeit er 0 an?? komisch :(
//         if(ownersTokenIds[0] !== '0'){}
//             // token ID ==> TokenURI ==> token Metadata ==> Token Metadata Lisa_____ hiten nochmal Id übergeben um mit in Metadata machen
//             for(var i=0;i<ownersTokenIds.length;i++){
//                 array.push(await getMetadataFromURI(await getTokenUri(ownersTokenIds[i]),ownersTokenIds[i] ) );
//             }
//         return array;
//     }catch(error){
//         console.log("Error getAllTokensMetadataArray() : ", error);
//     }
// }



async function getOwnerOfTokenId(tokenId){
    var owner;
   // try get owner from blockchain, if fail, return "error"
    try{
        owner = await NFTContract.methods.ownerOf(tokenId).call();
    }catch(err){
        owner ="error"
    }
    return owner.toLowerCase();
}



async function sendNFT(to,tokenId,changeSnackbar,onCloseClick){

    const _to =to;
    const _tokenId =tokenId;
    // if Metamask is conncted userdata = {address} if MCB Wallet userdata={address,privkey,pubkey,....}
    const userdata = JSON.parse(sessionStorage.getItem("userdata"))

    if(userdata.metamask === true){// Metamask

        return await sendNFTinMetamask(_to,_tokenId,userdata,changeSnackbar,onCloseClick) // changeSnackbar ist eine Funktion

    }else{// MCB Wallet

         const tx = await sendNFTInfura(  userdata.address, _to, userdata.privatekey, _tokenId,"data","contractAddr"  ) //(from,to,privateKey,value)
         return {netzwerk: "mcb", success: true, error:"kein error",tx: tx}; // Erfolgreiche Transaktion
    }
}





async function sendNFTinMetamask(_to,_tokenId,userdata,changeSnackbar,onCloseClick){ // changeSnackbar ist eine Funktion
    
    try {
        const _from = await window.web3.currentProvider.selectedAddress;
        const tx = await NFTContract.methods.transferFrom(_from,_to,_tokenId).send({from:_from})

        .on('transactionHash', function(hash){
            console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
            changeSnackbar("Transaktion wurde in MetaMask bestätigt.")
            onCloseClick()
        })
    
        console.log("Transaktion erfolgreich (NFT Send):", tx);
        return {netzwerk: "metamask", success: true, error:"kein error",tx: tx}; // Erfolgreiche Transaktion

    } catch (error) {
        console.error("Fehler beim Senden der NFT:", error);

        // ❌ Benutzer hat die Transaktion abgelehnt
        if (error.code === 4001) {
            return {netzwerk: "metamask", success: false, error:"Transaktion abgelehnt. Bitte bestätige die Transaktion in MetaMask."}; 
        }
        // ❌ Ungenügendes Gas oder unzureichende Mittel
        else if (error.message.includes("insufficient funds")) {
            return {netzwerk: "metamask", success: false, error:"Nicht genug ETH für die Gasgebühren. Bitte lade dein Wallet auf."}; 
        }
        // ❌ Ungültige Adresse
        else if (error.message.includes("invalid address")) {
            return {netzwerk: "metamask", success: false, error:"Ungültige Empfängeradresse."}; 
        }
        // ❌ Andere Fehler
        else {
            return {netzwerk: "metamask", success: false, error: error.message}; 
        }

    }
}








export{getTokenUri};
export{getMetadataFromURI};
//export{getAllTokensMetadataArray};

export{getOwnerOfTokenId};
export{sendNFT};
export{checkIfTokenExists};




export{getAllMetadataFromURI};