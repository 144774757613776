
import classes from './Single.module.css';


import NFTFormatEasyOnePage from '../../../components/NFT/NFTFormatEasyOnePage'
import LazyNFT from '../../../components/NFT/LazyNFT'

import {useState,useEffect} from 'react'
import {getAllSingles,getTokenIdFromSearchLimit,
    getAllSinglesOnChain
} from '../../../node/NFTData';

import {getAllMyTokenIDs_On_Off_chain} from '../../../node/NFTData'

const LIMIT_LOAD = 15

//if props.user. Dann kein Marketplace sondern eigene ansicht!
//props.filter = // onChain, offChain, myLikes or null (string)
// props.searchValue input.  "" show all
function Single(props){




    return( 
<>
        {!props.userMode && <>

        {props.searchedTokenList.length === 0 ?

        props.tokenList.map(  element =>  <LazyNFT key={element.tokenid} tokenId={element.tokenid}/>  )

        :

        props.searchedTokenList.map(  element => <LazyNFT key={element.tokenid} tokenId={element.tokenid}/>  )
        }
        </>}


        {props.userMode && <>


            {props.tokenList_myNFT.map(  element =>  <LazyNFT key={element.tokenid} tokenId={element.tokenid}/>  )}

      
        </>}



</>
        );


}

export default Single;




