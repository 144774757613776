
import {NFTContract,NFTContractAddress} from './NFTContract';
import {BuyNFTContract,BuyNFTContractAddress} from './BuyNFT'
import {UserContract,userContractAddress} from './UserContract'

import {_web3} from '../web3/Web3'
var web3 = _web3.mcbWallet
const userdata = JSON.parse(sessionStorage.getItem("userdata"))
if(userdata !== null){
    userdata.metamask === true ? web3 = _web3.metamask : web3 = _web3.mcbWallet
}


//const CHAIN = 'sepolia'
const CHAIN = 'mainnet'
const CHAIN_ID_mainnet = 1
const CHAIN_ID_sepolia = 11155111

const used_CHAIN_ID = CHAIN_ID_mainnet


const ONE_GWEI = 1e9;
var Tx = require('ethereumjs-tx').Transaction
const Common = require('@ethereumjs/common').default;
const common = new Common({ chain: CHAIN });


export async function sendEtherInfura(from,to,privateKey,value){

    value = parseInt(value)

    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    const gasPrice = await web3.eth.getGasPrice().catch(error => { console.error("Error occurred while getting Gas Price:", error); });
    const txCount = await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount}).catch(error => { console.error("Error occurred while fetching transaction count:", error); });

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: to,
        value: value,
        gasLimit: web3.utils.toHex(21000),
        gasPrice:web3.utils.toHex(gasPrice),
        chainId: used_CHAIN_ID 
    }
    //Create TRANSACTION and send to ApprovalView to sign
    //const tx =  new Tx(txObject,{'chain': netzwerk}) 
    
    const tx =  new Tx(txObject,{ common }) 
    return tx
}


/**
 * Sends an ERC20 token from one address to another using Infura.
 * @param {string} from - Sender's address.
 * @param {string} tokenContractAddress - The ERC20 token contract address.
 * @param {string} to - Recipient's address.
 * @param {string} privateKey - Sender's private key (with 0x prefix).
 * @param {string|number} amount - Amount of tokens to send in "token units" (e.g., 1.5).
 * @param {number} decimals - The token decimals (usually 18 for many tokens).
 * @returns {Promise<Tx>} - A promise that resolves to the transaction object.
 */

export async function sendERC20Infura(from, to, privateKey, amount, tokenContractAddress, decimals = 0) {
  // Convert the amount to the smallest unit (i.e., amount * 10^decimals)
  const tokenAmount = web3.utils.toBN(
    web3.utils.toWei(amount.toString(), 'ether') // using 'ether' as a helper for 10^18 conversion
  ).div(web3.utils.toBN(10).pow(web3.utils.toBN(18 - decimals)));
  
  // Alternatively, if the token doesn't have 18 decimals, you can do:
  // const tokenAmount = web3.utils.toBN(amount).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals)));
  
  // Create the data field by encoding the transfer function call:
  const data = web3.eth.abi.encodeFunctionCall({
    name: 'transfer',
    type: 'function',
    inputs: [
      { type: 'address', name: '_to' },
      { type: 'uint256', name: '_value' }
    ]
  }, [to, tokenAmount.toString()]);

  // Remove the "0x" prefix from the private key and create a buffer:
  privateKey = privateKey.slice(2);
  const privKeyBuffer = Buffer.from(privateKey, 'hex');

  // Get the current gas price and transaction count (nonce)
  const gasPrice = await web3.eth.getGasPrice();
  const txCount = await web3.eth.getTransactionCount(from);
  const nonce = web3.utils.toHex(txCount);

  // Set a gas limit (ERC20 transfers typically require more gas than a simple ETH transfer)
  const gasLimit = web3.utils.toHex(60000);

  const txObject = {
    nonce: nonce,
    to: tokenContractAddress, // Token contract is the recipient
    value: '0x0',             // No ETH is being transferred
    gasLimit: gasLimit,
    gasPrice: web3.utils.toHex(gasPrice),
    data: data,
    chainId: used_CHAIN_ID   // Make sure this is set to the proper chain ID
  };

  // Create and return the transaction object (to be signed and sent)
  const tx = new Tx(txObject, { common });
  return tx;
}






export async function sendNFTInfura(from,to,privateKey,tokenid){


    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    const gasPrice = await web3.eth.getGasPrice()

    const txCount =  await web3.eth.getTransactionCount(from)

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
    nonce: nonce,
    to: NFTContractAddress,
    from:from,
    gas: web3.utils.toHex('135778'),
    gasPrice:web3.utils.toHex(gasPrice),
    data: NFTContract.methods.transferFrom(from,to,tokenid).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx
    // tx.sign(privateKey)
    // const serializedTransaction = tx.serialize()
    // const raw = '0x' + serializedTransaction.toString('hex')
    // web3.eth.sendSignedTransaction(raw ).on('receipt', console.log)

}


export async function mintNFTInfura(from,privateKey,id,metaDataURL){



    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    const gasPrice = await web3.eth.getGasPrice()
    const gasPriceHex = web3.utils.toHex(gasPrice)

    const gas =web3.utils.toHex('257965')
    const erhöhtergasPrice = web3.utils.toHex(web3.utils.toBN(gasPrice).mul(web3.utils.toBN(12)).div(web3.utils.toBN(10))) // 20% höherer Gaspreis

    const txCount=  await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount})

    const estimatedGas = await NFTContract.methods.mintToken(from, metaDataURL, id).estimateGas({ from });
    const erhöterGas = web3.utils.toHex(estimatedGas + 50000) // Erhöhe das Gas leicht

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: NFTContractAddress,
        from: from,
        gas: erhöterGas,
        gasPrice: erhöhtergasPrice,
        data: NFTContract.methods.mintToken(from,metaDataURL,id).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx;
    // console.log(tx)
    // tx.sign(privateKey)
    // const serializedTransaction = tx.serialize()
    // const raw = '0x' + serializedTransaction.toString('hex')
    // return web3.eth.sendSignedTransaction(raw ).on('receipt', console.log)

}


export async function buyTokenOffInfura(privateKey, from,_metadataURI,_tokenId,_seller,_preis){
    


    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    const gasPrice = await web3.eth.getGasPrice()

    const txCount=  await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount})

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: BuyNFTContractAddress,
        from: from,
        value: web3.utils.toHex(web3.utils.toWei(_preis,"ether")),
        gas: web3.utils.toHex('257965'),
        gasPrice:web3.utils.toHex(gasPrice),
        data: BuyNFTContract.methods.buyTokenOff(_metadataURI,_tokenId,_seller).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx

    // tx.sign(privateKey)
    // const serializedTransaction = tx.serialize()
    // const raw = '0x' + serializedTransaction.toString('hex')
    // return web3.eth.sendSignedTransaction(raw ).on('receipt', console.log)

}


export async function buyTokenOnInfura(privateKey, from, _tokenId, _seller, _creator, _preis){
    

    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    var gasPrice = await web3.eth.getGasPrice()

    const txCount=  await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount})

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: BuyNFTContractAddress,
        value: web3.utils.toHex(web3.utils.toWei(_preis,"ether")),
        gas: web3.utils.toHex('257965'),
        gasPrice:web3.utils.toHex(gasPrice),
        data: BuyNFTContract.methods.buyTokenOn(_seller,_tokenId,_creator).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx
    // console.log(tx)
    // tx.sign(privateKey)
    // const serializedTransaction = tx.serialize()
    // const raw = '0x' + serializedTransaction.toString('hex')
    // return web3.eth.sendSignedTransaction(raw ).on('receipt', console.log)

}



export async function updateFriendsfura(privateKey, from, name, friendaddress){
    
    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    var gasPrice = await web3.eth.getGasPrice()

    const txCount=  await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount})

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: userContractAddress,
        gas: web3.utils.toHex('257965'),
        gasPrice:web3.utils.toHex(gasPrice),
        data: UserContract.methods.updateFriends(name,friendaddress).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx;
    // console.log(tx)
    // tx.sign(privateKey)
    // const serializedTransaction = tx.serialize()
    // const raw = '0x' + serializedTransaction.toString('hex')
    // return web3.eth.sendSignedTransaction(raw ).on('receipt', console.log)

}


export async function deleteFriendfura(privateKey, from, friendaddress){
    
    privateKey = privateKey.slice(2,privateKey.length) // remove 0x
    privateKey = Buffer.from(privateKey,'hex')

    var gasPrice = await web3.eth.getGasPrice()

    const txCount=  await web3.eth.getTransactionCount(from, (err,txCount) =>  {return txCount})

    const nonce = web3.utils.toHex(txCount);
    const txObject = {
        nonce: nonce,
        to: userContractAddress,
        gas: web3.utils.toHex('257965'),
        gasPrice:web3.utils.toHex(gasPrice),
        data: UserContract.methods.deleteFriend(friendaddress).encodeABI()
    }
    //SIGN TRANSACTION
    const tx =  new Tx(txObject,{ common }) 
    return tx;
}


function callbackFunc(){}
