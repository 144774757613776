import React,{useContext,useState} from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Stelle sicher, dass du qrcode.react installiert hast
import classes from './DepositView.module.css';

import {UserContext} from '../../UserProvider'
import useNightMode from '../../customHooks/useNightMode';

import IntegrationWrapper from './IntegrationWrapper'

const DepositView = (props) => {
  // Ersetze diese Adresse durch deine tatsächliche Ethereum-Adresse

  const userData = useContext(UserContext)
  const theme = useNightMode()

  const [copyStatus, setCopyStatus] = useState("Copy wallet address");

  const handleCopy = () => {
    navigator.clipboard.writeText(userData.address)
      .then(() => {
        setCopyStatus("Copied!");
        setTimeout(() => {
          setCopyStatus("Copy wallet address");
        }, 1500);
      })
      .catch((err) => {
        console.error("Failed to copy wallet address:", err);
      });
  };

  return (

    <IntegrationWrapper onClickBack={props.onClickBack} headerText={"deposit"}>

  <div className={classes.container} style={{color: theme.font}}>
        <h3>Scan or copy address to deposit tokens</h3>
        <div className={classes.qrContainer}>
          <QRCodeCanvas value={userData.address} size={110} />
        </div>
        <p className={classes.address}>{userData.shortAddress}</p>

        <button className={classes.copyButton} onClick={handleCopy}>
          {copyStatus}
        </button>
        <p className={classes.info}>
          You can receive any token from any EVM-based network to this address.
        </p>
      </div>


    </IntegrationWrapper>



  );
};

export default DepositView;
