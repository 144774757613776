import {getOptions} from './databank'
import {getCurrentUser} from '../web3/HelperFunctions'
import {fetchi} from '../globalData'

import StandartProfilPic from '../images/background.jpeg';



//return {exists: true, extension: '.jpeg'}
async function checkWhichEndingFileHas(fileName){ //[".png",".jpeg",".jpg"]
    const response = await fetch(fetchi + "/checkFileEnding/" + fileName);
    const data = await response.json();
    return data
}



// returns ["imageURL"], if no pic rteurns [] empty array
async function getProfilePicURL(user){

    var userAddress;
    if(user === "me"){
        userAddress = await getCurrentUser();
    }else{
        userAddress = user;
    }
    //const types = [".png",".jpeg",".jpg"]
    const ending = await checkWhichEndingFileHas(userAddress) //return {exists: true, extension: '.jpeg'}
    if(!ending.exists){
        return StandartProfilPic
    }
    const url = fetchi+ "/images/profile/"+ userAddress + ending.extension
    return url
}

// name in server is tokenId. jpg .png
function uploadNFTImageToServer (image,tokenId) {

    var imgType = image.type.substring(image.type.lastIndexOf("/")+1,1000);

    var blob = image.slice(0, image.size);
    var newImage = new File([blob], tokenId +"." + imgType);
    var formData = new FormData()
    formData.set('image',newImage)

    console.log(formData)

    fetch(fetchi+ '/uploadUserImage', {
                method: 'POST',
                body: formData
            }).then(console.log)

}

async function getNFTImageServerURL(tokenId){
    //const types = [".png",".jpeg",".jpg"]
    const ending = await checkWhichEndingFileHas(tokenId) //return {exists: true, extension: '.jpeg'}
    const url = fetchi+ "/images/profile/"+ tokenId + ending.extension
    return url
}



export async function uploadProfileImage (image) {

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const userAddress = userdata.address

    var type = image.type;
    var imgType = type.substring(type.lastIndexOf("/")+1,1000);
    console.log(imgType);
    // cannot change name of file so copy file and create new one with other name
    // set Name to address from person
    var blob = image.slice(0, image.size);
    var newFile = new File([blob], userAddress +"." + imgType);

    // FromData is a list from keys and values. set key=image and value = File. 'image is important because same name is used in server'
    var formData = new FormData();
    formData.set('image',newFile);

    // delete old picture
    const params = { userAddress: userAddress};
    const options = { method: 'POST',headers:{'content-type': 'application/json'},body: JSON.stringify( params ) };
    fetch(fetchi+  '/deleteProfilPic',options).then(res => { return res.json()}).then(res=>{console.log(res)

    }).then(()=>{
            //upload new picture

        console.log(formData)
        fetch( fetchi+ '/uploadUserImage', {
            method: 'POST',
            body: formData
        }).then(console.log)//.then(window.location.reload())
    })
    
}


export {getNFTImageServerURL}
export {uploadNFTImageToServer}
export {getProfilePicURL}