import classes from './FriendsPage.module.css';
import Friends from '../../components/friendComp/Friends';
import {useState,useEffect,useContext} from 'react';

function FriendsPage(){

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className={classes.container}>
            <Friends />
        </div>
    );
}

export default FriendsPage;