// Import nightMode Boolean from Navbar Switch
import {nightMode} from './components/home/Navbar3'

//dark
const dark1 = 'rgb(32, 34, 37)'//{backgroundColor:'rgb(32, 34, 37)'}
const dark1opacity = 'rgba(32, 34, 37, 0.6)'

const dark2 = 'rgb(48, 51, 57)'//{backgroundColor:'rgb(48, 51, 57)'}
const darkFont = 'rgb(201, 201, 201)'//{color:'rgb(201, 201, 201)'}
const darkFontHighlight = 'rgb(150,150,150)'//{color:'rgb(201, 201, 201)'}

const pngDark = 'invert(0.75)'//{filter:'invert(0.75)'}
const borderDark = '1px solid rgb(22, 22, 22)'//{border: '1px solid rgb(212, 212, 212)'}

const dark11 = 'rgb(11, 11, 11)'

const fontGreyDark = 'rgb(146, 146, 146)'

const hoverButtonDark = 'rgb(39, 41, 46)'


//light
const white = 'white'//{backgroundColor:'white'}
const whiteopacity = 'rgba(255,255,255, 0.6)'
const lightGrey = 'rgb(243, 243, 243)'//{backgroundColor:'rgb(243, 243, 243)'}
const brightFont = 'black'//{color:'black'}
const brightFontHighlight = 'rgb(77,77,77)'//{color:'rgb(201, 201, 201)'}

const pngNormal = ''//{}
const borderBright = '1px solid rgb(212, 212, 212)' //{border: '1px solid rgb(212, 212, 212)'}
const barkBlue = 'rgb(6, 29, 42)'

const boxColorLight = '#f0f2f5'
const boxShadowLight = '0 4px 8px rgba(0, 0, 0, 0.1)'
const boxColorDark = 'rgb(48, 51, 57)'

const fontGreyLight = 'rgb(120, 120, 120)'


const hoverButtonBright = 'rgb(213, 213, 213)'






const dark = [
    '#d5d7e0',
    '#acaebf',
    '#8c8fa3',
    '#666980',
    '#4d4f66',
    '#34354a',
    '#2b2c3d',
    '#1d1e30',
    '#0c0d21',
    '#01010a',
  ]

//const darkTheme= {color1:dark[9], color2:dark[8],color3:dark[8],font:darkFont,png:pngDark,border:borderDark}
const darkTheme= {color1:dark1, 
                  color1opacity: dark1opacity,
                  color2:dark2,
                  color3:dark2,
                  navbar:dark1,
                  font:darkFont,
                  fontHigh:darkFontHighlight,
                  png:pngDark,
                  border:borderDark,
                  barkBlue:barkBlue,
                  startPage:dark11,
                  boxColor:boxColorDark,
                  fontGrey: fontGreyDark,
                  hoverButton: hoverButtonDark
                  
                }

const brightTheme= {color1:white, 
      color1opacity: whiteopacity,
      color2:lightGrey,
      color3:white,
      navbar:white,
      font:brightFont,
      fontHigh:brightFontHighlight,
      png:pngNormal,
      border:borderBright,
      barkBlue:barkBlue,
      startPage:white,
      boxColor:boxColorLight,
      boxShadow:boxShadowLight,
      fontGrey: fontGreyLight,
      hoverButton: hoverButtonBright


    }

const theme = brightTheme

const themes ={dark:darkTheme,bright:brightTheme}

export {theme}
export {themes}