import React, { useState, useContext, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import { NightContext } from '../../../NightModeProvider';
import { themes } from '../../../ColorTheme';


//funktioniert akutell für Friends kann man aber auch für alles andere anpassen
const SearchBar = ({ friends, setSearchedFriends }) => {
  const nightMode = useContext(NightContext);
  const [theme, setTheme] = useState(themes.bright);

  useEffect(() => {
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright);
  }, [nightMode]);

  const [searchTerm, setSearchTerm] = useState('');

  // Handler, der den Suchbegriff aktualisiert und die gefilterte Liste zurückgibt
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    const filtered = friends.filter(
      (friend) =>
        friend.friend_name.toLowerCase().includes(value.toLowerCase()) ||
        friend.friend_addr.toLowerCase().includes(value.toLowerCase())
    );
    setSearchedFriends(filtered);
  };

  return (
    <div style={{ width: 'calc(100% - 45px)', position: 'relative' }}>
      <FaSearch
        style={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          color: theme.fontGrey,
        }}
      />
      <input
        type="text"
        placeholder="Search by name or address..."
        value={searchTerm}
        onChange={handleInputChange}
        className="search-input"
        style={{
          backgroundColor: theme.color1,
          width: '100%',
          padding: '8px 10px 8px 35px',
          borderRadius: '4px',
          border: 'none',
          outline: 'none',
          fontSize: '13px',
          color: theme.font,
        }}
      />
      <style>
        {`
          .search-input::placeholder {
            color: ${theme.fontGrey};
            opacity: 1;
          }
        `}
      </style>
    </div>
  );
};

export default SearchBar;
