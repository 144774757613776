import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useLocation } from 'react-router-dom';

import MenuButtonAnimated from './MenuButton.js';
import MyButton from './MyButton.js';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

// Sidebar
import Sidebar from '../sidebar/Sidebar';
import NotificationIntegration from '../sidebar/notifications/NotificationIntegration';


//Image
import profilePic from '../../images/profil.png'
import menuPic from '../../images/menu.png'



import logoutPic from '../../images/log-out.png'
import mondPic from '../../images/mond.png'
import walletPic from '../../images/wallet.png'
import LogoMiniCut from '../homePages/LogoMini2Cut'




//myCode
import { useHistory } from "react-router-dom";
import Wallet from '../wallet/Wallet'
import AmountView from '../wallet/AmountView'

import {useState,useEffect,useContext} from 'react'
import logo from '../../images/logo.png'
import profilColor from '../../images/background.jpeg';
import notificationImg from '../../images/notification.png';
import useProfilePicture from '../../customHooks/useProfilePicture'
import {getProfilePic} from '../../customHooks/useProfilePicture'




import {getCurrentUser} from '../../web3/HelperFunctions'

//Night Mode
import {ChangeNightFunction} from '../../NightModeProvider'

//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

//User Context
import {UserContext} from '../../UserProvider'
import {ChangeUserContext} from '../../UserProvider'


const profile = <div id={"profile"} style={{display:'flex',flexDirection:'row',gap:'26px'}}> <img src={profilePic} style={{height: '20px',width: 'auto'}}></img> <div style={{fontSize:'13px'}}>Profile</div> </div>
const wallet = <div id={"profile"} style={{display:'flex',flexDirection:'row',gap:'26px'}}> <img src={walletPic} style={{height: '20px',width: 'auto'}}></img> <div style={{fontSize:'13px'}}>Wallet</div> </div>
const notification = <div style={{display:'flex',flexDirection:'row',gap:'23px'}}><img src={notificationImg} style={{height: '20px',width: 'auto',marginLeft:'1px',marginRight:'2px'}}></img>  <div style={{fontSize:'13px'}}>Notification</div> </div>
const logout = <div style={{display:'flex',flexDirection:'row',gap:'23px'}}> <img src={logoutPic} style={{height: '20px',width: 'auto',marginLeft:'3px'}}></img>  <div style={{fontSize:'13px'}}>Logout</div> </div>

const pages = ['Home','Friends', 'My-NFT','Create-NFT','NFT-Marketplace','Crypto-Chat'];
const settings = [{a:profile,b:"profile"},{a:wallet,b:"wallet"},{a:notification,b:"notification"},{a:logout,b:"logout"} ];



const ResponsiveAppBar = () => {
  const userData = useContext(UserContext)
  const setUserDataFunc = useContext(ChangeUserContext)

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const [menuOpen, setMenuOpen] = React.useState(false);


  const [walletOpen,setWalletOpen] =React.useState(false)
  const [notificationOpen,setNotificationOpen] =React.useState(false)
  const profilePicURL = useProfilePicture(userData.address)

  



  const setNightModeFunc = useContext(ChangeNightFunction)
  // Night Mode
  const nightMode = useContext(NightContext)
  const [theme,setTheme] =useState(themes.bright)
  useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  function handleNightChange(e){
    console.log("handleNightChange")

    console.log(e)
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright)
    setNightModeFunc(e.target.checked)
  }



  function closeWallet(){
    setWalletOpen(false)
  }

  function openWallet(){
    setWalletOpen(true)
  }

  function closeNotification(){
    setNotificationOpen(false)
  }

  function openNotification(){
    setNotificationOpen(true)
  }


  const history = useHistory();
  function openPage(page){
 
    closeWallet()
    if(page==="Friends"){
      history.push("/friends")
    }else if(page==="wallet"){
      openWallet()
    }else if(page==="My-NFT"){
      history.push("/mynft");
    }else if(page==="NFT-Marketplace"){
      history.push("/marketplace");
    }else if(page==="Crypto-Chat"){
      history.push("/chats");
    }else if(page==="profile"){
      history.push("/me");
    }else if(page==="notification"){
      openNotification()
    }else if(page==="logout"){
      // When logout Clear session storage. Because there is priv Key and Address saves. If Metamask there is just address saved
      sessionStorage.clear()
      // also clear userData Provider
      setUserDataFunc(false)
      history.push("/");
    }else if(page==="Create-NFT"){
      history.push("/createNFT");
    }else if(page==="Home"){
      history.push("/home");
    }
    
  }



  const handleOpenNavMenu = (event) => {
    openPage(event.currentTarget.id)
    setAnchorElNav(event.currentTarget);
    setMenuOpen(true)
  };

  const handleCloseNavMenu = (event) => {

    openPage(event.currentTarget.id)
    setAnchorElNav(null);
    setMenuOpen(false)

  };


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
    openPage(event.currentTarget.id)
  };


  const handleCloseUserMenu = (event) => {
    setAnchorElUser(null);
    console.log(event.currentTarget.id)
    openPage(event.currentTarget.id)
  };



  const [atTop, setAtTop] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Wenn pageYOffset 0 ist, bist du ganz oben
      setAtTop(window.pageYOffset === 0);
    };

    window.addEventListener('scroll', handleScroll);
    // Event-Listener beim Unmount entfernen
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const location = useLocation();

  return (

    

  <div style={{width: '100%', position:'sticky',top:'0px',zIndex:'3000'}}>
  
  {/* if go to Marketplace without login*/ }


  { true&&
  <div>


    {notificationOpen && <Wallet notification integration={<NotificationIntegration notifications={[1,2,3]} close={closeNotification}/>} closeWalletFunc={closeNotification}/>}

    {walletOpen && <Wallet closeWalletFunc={closeWallet}> </Wallet>  }

    <AppBar style={ atTop ? { boxShadow: 'none' }: {}}  sx={{backgroundColor: (atTop && location.pathname === '/marketplace') ? "transparent": theme.navbar ,position:'relative',zIndex:'3002'}}>

      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            onClick={()=>{openPage("Home")}}
            variant="h6"
            noWrap
            
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex',alignItems:'center' },
              fontFamily: 'monospace',
              fontWeight: 300,
              color: theme.font,
              cursor:"pointer",
              textDecoration: 'none',
              fontSize:'22px',
              gap: '10px'
            }}
          >
            <LogoMiniCut />

            MyCryptoBook

          </Typography>
          
          
          {/** Breites Menu */}
          { userData.address &&
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                id={page}
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: theme.font, display: 'block' }}
              >
                {page}
              </Button>
            
            ))}
          </Box>
          }
          {/** Eingeklapptes Menu */}
          { userData.address &&

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' ,color:theme.font} }}>
            <MenuButtonAnimated
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              menuIsOpen={menuOpen}
              color="inherit"
            >
              { /*<img src={menuPic} style ={{filter:theme.png,height:'30px'}}></img>  */}
            </MenuButtonAnimated>



            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none',zIndex:'3005' },
              }}
            >
              {pages.map((page) => (
                <MenuItem id={page} key={page} onClick={handleCloseNavMenu}> 
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
}

          {/** Mittleres Logo */}
          <Typography
            variant="h5"
            noWrap
            component="a"
            
            sx={{
              mr: 2,
              ml: 1,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <LogoMiniCut />
          </Typography>

          { userData.address &&
            
          <Box sx={{ flexGrow: 0 }}>

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={profilePicURL} sx={{border: '2px solid grey',height: '40px',width: '40px'}}/>
            </IconButton>


            <Menu
              sx={{ mt: '45px' ,zIndex:'3005'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >

              {settings.map((setting) => (
                <MenuItem id={setting.b} key={setting.b} onClick={handleCloseUserMenu} >
                  <Typography textAlign="center">{setting.a}</Typography>
                </MenuItem>
              ))}
              <div style={{display:'flex',flexDirection:'row',alignItems: 'center',gap:'5px'}}>
                  
                  <img src={mondPic} style={{height: '25px',width: 'auto',marginLeft: '17px',marginRight:'14px'}}></img>

                  <div style={{fontSize:'13px',marginRight: '0px'}}>Night Mode</div>
                  <Switch checked={nightMode} onChange={handleNightChange}/>


              </div>
            </Menu>
          </Box>
          }

      {/* LOGIN Integration if not logged in* */}
      {!userData.address &&
      <div style={{position:"absolute", right:"0px", display:"flex"}}>
      <MyButton /> 
      <IconButton sx={{ ml: 1 }} onClick={handleNightChange} >
                    {nightMode ? <Brightness7Icon sx={{color:"white"}}/> : <Brightness4Icon />}
      </IconButton>
      </div>
      } 
        </Toolbar>
      </Container>

      
    </AppBar>
  </div> }
  </div>

  );
};
export default ResponsiveAppBar;
