import {useEffect,useRef,useState,useContext} from 'react'
import classes from './IntegrationWrapper.module.css'

import useNightMode from '../../customHooks/useNightMode';
import BackButton from './BackButton';


function IntegrationWrapper(props) {
  const theme = useNightMode()



return (
<div  className={classes.integration}>

  <div className={classes.box} style={{backgroundColor:theme.color2,border:theme.border}}>

        <div className={classes.header} style={{color:theme.font}}> 
            { !props.noBackButton && < BackButton onClickBack={props.onClickBack}/>}
            { !props.noBackButton && <div className={classes.headerText} >{props.headerText}</div> }
        </div>

  {props.children}

  
  </div>
</div>

  );
}


export default IntegrationWrapper