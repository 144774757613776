import {fetchi} from '../globalData'
import {getOptions} from './databank'


// return [ {notification,von,zu,nft,id} ]
//if no notification: return: [] empty array
async function loadMyNotificationsFromDB(){
    const me = JSON.parse(sessionStorage.getItem("userdata")).address
    var notificcation = await fetch(fetchi+ "/databank",getOptions("loadMyNotifications",{zu: me.toString()}  )).then(res => {return res.json()}).catch(console.log);

    notificcation = notificcation[0]

    console.log(notificcation)
    return notificcation;
}

async function setNotificationsToDB(notification,von,zu,nft){
    const me = JSON.parse(sessionStorage.getItem("userdata")).address
    var notificcation = await fetch(fetchi+ "/databank",getOptions("setNotification",{notification:notification,von: me.toString(),zu:zu,nft:nft}  )).then(res => {return res.json()}).catch(console.log);

    notificcation = notificcation[0]

    console.log(notificcation)
    return notificcation;
}

async function deleteNotificationDB(id){

    try {
        const response = await fetch(fetchi + "/databank", getOptions("deleteNotification", { id: id }));
        
        console.log(response)
        if (!response.ok) {
            console.error("Fehler beim Löschen der Benachrichtigung:", response.statusText);
            return false;
        }
        if (response.ok) {
            console.log("Erfolg beim Löschen der Benachrichtigung:", response.statusText);
        }

        const result = await response.json();
        console.log(result)
        return {success: true}
    } catch (error) {
        console.error("Fehler beim Löschen der Benachrichtigung:", error);
        return {success: false}
    }

}




export {loadMyNotificationsFromDB}
export {setNotificationsToDB}
export {deleteNotificationDB}

