import React,{useState,createContext} from 'react';


export const LoginContext = createContext(false);
export const ChangeLoginContext = createContext();

//{name:'',pw:'',publickey,privatekey,address}
export function OpenLoginProvider(props){

    const [openLogin,setOpenLogin] = useState(false)

    function setLoginFunc(data){
        setOpenLogin(data)


    }

    return <LoginContext.Provider value={openLogin}>
                <ChangeLoginContext.Provider value={setLoginFunc}>
                    {props.children}
                </ChangeLoginContext.Provider>
            </LoginContext.Provider>


}

//User Context
// import {UserContext} from '../../UserProvider'
// const userData = useContext(UserContext)

// const address = JSON.parse(sessionStorage.getItem("userdata")).address

function getSessionStorage(key) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return false;
    }
    return JSON.parse(stored);
  }