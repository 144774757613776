import { useState, useRef, useEffect } from 'react';
import NFTFormatEasyOnePage from './NFTFormatEasyOnePage';

const LazyNFT = ({ tokenId }) => {
  const ref = useRef();
  const [isVisible, setIsVisible] = useState(false);

  console.log("Ich rendere NFT mit TokenId: " +tokenId)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '0px',
        threshold: 0.1, // 10% des Elements muss sichtbar sein
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
    
    return () => {
      observer.disconnect();
    };
  }, []);

  return <div ref={ref}>{isVisible ? <NFTFormatEasyOnePage tokenId={tokenId} /> : null}</div>;
};

export default LazyNFT;
