import {useEffect,useRef,useState,useContext} from 'react'
import classes from './HistoryView.module.css'
//material UI
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import receiveImg from '../../images/download.png'
//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'
import { ethers } from "ethers";
import {shortAddr} from '../../web3/LoadingFunctions'

import {API_ETHERSCAN} from '../../globalData'

import BackButton from './BackButton'
import IntegrationWrapper from './IntegrationWrapper'

import { SendHorizontal, ArrowDownToLine, Code } from 'lucide-react';

import {getNameFromAddress} from '../../customHooks/useNameFromAddress'



import useWeb3 from '../../customHooks/useWeb3';
import useNightMode from '../../customHooks/useNightMode';


//const URL = "https://sepolia.etherscan.io/tx/"
const URL = "https://etherscan.io/tx/"


//const CHAIN = "sepolia"
const CHAIN = 'mainnet'
const CHAIN_ID_mainnet = 1



function numberToDate(number){
    switch(number){
        case 1:
        return "Jan"
        break;
        case 2:
        return "Feb"
        break;
        case 3:
        return "Mar"
        break;
        case 4:
        return "Apr"
        break;
        case 5:
        return "May"
        break;
        case 6:
        return "Jun"
        break;
        case 7:
        return "Jul"
        break;
        case 8:
        return "Aug"
        break;
        case 9:
        return "Sep"
        break;
        case 10:
        return "Oct"
        break;
        case 11:
        return "Nov"
        break;
        case 12:
        return "Dez"
        break;
    } 
}

function HistoryView(props) {

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const web3 = useWeb3()
    const theme = useNightMode()
    const [loadingHistory,setLoadingHistory] =useState(true)
    const [txs,setTxs] = useState([]) //tx.hash,from,to,value,data

    async function getMyHistory() {
        const provider = new ethers.providers.EtherscanProvider(CHAIN, API_ETHERSCAN);
    
        if (!ethers.utils.isAddress(userdata.address)) {
            throw new Error("Ungültige Ethereum-Adresse: " + userdata.address);
        }
    
        let history = await provider.getHistory(userdata.address);
    
        // Jede Transaktion wird asynchron verarbeitet und ggf. erweitert:
        history = await Promise.all(history.map(async (tx) => {
            // Standardmäßig: kein ERC20-Transfer
            tx.erc20_transfer = false;
            tx.erc20_token_amount = null;
            tx.erc20_token_name = null;
            tx.erc20_token_contract = null;
            tx.erc20_token_decimals = null;
    
            // Prüfen, ob es sich um einen ERC20-Transfer handelt (typische Methodensignatur: 0xa9059cbb)
            if (tx.data && tx.data !== "0x" && tx.data.startsWith("0xa9059cbb")) {
                try {
                    // Erstelle ein Interface zur Dekodierung der transfer()-Funktion
                    const iface = new ethers.utils.Interface([
                        "function transfer(address to, uint256 amount)"
                    ]);
                    const decoded = iface.decodeFunctionData("transfer", tx.data);
    
                    // Markiere die Transaktion als ERC20-Transfer und speichere die übertragene Menge
                    tx.erc20_transfer = true;
                    tx.erc20_token_amount = decoded.amount.toString();
    
                    // Erstelle ein Contract-Objekt zur Abfrage von Token-Informationen (Name und Dezimalstellen)
                    const tokenContract = new ethers.Contract(tx.to, [
                        "function name() view returns (string)",
                        "function decimals() view returns (uint8)"
                    ], provider);
    
                    // Token-Namen abrufen
                    try {
                        tx.erc20_token_name = await tokenContract.name();
                    } catch (nameError) {
                        tx.erc20_token_name = "unknown";
                    }
    
                    // Token-Dezimalstellen abrufen
                    try {
                        tx.erc20_token_decimals = await tokenContract.decimals();
                    } catch (decimalsError) {
                        tx.erc20_token_decimals = null;
                    }
    
                    // Die Contract-Adresse entspricht hier der "to"-Adresse der Transaktion
                    tx.erc20_token_contract = tx.to;
                } catch (err) {
                    console.error("Fehler beim Dekodieren des ERC20-Transfers:", err);
                }
            }
            return tx;
        }));
    
        return history;
    }
    
    async function getMyHistoryAlt (){
        let provider = new ethers.providers.EtherscanProvider(CHAIN,API_ETHERSCAN);

        if (!ethers.utils.isAddress(userdata.address)) {
            throw new Error("Ungültige Ethereum-Adresse: " + userdata.address);
        }
        let history = await provider.getHistory(userdata.address);
        return history
    }


    async function getTransactions() {


        try {
            var history = await getMyHistory()

            if (history.length === 0) {
                console.log("Keine Transaktionen gefunden.");
            } else {
                console.log("Transaktionshistorie:", history);
            }

            history = history.reverse() // latest on top
            setTxs(history)

            setLoadingHistory(false)
        } catch (error) {
            console.error("Fehler beim Abrufen der Transaktionshistorie:", error);
            setLoadingHistory(false)
        }
    }

    useEffect(() => {
        getTransactions()
        console.log(txs)
    },[props])

    console.log("txs")

    console.log(txs)


    function openEtherscan(hash){
        window.open(URL + hash )
    }

  return (
<IntegrationWrapper headerText={"history"} onClickBack={props.onClickBack}>

    <div className={classes.list} style={{borderTop:theme.border}}>
    {
    txs.length <1 ?
    <div style={{color:theme.fontGrey, width:"100%",display:"flex",justifyContent:"center",marginTop:"10px"}}>{loadingHistory? "Loading...":"No Transactions" }</div>
    :
    txs.map(tx =>

        <TransactionEvent tx={tx} theme={theme} web3={web3} userdata={userdata} openEtherscan={openEtherscan} />


    )}
    </div>

</IntegrationWrapper>
  );
}



function TransactionEvent({tx, theme, web3, userdata, openEtherscan}){

    



    const value = tx.erc20_transfer
    ?
    ethers.utils.formatUnits(tx.erc20_token_amount,tx.erc20_token_decimals || 18).toString().slice(0,8) +" " +tx.erc20_token_name
    :
    web3.utils.fromWei(web3.utils.hexToNumberString(tx.value._hex),"ether").slice(0,8) + " ETH"

    const contractInteraction = tx.data==="0x" ? "":""
    const isContractInteraction = tx.data!=="0x"



    const action = (isContractInteraction && !tx.erc20_transfer) 
    ?
        "Contract Interaction"
    :
    
    (tx.from.toLowerCase() === userdata.address ? "Send" : "Receive") 

    const actionImg = (isContractInteraction && !tx.erc20_transfer) 
    ?
        < Code style={{filter:theme.png,height:"20px"}} />
    :
    tx.from.toLowerCase() === userdata.address 
    ? <SendHorizontal style={{filter:theme.png,height:"20px"}} />
    : <ArrowDownToLine style={{filter:theme.png,height:"20px"}}/>
   // const actionImg = tx.from.toLowerCase() === userdata.address ? <div style={{filter:theme.png}} className={classes.icon}><ArrowForwardIcon  fontSize="large"/></div> : <img style={{filter:theme.png, height:'25px',marginLeft:'5px'}} src={receiveImg}></img>
    const date = numberToDate(parseInt(new Date(tx.timestamp*1000).getUTCMonth())  +1 ) +" "+ new Date(tx.timestamp*1000).getUTCDate() +"."
    

    const isErc20_transfer = tx.erc20_transfer
    const erc20_token_name = " " +tx.erc20_token_name
    const erc20_token_amount = tx.erc20_token_amount
    const decimals = tx.erc20_token_decimals

    const toFrom = tx.from.toLowerCase() === userdata.address ? `to: ${shortAddr(tx.to)}` : `from: ${shortAddr(tx.from)}`


return(

<div key={tx.hash} style={{borderBottom:theme.border}} className={classes.txElement} onClick={()=>{ openEtherscan(tx.hash) } }>

    <div style={{display:'flex',gap:'10px'}}>

    <div className={classes.actionImgWrapper} >
    {actionImg}
    </div>
        <div style={{display:'flex',flexDirection:'column',gap:'4px',fontSize:'16px'}}>
            <div style={{color:theme.font,fontWeight:"bold"}}>{action} </div>

            <div style={{display:'flex',gap:"10px"}}> 
                <div className={classes.time} > 
                    {date}  
                </div>  
                <div style={{color:theme.font,fontWeight:"lighter"}} className={classes.fromto}>{toFrom?.toLowerCase() }</div>  
                <div className={classes.contractInteraction}> {contractInteraction}</div>
            </div>

        </div>
    </div>

    <div>

    </div>

    <div style={{display:'flex',flexDirection:'column',gap:'4px',position:'absolute',right:'18px',top:'12px',width:'120px'}}>
        <div style={{color:theme.fontGrey,display:'flex',width:'100%',justifyContent:'flex-end',fontSize:'15px',fontWeight:"bold",whiteSpace: 'nowrap'}}>  {value} </div>

        <div style={{visibility: 'hidden'}}>
            {" a"}
        </div>

    </div>

</div>
)
}


export default HistoryView