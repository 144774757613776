import {_web3} from '../web3/Web3'
import {useState,useEffect} from 'react'
import useWeb3 from '../customHooks/useWeb3'


const mainnet = '0x8f4F97eBffBD2C5CEA7d241D16DA6e6adc0972eC'
const sepolia = '0x1E161a8901D0f81F92f748eC4387618326754414'


const BuyNFTContractAddress = mainnet; //alt
const BuyMethod = ''

const BuyNFTContractABI=[
	{
		"inputs": [],
		"name": "MCBNFTAddress",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "metadataURI",
				"type": "string"
			},
			{
				"internalType": "uint256",
				"name": "id",
				"type": "uint256"
			},
			{
				"internalType": "address payable",
				"name": "seller",
				"type": "address"
			}
		],
		"name": "buyTokenOff",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "address",
				"name": "creator",
				"type": "address"
			}
		],
		"name": "buyTokenOn",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
]


export function useBuyNFTContract(){

	const [buyNFTContract,setBuyNFTContract] = useState()
	const web3 = useWeb3()

	useEffect(()=>{
		setBuyNFTContract( new web3.eth.Contract(BuyNFTContractABI,BuyNFTContractAddress) )
	},[])


	return buyNFTContract
}

var web3 = _web3.mcbWallet
const userdata = JSON.parse(sessionStorage.getItem("userdata"))
if(userdata !== null){
    userdata.metamask === true ? web3 = _web3.metamask : web3 = _web3.mcbWallet
}

const BuyNFTContract = new web3.eth.Contract(BuyNFTContractABI,BuyNFTContractAddress)



export {BuyNFTContractAddress};
export {BuyMethod};
export {BuyNFTContract};


