import React, {useState,useEffect,useContext,useLayoutEffect,useRef} from 'react';

const MyVisible = ({ iAmVisible, scrollContainerRef }) => {
  const elementRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);
  useEffect(() => {
    if (!scrollContainerRef?.current) return; // Falls der Scrollcontainer nicht existiert, beende

    const scrollElement = scrollContainerRef.current;

    // Scroll-Erkennung im Scrollcontainer
    const handleScroll = () => {
      setHasScrolled(true);
      scrollElement.removeEventListener("scroll", handleScroll);
    };

    scrollElement.addEventListener("scroll", handleScroll);

    // IntersectionObserver für den Scrollcontainer
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasScrolled) {
          console.log("Element ist im Scrollfenster sichtbar!");
          iAmVisible();
        }
      },
      {
        root: scrollElement, // Bezieht sich auf den übergebenen Scrollcontainer
        threshold: 0.5, // 50% des Elements müssen sichtbar sein
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      observer.disconnect();
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled, scrollContainerRef]); // Neu: `scrollContainerRef` als Abhängigkeit

  return (
    <div style={{ height: "2px", width: "50px" }}>
      <div ref={elementRef} style={{ height: "2px", textAlign: "center" }} />
    </div>
  );
};

export default MyVisible