import {getOptions} from './databank'
import {fetchi} from '../globalData'

async function follow2(person){ // Alt
    const currentUser = JSON.parse(sessionStorage.getItem("userdata")).address

    fetch( fetchi + "/databank",getOptions("follow",{person: person,follower: currentUser.toLowerCase()} )).catch(console.log);

    //also set to Notifications DB
    fetch( fetchi + "/databank",getOptions("setNotification",{notification:'follow', von:currentUser.toLowerCase(), zu:person,nft:''} )).catch(console.log);

}

async function unfollow(person){
    const currentUser = JSON.parse(sessionStorage.getItem("userdata")).address

    try {
        const unfollowResponse = await fetch(
            fetchi + "/databank",
            getOptions("unfollow",{person: person,follower: currentUser.toLowerCase()} ));


        //  Responses als Objekte zurückgeben (wenn nötig)
        return {
            unfollowResult: await unfollowResponse.json(),
        };

    } catch (error) {
        console.log(error);
        return null;  // Im Fehlerfall kannst du `null` oder einen Fehlerwert zurückgeben
    }

}


async function follow(person) {
    const currentUser = JSON.parse(sessionStorage.getItem("userdata")).address;

    try {
        const followResponse = await fetch(
            fetchi + "/databank",
            getOptions("follow", { person: person, follower: currentUser.toLowerCase() })
        );

        const notificationResponse = await fetch(
            fetchi + "/databank",
            getOptions("setNotification", {
                notification: 'follow',
                von: currentUser.toLowerCase(),
                zu: person,
                nft: ''
            })
        );

        // Beide Responses als Objekte zurückgeben (wenn nötig)
        return {
            followResult: await followResponse.json(),
            notificationResult: await notificationResponse.json()
        };

    } catch (error) {
        console.log(error);
        return null;  // Im Fehlerfall kannst du `null` oder einen Fehlerwert zurückgeben
    }
}



export {follow,unfollow}