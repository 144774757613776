import classes from './SendNFTIntegration.module.css';
import FriendListElement from '../PopupFenster/FriendListElement';
import {useState,useContext, useEffect} from 'react';
import {getAllFriends,getAllFriendsPromise} from '../../web3/GetAllFriends';
import {sendNFT} from '../../web3/NFTContractHelper';
import ApprovalView from './ApprovalView'
import {ChangeSnackbarFunction} from '../../SnackbarProvider'





function SendNFTIntegration(props){

    const [allFriends,setAllFriends]= useState([])
    const [txObj,setTxObj] = useState(false)

    //Snackbar
    const changeSnackbar = useContext(ChangeSnackbarFunction)

    useEffect(()=>{
        getAllFriendsPromise().then(res => {setAllFriends(res)});
    },[])



    // function is triggert from Friend Element
    async function send(friend_addr,tokenId){

        var response = await sendNFT(friend_addr,tokenId,changeSnackbar,props.onCloseClick) // ich gebe hier die Funktion changeSnackbar rein
        //response: {netzwerk: "metamask", success: true, error:"kein error",tx: tx}

        console.log(response)
        // METAMASK Netzwerk
        if(response.netzwerk =="metamask"){
            if(response.success){
                changeSnackbar("NFT was sent successfully!")
                // Seite erneuern
                if(props.transactFinishAndReload){
                    props.transactFinishAndReload("metamask")
                }
            }
            if(!response.success){
                changeSnackbar(response.error)
            }
        }

        //MCB Netzwerk
        if(response.netzwerk =="mcb"){

            console.table(response.tx)
            setTxObj(response.tx) // hierdurch wird Approval Wallet geöffnet
        }

    }



    return (

    <div style={{width:'100%',justifyContent: 'center',display: 'flex'}}>

        { txObj && <ApprovalView transactFinishAndReload={props.transactFinishAndReload} closeWalletFunc={props.closeWalletFunc} type="send NFT" tx={txObj}  />  }
        { !txObj &&
        <div className={classes.integration}>

            <div>{props.text}</div>

            <div className={classes.list}>
                {allFriends.map(item => <FriendListElement key={`${item.friend_addr}-${item.blockchain}`} send={send} closeSendModal={props.onCloseClick} friendItem={item}  tokenId={props.tokenId}  />   )}
            </div>
        </div>
        }
    </div>
    );



}

export default SendNFTIntegration;