import * as sigUtil from "@metamask/eth-sig-util";
import {PWdecrypt,getUsernameDB} from './node/username'
import {getPublicKey} from './node/databank'
 const ethUtil = require('ethereumjs-util');
const EthCrypto = require('eth-crypto');
const { ethers } = require('ethers');

async function getPublicKey_MCBWallet(address){
  const username = await getUsernameDB(address) // return {name,pw,privatekey,publickey,address}
  return username.publickey
}
async function getPrivateKey_MCBWallet(address){
  console.log("addresse Für Private Key: " + address)
  const username = await getUsernameDB(address) // return {name,pw,privatekey,publickey,address}
  return username.privatekey
}

export async function encryptMessage_MCBWallet(message,address) {
  console.log("address " + address)
  console.log("ENcryptMessage_MCBWallet")
  const publicKey = await getPublicKey_MCBWallet(address)
  console.log("publickey: " + publicKey);

  const encrypted = await EthCrypto.encryptWithPublicKey(
    publicKey.slice(2),
    message
  );
  console.log("Encrypted: "+EthCrypto.cipher.stringify(encrypted))

  return EthCrypto.cipher.stringify(encrypted);
}

export async function decryptMessage_MCBWallet(encryptedString,address,password) {
  const privateKey_Verschlüsselt = await getPrivateKey_MCBWallet(address) // verschlüsselt

  var privateKey = PWdecrypt(privateKey_Verschlüsselt,password)

  const encryptedObject = EthCrypto.cipher.parse(encryptedString);


  const decrypted = await EthCrypto.decryptWithPrivateKey(
    privateKey.slice(2),
    encryptedObject
  );
  console.log("decrypted: " + decrypted)
  return decrypted;
}


export async function encryptMessage_Metamask(message, address){ // Metamask

    const publicKey = await getPublicKey(address)
    console.log("publickey: " + publicKey);

    const encryptedMessage = ethUtil.bufferToHex(
      Buffer.from(
        JSON.stringify(
          sigUtil.encrypt({
            publicKey: publicKey,
            data: message,
            version: 'x25519-xsalsa20-poly1305',
          })
        ),
        'utf8'
      )
  );
  console.log("Encrypted: " + encryptedMessage)
  return encryptedMessage   
}

export async function decryptedMessage_Metamask(encryptedMessage){ // Metamask

  const userdata = JSON.parse(sessionStorage.getItem("userdata"))

  const decryptedMessage = await window.ethereum.request({
    method: 'eth_decrypt',
    params: [encryptedMessage, userdata.address],
  })
 

  console.log("decryptedMessage_Metamask")
  
  console.log(decryptedMessage)
  return decryptedMessage
}


