import * as React from 'react';
import {useEffect,useRef,useState,useContext} from 'react'
import classes from './ExtractKey.module.css'
//material UI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
//Comps
import IntegrationWrapper from './IntegrationWrapper';

import {loginDB,PWdecrypt} from '../../node/username'
// Hooks
import useNightMode from '../../customHooks/useNightMode';
import useWeb3 from '../../customHooks/useWeb3';

function ExtractKey(props) {
    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
 
    const theme = useNightMode()
    const web3 = useWeb3()
    const [password,setPassword] = useState("")
    const [key,setKey] = useState("")

    const [loginError,setLoginError] = useState("")
    const [approved,setApproved] = useState(false)


    // check PW and decrypt Private Key
    async function checkPW(){
        const res = await loginDB(userdata.name,password) // not exist: return "error" else: {name:'',pw:'',publickey,privatekey,address}

        console.log(res)
        if( res === "error"){
            setLoginError("unvalid password")
            return false
        }
        return true
    }


    async function approve(){

        // checks if PW correct
        if(! await checkPW()){ 
            return
        }
        const decryptedPrivateKey = PWdecrypt(userdata.privatekey,password)
        setKey(decryptedPrivateKey)
        setApproved(true)
    }



  return (
    <IntegrationWrapper onClickBack={props.goBack} headerText={"private Key"}>

            {!approved &&
            <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.box}>

                <div style={{borderBottom: theme.border}} className={classes.approve}>

                    <div className={classes.pwWrapper}>
                        <div style={{color:'red'}}>Don't show your private Key to anyone. The person who has your private Key has accsess to all your funds.</div>
                        <div style={{color: theme.font}}>Confirm with your Password to show your Private Key. </div>
                        <TextField  value={password} onChange={(e)=>{setPassword(e.target.value);setLoginError("")}} helperText={loginError} error={loginError!==""} label="Password" type="password"  sx={{width:'100%'}}  />
                    </div>

                </div>

                <div className={classes.approve}>

                    <Button onClick={approve} sx={{width:'90px'}} variant="contained">  Approve</Button>

                </div>

            </div>
            }

            {approved &&
            <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.box}>

                <div style={{borderBottom: theme.border}} className={classes.approve}>

                    <div className={classes.key}>{key}</div>

                </div>

            </div>
            }

    </IntegrationWrapper>
  );
}


export default ExtractKey