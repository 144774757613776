
/**
 Diese Funktion nimmt eine Funktion wie 
const tx = await BuyNFTContract.methods.buyTokenOn(_seller,_tokenId,_creator).send({from:_from, value: web3.utils.toWei(_preis,"ether") })
  
Die Funktion wird ausgeführt und bei Erfolg wird zurückgegeben:

{netzwerk: "metamask", success: true, error:"kein error",tx: tx};

und bei Fehlschlag:
{netzwerk: "metamask", success: false, error: error.message};

zudem wird sobald die Transaktion bestätigt wird die Snackbar geupdatet
  
 */

async function handlingMetamaskFunctionsMitErrorResponse(ele1,ele2,funktion,changeSnackbar, info){ // changeSnackbar ist eine Funktion
    
    try {
        const tx = await funktion(ele1[0],ele1[1],ele1[2]).send(ele2)
        .on('transactionHash', function(hash){
            console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
            changeSnackbar("Transaktion wurde in MetaMask bestätigt.")
            //onCloseClick()
        })
    
        console.log(`Transaktion erfolgreich ${info}:`, tx);
        return {netzwerk: "metamask", success: true, error:"kein error",tx: tx}; // Erfolgreiche Transaktion

    } catch (error) {
        console.error(`Fehler bei ${info}:`, error);

        // ❌ Benutzer hat die Transaktion abgelehnt
        if (error.code === 4001) {
            return {netzwerk: "metamask", success: false, error:"Transaktion abgelehnt. Bitte bestätige die Transaktion in MetaMask."}; 
        }
        // ❌ Ungenügendes Gas oder unzureichende Mittel
        else if (error.message.includes("insufficient funds")) {
            return {netzwerk: "metamask", success: false, error:"Nicht genug ETH für die Gasgebühren. Bitte lade dein Wallet auf."}; 
        }
        // ❌ Ungültige Adresse
        else if (error.message.includes("invalid address")) {
            return {netzwerk: "metamask", success: false, error:"Ungültige Empfängeradresse."}; 
        }
        // ❌ Andere Fehler
        else {
            return {netzwerk: "metamask", success: false, error: error.message}; 
        }

    }
}


export{handlingMetamaskFunctionsMitErrorResponse}