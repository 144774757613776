import classes from './NFTSlider.module.css';
import React, { useEffect,useState } from 'react';
import NFTFormatEasyOnePage from '../../../components/NFT/NFTFormatEasyOnePage';
import LazyNFT from '../../../components/NFT/LazyNFT';
import {getAllTokenIdFromCollection} from '../../../node/NFTData'

// Loade Komponente besitze die Anzeige Komponente
function NFTSlider(props) {
    const [allTokens,setAllTokens] = useState([])
    async function loadTokenIDSFromCollection(){
        var allTokenids = await getAllTokenIdFromCollection(props.collectionName)
        allTokenids = allTokenids.map(allTokenids => allTokenids.tokenid)
        //allTokenids = allTokenids.slice(0,8)
        setAllTokens(allTokenids)
    }

    useEffect(()=>{
        loadTokenIDSFromCollection()
    },[props.collectionName])

    console.log("allTokens")

    console.log(allTokens)

    return (<NFTSliderAnzeige nfts={allTokens}/>)

}


// Anzeige Componente
const NFTSliderAnzeige = ({nfts}) => {
  return (
    <div
      style={{
        overflowX: 'auto',
        overflowY: 'hidden', // Vertikales Scrollen deaktivieren
        whiteSpace: 'nowrap',
        touchAction: 'pan-x', // Auf mobilen Geräten nur horizontale Gesten zulassen
        display:"flex"
      }}
    >
      {nfts.map((tokenId, index) => (
        <div
          key={index}
          style={{
            flex: '0 0 auto',
            display: 'inline-block',
            width: '155px',

            marginRight: '10px'
          }}
        >
          <LazyNFT tokenId={tokenId} />
        </div>
      ))}
    </div>
  );
};

export default NFTSlider;
