import React, { useState, useEffect,useContext } from 'react';
import classes from './Newsfeed.module.css';
import { getOptions } from '../../../node/databank';
import { fetchi } from '../../../globalData';
import { getAllFriendsPromise } from '../../../web3/GetAllFriends';
import { getNameFromAddress } from '../../../node/betterFunctions';
import { shortAddr } from '../../../web3/LoadingFunctions';
import NFTFormatEasyOnePage from '../../../components/NFT/NFTFormatEasyOnePage'; // Pfad ggf. anpassen
import { themes } from '../../../ColorTheme';
import { NightContext } from '../../../NightModeProvider';
import MyNFTsWidget from './NFTWidget_ModalChoose'; // Passe den Pfad ggf. an
import { useClickOutside } from '@mantine/hooks';

function AddressToName(props) {
  const [address, setAddress] = useState();
  useEffect(() => {
    getNameFromAddress(props.creatorAddr).then(res =>
      setAddress(res + " (" + shortAddr(props.creatorAddr) + ")")
    );
  }, [props.creatorAddr]);
  return (
    <div style={{ marginRight: "10px", fontWeight: "bold" }}>
      {address}
    </div>
  );
}

async function getNewsFromMyFriendsDB() {
  const allFriends = await getAllFriendsPromise();
  var friendsList = allFriends.map(friend => friend.friend_addr);
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const myAddress = userdata.address;
  friendsList.push(myAddress);
  const friendListString = friendsList.map(friend => `'${friend}'`).join(', ');
  console.log(friendListString);
  var response = await fetch(fetchi + "/databank", getOptions("getNewsFromMyFriends", { friendsList: friendListString })).catch(console.log);
  response = await response.json();
  if (response === "error") {
    response = [];
  } else {
    response = response[0];
  }
  console.log(response);
  return response;
}

async function postNewsDB(news) {
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const myAddress = userdata.address;
  const response = await fetch(fetchi + "/databank", getOptions("postNews", { news: news, creator: myAddress.toLowerCase() })).catch(console.log);
  console.log(response);
  return response;
}

async function deleteNewsDB(id) {
  const response = await fetch(fetchi + "/databank", getOptions("deleteNews", { id: id })).catch(console.log);
  console.log(response);
  return response;
}

const NewsFeed = () => {
      // Night Mode
      const nightMode = useContext(NightContext)
      const [theme,setTheme] =useState(themes.bright)
      useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
  const [posts, setPosts] = useState([]);
  const [newPostText, setNewPostText] = useState('');
  const [showNFTWidget, setShowNFTWidget] = useState(false);
  const [selectedNFTTokenId, setSelectedNFTTokenId] = useState(null);

  //Click Outside Ref
  const closeRef = useClickOutside(() => setShowNFTWidget(false));


  // Laden der Posts
  const loadNews = async () => {
    const fetched = await getNewsFromMyFriendsDB();
    console.log(fetched);
    console.log("fetched");

    setPosts(fetched);
    
  };

  window.addEventListener("load", function() {
    if (window.innerWidth <= 900) { // Falls die Bildschirmbreite ≤ 768px (Handy)
        const scrollElement = document.getElementById("container");
        if (scrollElement) {
            scrollElement.style.overflowY = "scroll"; // Scrollbar aktivieren
            scrollElement.style.maxHeight = "100vh"; // Optional: Maximale Höhe setzen
        }
    }
});


  console.log("posts")
  console.log(posts)

  useEffect(() => {
    loadNews();
  }, []);

  // Posten eines Textbeitrags
  const handlePostSubmit = () => {
    if (newPostText.trim() === '') return;
    const newPost = {
      id: Date.now(),
      news: newPostText,
      creator: JSON.parse(sessionStorage.getItem("userdata")).address,
      datum: new Date()
    };
    setPosts([newPost, ...posts]);
    setNewPostText('');
    postNewsDB(newPostText);
  };

  // Posten eines NFT-Posts
  const handleNFTPost = (tokenId) => {
    // Hier wird als Inhalt der Post nicht mehr ein reiner Text, sondern ein Kennzeichen für einen NFT-Post gesetzt.
    // Du könntest z. B. ein spezielles Objekt-Format nutzen, um zwischen Text- und NFT-Posts zu unterscheiden.
    const newPost = {
      id: Date.now(),
      // Im Feld "news" speichern wir die tokenId und kennzeichnen den Post als NFT-Post
      news: `{"tokenId":"${tokenId}"}`,
      creator: JSON.parse(sessionStorage.getItem("userdata")).address,
      datum: new Date(),
      isNFT: true
    };
    setPosts([newPost, ...posts]);
    // Falls erforderlich, kann hier auch ein eigener Endpunkt für NFT-Posts verwendet werden:
    postNewsDB(newPost.news);
  };

  console.log(posts)

  const handleDelete = (id, userId) => {
    if (userId !== JSON.parse(sessionStorage.getItem("userdata")).address) return;
    setPosts(posts.filter(post => post.id !== id));
    deleteNewsDB(id);
  };

  // Inline Styling (wie gehabt)
  const containerStyle = {
    zIndex: "2",
    opacity: "0.97",
    marginBottom: '0px',
    backgroundColor: theme.boxColor,
    borderRadius: '8px',
    boxShadow: theme.boxShadow,
    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    maxHeight: "1110px",
    overflowY:"scroll"
  };

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.color1,
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '0.75rem 1rem',
    marginBottom: '1.5rem',
    border: theme.border

  };

  const textareaStyle = {
    width: '100%',
    border: 'none',
    fontSize: '16px',
    outline: 'none',
    resize: 'none',
    overflow: 'hidden',
    backgroundColor: theme.color1,
    color: theme.font

  };




  const nftButtonStyle = {
    alignSelf: 'flex-end',
    backgroundColor: '#28a745',
    color: '#fff',
    border: 'none',
    padding: '0.5rem 1rem',
    marginTop: '0.5rem',
    borderRadius: '6px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  };

  const postsContainerStyle = {
    display: 'flex',
    flexDirection: 'column'
  };

  const postCardStyle = {
    backgroundColor: theme.color1,
    padding: '1rem',
    borderRadius: '8px',
    marginBottom: '1rem',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    display: 'flex',
    flexDirection: 'column'
  };

  const postHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  };

  const postTextStyle = {
    fontSize: '16px',
    color: theme.font
  };
  const NFTFormatWrapper = {
    width: '140px',
  };

  

  const dateTimeStyle = {
    fontSize: '12px',
    color: theme.font,
    display: 'flex'
  };

  const deleteButtonStyle = {
    backgroundColor: theme.color2,
    border: 'none',
    padding: '0.5rem 0.75rem',
    borderRadius: '6px',
    fontSize: '14px',
    color: theme.font,
    cursor: 'pointer',
    transition: 'background-color 0.2s'
  };

  return (
    <div style={containerStyle} className={classes.container} id="container">
      {/* Inputbereich für normale Text-Posts */}
      <div style={inputContainerStyle}>
        <textarea
          placeholder="What's on your mind?"
          className="search-input"
          value={newPostText}
          onChange={e => setNewPostText(e.target.value)}
          style={textareaStyle}
          rows={3}
          onInput={(e) => {
            e.target.style.height = 'auto';
            e.target.style.height = `${e.target.scrollHeight}px`;
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handlePostSubmit();
            }
          }}
        />
              <style>
      {`
        .search-input::placeholder {
          color: ${theme.font}; /* Hier die gewünschte Farbe */
          opacity: 1;
        }
      `}
    </style>
        <div style={{ display: 'flex', justifyContent: 'right', gap: '0.5rem' }}>
          <button
            onClick={handlePostSubmit}
            style={deleteButtonStyle}
            onMouseOver={e => (e.currentTarget.style.backgroundColor = theme.color1)}
            onMouseOut={e => (e.currentTarget.style.backgroundColor = theme.color2)}
          >
            Post
          </button>
          <button
            onClick={() => setShowNFTWidget(true)}
            style={deleteButtonStyle}
            onMouseOver={e => (e.currentTarget.style.backgroundColor = theme.color1)}
            onMouseOut={e => (e.currentTarget.style.backgroundColor = theme.color2)}
          >
            Post NFT
          </button>
        </div>
      </div>

      {/* Falls ein NFT ausgewählt wurde, kann im Post auch das NFT-Widget gerendert werden */}
      <div style={postsContainerStyle}>
        {posts.map(post => (
          <div key={post.id} style={postCardStyle}>
            <div style={postHeaderStyle}>
              <span style={dateTimeStyle}>
                <AddressToName creatorAddr={post.creator} />
                {
                new Date(post.datum).toLocaleString('en-US', {
                  day: '2-digit',
                  month: '2-digit',
                  year: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true
                }).toLowerCase()
                }
              </span>
              {post.creator === JSON.parse(sessionStorage.getItem("userdata")).address && (
                <button
                  onClick={() => handleDelete(post.id, post.creator)}
                  style={deleteButtonStyle}
                  onMouseOver={e => (e.currentTarget.style.backgroundColor = theme.color1)}
                  onMouseOut={e => (e.currentTarget.style.backgroundColor = theme.color2)}
                >
                  Delete
                </button>
              )}
            </div>
            <span style={postTextStyle}>
              { post.news.includes(`{"tokenId":`)
              
                ? <div style={NFTFormatWrapper}><NFTFormatEasyOnePage tokenId={JSON.parse(post.news).tokenId} />  </div>
                : post.news }
            </span>
          </div>
        ))}
      </div>

      {/* Modal/Overlay für das NFTWidget */}
      {showNFTWidget && (
        <div  style={{ 
          position: 'fixed',
          top: 0, left: 0, right: 0, bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <div ref={closeRef}  style={{zIndex: 1001,width:"300px", background: theme.color1, padding: '1rem', borderRadius: '8px', maxHeight: '90%', overflowY: 'auto' }}>
            <div style={{display:"flex", width: '100%',justifyContent:"space-between"}}>
            <h3 style={{color: theme.font}}>Choose NFT</h3>
            <button
              onClick={() => setShowNFTWidget(false)}
              style={deleteButtonStyle}
              onMouseOver={e => (e.currentTarget.style.backgroundColor = theme.color1)}
              onMouseOut={e => (e.currentTarget.style.backgroundColor = theme.color2)}
            >
              Cancel
            </button>
            </div>
            {/* Hier wird das NFTWidget eingebunden */}
            <MyNFTsWidget disableClick={true}
              onSelect={(tokenId) => {
                // Sobald der Nutzer ein NFT auswählt, wird tokenId zurückgegeben
                setSelectedNFTTokenId(tokenId);
                handleNFTPost(tokenId);
                setShowNFTWidget(false);
              }}
            />

          </div>
        </div>
      )}
    </div>
  );
};

export default NewsFeed;
