import classes from './AddFriendIntegration.module.css';

import React,{useState,useContext} from 'react';

import {UserContract,userContractAddress} from '../../web3/UserContract'
//img
import validImage from '../../images/valid.png';
import infoButton from '../../images/infoButton.png';

import inValidImage from '../../images/invalid.png';
import plusImg from '../../images/plus.png';
import {follow} from '../../node/followFunction';
import {updateFriendsfura} from '../../web3/SendEtherInfura'

import {ChangeSnackbarFunction} from '../../SnackbarProvider'

//material UI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';


import {_web3} from '../../web3/Web3'
var web3 = _web3.mcbWallet
const userdata = JSON.parse(sessionStorage.getItem("userdata"))
if(userdata !== null){
    userdata.metamask === true ? web3 = _web3.metamask : web3 = _web3.mcbWallet
}


//props.allFriends: [{friend_name:string,friend_addr:string,blockchain:bool}]
function AddFriendIntegration(props){

      //Snackbar
      const changeSnackbar = useContext(ChangeSnackbarFunction)


    const [addrValid,setAddressValid] = useState({set:false,valid:false}); 
    const [alignment,setAlignment] = React.useState('center');

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
          setAlignment(newAlignment);
        }
      };
    
    function isValidAddress(){
        return web3.utils.isAddress(document.getElementById("addressInput").value);
    }

    function addressChanged(){ //triggert bei onChange friendAddress

        // Check if Address is set
        if(document.getElementById("addressInput").value.length===0){
            setAddressValid(prevState => ({...prevState, set:false}))
        }else{
            setAddressValid(prevState => ({...prevState, set:true}))
        }

        // Check if Address is valid
        if(isValidAddress()){
            setAddressValid(prevState => ({...prevState, valid:true}))
        }
        else{
            setAddressValid(prevState => ({...prevState, valid:false}))
        }  
    }


    function checkIfFriendIsInList(addressToCheck,blockchainFilter){ //blockchainFilter: true. Dann zeigt es nur Blockchainfreunde
        

        return props.allFriends.some(friend => friend.friend_addr === addressToCheck.toLowerCase() && friend.blockchain === blockchainFilter )
    }

    async function addFriend(){
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        const addr= document.getElementById("addressInput").value;


        if(userdata.address.toLowerCase() === addr.toLowerCase() ){
            changeSnackbar("This is your own address!")
            return
        }
        // if address unvalid return
        if(!isValidAddress()){
            return
        }

        if(alignment === "left"){ // BlockchainFriend
            if(checkIfFriendIsInList(addr,true)){
                changeSnackbar("Blockchain-Friend already added!")
                return
            }
        }else{
            if(checkIfFriendIsInList(addr,false)){
                changeSnackbar("Follow-Friend already added!")
                return
            }
        }
        //BlockchainFriend
        if(alignment ==="left"){
            const name= document.getElementById("inputName").value;
            if(name!=="" && addr!==""){

                
                if(userdata.metamask === true){// Metamask
                    UserContract.methods.updateFriends(name,addr).send({
                        from: userdata.address,
                        to: userContractAddress
                    })
                    .on('transactionHash', function(hash){
                        console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
                        if(props.onClose_AfterAddedFriend){
                            props.onClose_AfterAddedFriend("Metamask transaction can take uo to 1 min.")
                        }
                    })
                    .then(receipt => {
                        console.log("Transaktion erfolgreich!", receipt);
                        if(props.onClose_AfterAddedFriend){
                            props.onClose_AfterAddedFriend("Transaction was successfull!")
                        }


                    })
                    .catch(error => {
                        console.log("Transaktion abgelehnt oder ein Fehler ist aufgetreten", error);
                        if(props.onClose_AfterAddedFriend){
                        props.onClose_AfterAddedFriend("Transaction denied or an error occured.")
                        }

                    })

                    
                
                }else{ //MCB Wallet
                    const tx = await updateFriendsfura(userdata.privatekey,userdata.address,name,addr)
                    props.openApproveWallet(tx)

                    if(props.onClose_AfterAddedFriend){
                        props.onClose_AfterAddedFriend("MCB_Wallet")
                    }
                }

            }
        }else{ // FollowFried
            //follow(addr.toLowerCase())

            follow(addr.toLowerCase()).then(result => {
                var status=""
                if (result) {
                    console.log("Follow Response:", result.followResult);
                    console.log("Notification Response:", result.notificationResult);

                    status="Friend added!"
                } else {
                    console.log("An error occurred, while follow friend.");

                    status="There was an error. Please try later again."
                }
                if(props.onClose_AfterAddedFriend){
                    props.onClose_AfterAddedFriend(status)
                }
                
                
            });
            



        }
    }


    


    return (
            <div className={classes.integration}>

                {/** Info Button */}
                <IconButton onClick={()=>{window.open("https://mycryptobook.gitbook.io/untitled/friends","_blank")}}  sx={{marginTop:"-12px",marginBottom:"-20px"}}> <img style={{height:'17px',width:'auto'}} src={infoButton}></img> </IconButton>

                <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment" sx={{width:'100%'}}>
                    {!props.justFollowFriend &&
                    <ToggleButton value="left" aria-label="left aligned" sx={{width:'100%'}}>
                    Blockchain Friend
                    </ToggleButton>
                    }
                    <ToggleButton value="center" aria-label="right aligned" sx={{width:'100%'}}>
                    Follow Friend
                    </ToggleButton>

                </ToggleButtonGroup>



                    {/*  Friend Name*/}
                    {alignment==="left" &&
                    <input id="inputName" type="text" placeholder="friend name" className={classes.input}></input>
                    }

                    {/*  Friend Address*/}
                   <input onChange={addressChanged} id="addressInput" type="text" placeholder="friend address" className={classes.input}></input>

                    {/** Valid und Invalid Address */}
                    { addrValid.valid && addrValid.set && 
                        <div className={classes.validAddr} style={{color:"green",}}> 
                            <img className={classes.validPicture}  style={{height:"16px"}} src={validImage}></img>
                            Valid Address
                        </div> 
                    }
                    { !addrValid.valid && addrValid.set && 
                        <div className={classes.validAddr} style={{color:"red",}}> 
                            <img className={classes.invalidPicture}  style={{height:"16px"}} src={inValidImage}></img>
                            Unvalid Address
                        </div> 
                    }

                {/* Add Friend Button*/}
                <Tooltip title="add" placement="top" disableInteractive arrow>
                    <Button sx={{width:'100%'}}variant="outlined" onClick={addFriend} ><img src={plusImg} style={{height:'20px',width:'auto'}}></img></Button>
                </Tooltip>

            </div>


    );


}

export default AddFriendIntegration;