import * as React from 'react';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

const StyledBadge = styled(Badge)((props) => ({
  '& .MuiBadge-badge': {
    backgroundColor: props.online? '#44b700' : "red",

    color: props.online? '#44b700' : "",

    boxShadow: props.online? `0 0 0 2px transparent ` : "",
    '&::after': {
      position: 'absolute',
      top: "-1px",
      left: "-1px",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: props.online? 'ripple 2s infinite ease-in-out' : "",
      border: props.online? '1px solid currentColor' : "none",
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1.8)',
      opacity: 0,
    },
  },
}));



export default function BadgeAvatars(props) {

    console.log("BadgeAvatars")

    console.log(props)
  return (
      <StyledBadge 
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
        online = {props.online}
      >
        <Avatar sx={props.sx} src={props.src} />
      </StyledBadge>


     
  );
}
