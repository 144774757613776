import classes from './Overview.module.css';
import {useLocation,useHistory,useParams} from 'react-router-dom';
import {useState,useEffect,useContext} from 'react';

//Fetchi
import {fetchi} from '../../globalData'
//wallet approve
import Wallet from '../../components/wallet/Wallet'
import ApprovalView from '../../components/wallet/ApprovalView';
//Snackbar
import {ChangeSnackbarFunction} from '../../SnackbarProvider'
import {UserContext} from '../../UserProvider'

//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

import Newsfeed from './Overview_comps/Newsfeed'
import FriendList from './Overview_comps/FriendWidget'
import NewsLearnWidget from './Overview_comps/LearnWidget'
import { BorderBottom } from 'tabler-icons-react';
import MyNFTsWidget from './Overview_comps/NFTWidget_ModalChoose'
import MyNFTsWidgetAlt from './Overview_comps/NFTWidgetAlt'

import MCBPic from '../../images/MCB.png'

import MCBPic2 from '../../images/MCB2.png'




import NFTFormatEasyOnePage from '../../components/NFT/NFTFormatEasyOnePage'


import Logo from '../../components/homePages/Logo'

const userdata = JSON.parse(sessionStorage.getItem("userdata"))

function Overview(){        
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
    //Snackbar
    const changeSnackbar = useContext(ChangeSnackbarFunction)
    const userContext = useContext(UserContext)
    const history = useHistory();

    const [userAddress,setUserAddress] =useState()


    useEffect(() => {
        setUserAddress(JSON.parse(sessionStorage.getItem("userdata")).address)
        window.scrollTo(0,0)
    },[])

    console.log("userCont")
    console.log(userContext)

    

    return (

   
        <div 
        style={{
            backgroundColor: theme.color1,

          }} 
        
        className={classes.container}>

            <div style={{zIndex:1000}} className={`${classes.spalte} ${classes.spalteBreit}`}>
                < Newsfeed />
            </div>
            <div  className={`${classes.spalte} ${classes.spalteEng}`}>
                < Widget child={<NewsLearnWidget/> }/>
                < Widget  child={<FriendList />} /> 
                < Widget bottom child={<MyNFTsWidgetAlt />} /> 
            </div>

            </div>




    );

}


function Widget({child,bottom}){        
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
    //Snackbar
    const changeSnackbar = useContext(ChangeSnackbarFunction)
    const userContext = useContext(UserContext)


    

    return (

        <div style={{ marginBottom: bottom ? "20px":"0px",  backgroundColor:theme.boxColor,boxShadow:theme.boxShadow}} className={classes.widget}>
        
            {child}


        </div>

    );

}


export default Overview;