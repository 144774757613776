import classes from './FriendElement.module.css';
import { useHistory } from "react-router-dom";
import deleteImg from '../../images/delete.png';
import sendImg from '../../images/send.png';
import profilePic from '../../images/profilepic.png';
import saveFriend from '../../images/saveFriend2.png';
import { UserContract } from '../../web3/UserContract';
import {useState,useEffect,useContext} from 'react'
import {unfollow} from '../../node/followFunction';
//popup 
import PopupFenster from '../PopupFenster/PopupFenster'
import SendIntergation from '../PopupFenster/SendIntergation'
//Wallet 
import Wallet from '../wallet/Wallet'
import SendView from '../wallet/SendView'
import {deleteFriendfura} from '../../web3/SendEtherInfura'
//material UI 
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ButtonGroup from '@mui/material/ButtonGroup';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
// Hooks
import useProfilePic from '../../customHooks/useProfilePicture';
import useNightMode from '../../customHooks/useNightMode'
import useWeb3 from '../../customHooks/useWeb3'

function FriendElement(props){

    const theme = useNightMode()
    const web3 = useWeb3()
    const history = useHistory();
    const [sendButton,setSendButton] = useState(false);
    const [copyClicked,setCopyClicked] = useState(false);
    const profilePicURL= useProfilePic(props.longAddr)


    function openFriendProfile(){
        // nicht mehr notwenig localStorage da nun der name gepushd wird
        localStorage.setItem("friendName",props.friendName);
        localStorage.setItem("friendAddr",props.longAddr);

        history.push({
            pathname:"/profile/"+props.longAddr,
            data: {
                friendName: props.friendName,
                friendAddr: props.longAddr
            }
        });

    }

    // check if blockchain or databas
    async function deleteFriend(){
        // Blockchain
        if(props.saveFriend ){


            const userdata = JSON.parse(sessionStorage.getItem("userdata"))
            if(userdata.metamask === true){// Metamask

                UserContract.methods.deleteFriend(props.longAddr).send({from: window.web3.currentProvider.selectedAddress})
                .on('transactionHash', function(hash){
                    console.log("Transaktion wurde in MetaMask bestätigt, Hash:", hash);
                    // Hier kannst du sofort eine Aktion ausführen, nachdem der Benutzer in MetaMask auf "Bestätigen" geklickt hat.
                    // Zum Beispiel: Einen Spinner anzeigen, um zu zeigen, dass die Transaktion verarbeitet wird.
                    //Snackbar
                    setState({ ...state, open: true,message: "Transaction can take up to 1 min." }) 
                })
                .then(receipt => {
                    console.log("Transaktion erfolgreich!", receipt);
                    // Hier kannst du mit dem "receipt" arbeiten, das alle Details der erfolgreichen Transaktion enthält.
                    // Zum Beispiel: Ein Update in der UI oder eine Bestätigung dem Nutzer anzeigen.

                    props.loadFriends() // Damit der gelöschte eintrag Verschwindet.
                    //Snackbar
                    setState({ ...state, open: true,message: "Transaction was successfull!" })  
                })
                .catch(error => {
                    console.log("Transaktion abgelehnt oder ein Fehler ist aufgetreten", error);
                    // Hier kannst du mit dem Fehler arbeiten oder eine Benachrichtigung anzeigen.
                    //Snackbar
                    setState({ ...state, open: true,message: "Transaction denied or an error occured." })  
                })

            }else{ //MCB WALLET
                const tx = await deleteFriendfura(userdata.privatekey,userdata.address,props.longAddr)
                props.openApproveWallet(tx)
            }
            


        // Follow Friend
        }else{
            //unfollowUser(props.longAddr);

            unfollow(props.longAddr.toLowerCase()).then(result => {
                if (result) {
                    console.log("Unfollow Response:", result.unfollowResult);
                } else {
                    console.log("An error occurred, while unfollow friend.");
                }

                // Lade Freundes liste neu nach der Löschung
                props.loadFriends()

            });
        }
    }

    function copyAddress(){
        navigator.clipboard.writeText(props.longAddr);
        setCopyClicked(true)
    }

    function closeTooltip(){
        setTimeout(() => {setCopyClicked(false)},200)
    }

    // Value is HardCoded right now
    function openSend(){
        setSendButton(true)
    }
    function closeSend(){
        setSendButton(false)
    }
    function closeWalletFunc(){
        setSendButton(false)
    }


    // Snackbar
    const [state, setState] = useState({ open: false, Transition: Slide, });
    const handleClose = () => { setState({ ...state, open: false }); };
    const handleClick = (Transition) => () => { setState({ open: true, Transition, })}








    return (

        <div>                
            {sendButton &&  <Wallet closeWalletFunc={closeWalletFunc}
                            integration = {<SendView noBackButton longAddr={props.longAddr} addr={props.addr} friendName={props.friendName} closeWalletFunc={closeWalletFunc} text={`Send Ether to ${props.friendName}`}/>}
                            >

                            </Wallet> }
            <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.element}>

                <Snackbar open={state.open} onClose={handleClose} TransitionComponent={state.Transition} message={state.message} key={state.Transition.name} />


                { false  && <PopupFenster integration={<SendIntergation longAddr={props.longAddr} addr={props.addr} friendName={props.friendName}/>} text={`Send Ether to ${props.friendName}`} onCloseClicked={closeSend} />   }



                <div className={classes.nameWrapper}>
                    <img src={profilePicURL} className={classes.profilePicture}></img>
                    <div style={{color:theme.font}}id="friendName" className={classes.name}> {props.friendName}  </div>
                    {props.saveFriend &&

                        <Tooltip title="on chain" disableInteractive arrow placement="top">
                            <Button> < img src={saveFriend} style={{height: '20px',width: 'auto'}}></img>   </Button>
                        </Tooltip>

                    }
                </div>

                <div className={classes.wrapper}>

                    {/* Crypto Address */}
                    <Tooltip className={classes.cryptoWrapper} onClose={closeTooltip} title={copyClicked ? "Copied!" : "Copy"} placement="left" arrow>
                        <Button onClick={copyAddress} sx={{marginRight:'10px'}} >{props.addr}</Button>
                    </Tooltip>

                    <ButtonGroup variant="text" >
                        <Tooltip title="profile" disableInteractive arrow placement="top">
                            <Button onClick={openFriendProfile}>    < img src={profilePic} style={{height: '20px',width: 'auto',filter:theme.png}}></img>   </Button>
                        </Tooltip>
                        <Tooltip title="send" disableInteractive arrow placement="top">
                            <Button onClick={openSend}>    < img src={sendImg} style={{height: '20px',width: 'auto',filter:theme.png}}></img>   </Button>
                        </Tooltip>
                        <Tooltip title="delete" disableInteractive arrow placement="top">
                            <Button  onClick={deleteFriend}>    < img src={deleteImg} style={{height: '20px',width: 'auto',filter:theme.png}}></img>   </Button>
                        </Tooltip>
                    </ButtonGroup>



                </div>
            </div>
        </div>
        );

}

export default FriendElement;