import Page1 from '../components/start/startPageComponents/Page1'
import HomePage from '../components/homePages/HomePage'


function StartPage(){

    return (
        <div>
            <Page1 />
            <HomePage />
        </div>
    );
}

export default StartPage;