import * as React from 'react';

import {useEffect,useRef,useState,useContext} from 'react'
import classes from './NotificationIntegration.module.css'
import {loadMyNotificationsFromDB,deleteNotificationDB} from '../../../node/NotificationManagement'

import NotificationElement from './NotificationElement'

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'
// Snackbar-Context
import {ChangeSnackbarFunction} from '../../../SnackbarProvider'


//get deleteNotification(id) from NotificationElement
export default function NotificationIntegration(props) {

          // Night Mode
          const nightMode = useContext(NightContext)
          const [theme,setTheme] =useState(themes.bright)
          useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
          //Snackbar
          const changeSnackbar = useContext(ChangeSnackbarFunction)

          const [notifications,setNotifications] = useState([])
          async function loadNotifications(){

            //[ {notification,von,zu,nft,id} ]
            const notificationsFromDB = await loadMyNotificationsFromDB()
            // reverse that new notis are on top
            setNotifications(notificationsFromDB.reverse())
            
          }

          useEffect(()=>{
            loadNotifications()
          },[])



          function deleteIdFromArray(notifications, idToDelete) {
            return notifications.filter(notification => notification.id !== idToDelete);
          }
          async function deleteNotification(id){
            setNotifications(prev => deleteIdFromArray(prev, id)); // nicht auf response warten wegen verzögernung

            const response = await deleteNotificationDB(id) //delete Database
            if(response.success){
              //setNotifications(prev => deleteIdFromArray(prev, id));
              //changeSnackbar("Success")
            }
            if(!response.success){
              changeSnackbar("Failed to delete. Try later again.")
            }
          }


  return (

    <div className={classes.container}>

      <div style={{borderBottom:theme.border}} className={classes.header}>

        <div style={{color: theme.font,fontSize:'22px'}} >Notifications</div>

      </div>

      <div className={classes.list}>

        {notifications.map((ele)=>{return <NotificationElement key={ele.id} deleteNotification={deleteNotification} close={props.close} notification={ele}/>})}

      </div>

    </div>

  );
}
