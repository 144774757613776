import * as React from 'react';

import {useEffect,useRef,useState,useContext} from 'react'
import classes from './ApprovalView.module.css'

import {shortAddr} from '../../web3/LoadingFunctions'


//material UI
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {loginDB,PWdecrypt} from '../../node/username'

import IntegrationWrapper from './IntegrationWrapper';

//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'
//Snackbar
import {ChangeSnackbarFunction} from '../../SnackbarProvider'

//User Context
import {UserContext} from '../../UserProvider'
import useNightMode from '../../customHooks/useNightMode';
import useWeb3 from '../../customHooks/useWeb3';



import AmountView from './AmountView'



const ethPrice = require('eth-price');


function toHexString(byteArray) {
    return Array.from(byteArray, function(byte) {
      return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
}
function readInt(array) {
    var value = 0;
    for (var i = 0; i < array.length; i++) {
        value = (value * 256) + array[i];
    }
    return value;
}

// Just needs props.type and props.tx to sign tx and geht txInfos
// close: props.closeWalletFunc()

// props.transactionSuccess() wenn die Transaktion erfolgreich war
// props.transactionHash() bei start der Transaktion
// props.transactionError() bei Fehler
function ApprovalView(props) {

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    const web3 = useWeb3()
    const theme = useNightMode()
    const changeSnackbar = useContext(ChangeSnackbarFunction)

    const [openAmountView,setopenAmountView] =useState(false)
    const [password,setPassword] = useState("")
    const [loginError,setLoginError] = useState("")   
    const [loadHistoryNew,setloadHistoryNew] =useState(false)


    // check PW and decrypt Private Key
    async function decryptPrivateKey(){
        // faster version to check PW: hash(PW) is in userdata.pw
        const res = await loginDB(userdata.name,password) // not exist: return "error" else: {name:'',pw:'',publickey,privatekey,address}

        console.log(res)
        if( res === "error"){
            setLoginError("unvalid password")
            return false
        }
        return true
    }


    async function approve(){

        // checks if PW correct
        if(! await decryptPrivateKey()){ 
            return
        }
        // decrypto Private Key and sign Tx with it
        const decryptedPrivateKey = PWdecrypt(userdata.privatekey,password)

        console.log(props.tx)

        console.log(decryptedPrivateKey)

    
        signTx(props.tx,decryptedPrivateKey)
    

        setopenAmountView(true) // open Amount View and Closes Approve View
        

    }

    async function cancel(){
        //props.closeWalletFunc()
        setopenAmountView(true)

        changeSnackbar("Transaction canceled:" + props.type)

    }

    async function signTx2(tx,privateKey){
        privateKey = privateKey.slice(2,privateKey.length) // remove 0x
        privateKey = Buffer.from(privateKey,'hex')
        tx.sign(privateKey)
        const serializedTransaction = tx.serialize()
        const raw = '0x' + serializedTransaction.toString('hex')

        console.log("Sende Transaktion...");
        const erg = web3.eth.sendSignedTransaction(raw )
        
        .once('receipt', function(receipt){
            console.log("Receipt: ", receipt);
            changeSnackbar("MCB Transaction was successfull: " + props.type)
            setloadHistoryNew(true)

            if(props.transactionSuccess){

                //Für NFT Creation
                const tokenId = web3.utils.hexToNumberString(receipt.logs[0].topics[3]); // Token-ID abrufen
                const txHash = receipt.transactionHash
                props.transactionSuccess({tokenId:tokenId,txHash:txHash})
            }
            //
            //window.location.reload()

            props.transactFinishAndReload()
            
        })
        .on('transactionHash', function(hash){
        
            changeSnackbar("MCB Transaction can take up to 1 min.")

            console.log("Transaction Hash:", hash);
            //props.transactionHash()
        })
        .on('error', function(error){
            
            changeSnackbar(`MCB Transaction error: ${error}` )

            console.error("An error occurred:", error);
            //props.transactionError()
        })
        return await erg
    }

    async function signTx(tx, privateKey) {
        let isComponentMounted = true; // Variable zum Überprüfen, ob die Komponente noch existiert
    
        try {
            privateKey = privateKey.slice(2); // Entferne "0x"
            privateKey = Buffer.from(privateKey, 'hex');
            tx.sign(privateKey);
            const serializedTransaction = tx.serialize();
            const raw = '0x' + serializedTransaction.toString('hex');
    
            console.log("Sende Transaktion...");
    
            const transactionPromise = new Promise((resolve, reject) => {
                const txInstance = web3.eth.sendSignedTransaction(raw);
    
                txInstance
                    .once('transactionHash', (hash) => {
                        if (isComponentMounted) {
                            changeSnackbar("MCB Transaction can take up to 1 min.");
                            console.log("Transaction Hash:", hash);
                        }
                    })
                    .once('receipt', (receipt) => {
                        if (isComponentMounted) {
                            console.log("Receipt erhalten:", receipt);
                            changeSnackbar("MCB Transaction was successful: " + props.type);
                            setloadHistoryNew(true);
    
                            if (props.transactionSuccess) {
                                const tokenId = web3.utils.hexToNumberString(receipt.logs[0].topics[3]); // Token-ID abrufen
                                const txHash = receipt.transactionHash;
                                props.transactionSuccess({ tokenId, txHash });
                            }
    
                            props.transactFinishAndReload();
                        }
                        resolve(receipt);
                    })
                    .on('error', (error) => {
                        if (isComponentMounted) {
                            changeSnackbar(`MCB Transaction error: ${error}`);
                            console.error("Transaktionsfehler:", error);
                        }
                        reject(error);
                    });
            });
    
            return await transactionPromise;
        } catch (error) {
            console.error("Signing Error:", error);
            throw error;
        } finally {
            isComponentMounted = false; // Cleanup, falls die Komponente unmounted wird
        }
    }
    

    // load all Tx Data
    const [txData,setTxData] = useState({})
    useEffect(()=>{
        const tx = props.tx
        console.table(tx._fields)
        console.table(tx.raw)

        if(true){
            const to = shortAddr(  "0x" + toHexString(tx.raw[3])  )
            const value = web3.utils.fromWei(readInt(tx.raw[4]).toString(),"ether")
            const gasLimit =  parseInt(  toHexString(tx.raw[2])  ,16).toString()  
            var gasPrice = web3.utils.fromWei(  parseInt(  toHexString(tx.raw[1])  ,16).toString()  ,"ether") // in Ether for calc
            const fee = gasLimit*gasPrice
            gasPrice = web3.utils.fromWei(  parseInt(  toHexString(tx.raw[1])  ,16).toString()  ,"gwei") // in Gwei

            //const fee ="a"
            // calc Fee in USD
            ethPrice("usd").then(res=>{
                const ethPrice=parseFloat(res.toString().split(":").pop())
                const feeUSD =(ethPrice*parseFloat(fee)).toFixed(2);

                setTxData({to:to,value:value,fee:fee,feeUSD:feeUSD,gasLimit:gasLimit,gasPrice:gasPrice})
            })
        }

    },[])



  return (
    <div style ={{width: "100%"}}> 

        {openAmountView && <AmountView loadHistoryNew={loadHistoryNew}/> }


        {!openAmountView &&
        <div className={classes.integration}>

            {/*totalAmount*/}
            <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.box}>

                <div style={{color:theme.font,borderBottom: theme.border,fontSize:'20px'}} className={classes.action}>
                    {props.type}
                </div>

                <div  className={classes.details}>

                    <div style={{color:theme.font}} className={classes.data} >  {"To: " }      <div> {txData.to} </div>                      </div>  
                    <div style={{color:theme.font}} className={classes.data} >  {"Value: " }   <div> {txData.value + " Ether"} </div>        </div>
                    <div style={{color:theme.font}} className={classes.data} >  {"Max fee: "}  <div> {txData.fee + " Ether"} </div>         </div>
                    <div style={{color:theme.font}} className={classes.data} >  {" - in USD: "}  <div> {txData.feeUSD + " USD"} </div>        </div>
                    <div style={{color:theme.font}} className={classes.data} >  {"gasLimit: "} <div> {txData.gasLimit } </div>   </div>
                    <div style={{color:theme.font}} className={classes.data} >  {"gasPrice: "} <div> {txData.gasPrice + " Gwei"} </div>   </div>


                </div>

                <div style={{borderTop: theme.border}} className={classes.approve}>

                    <div className={classes.pwWrapper}>
                        <div style={{fontSize:'18px'}}>Confirm with your Password</div>
                        <TextField  value={password} onChange={(e)=>{setPassword(e.target.value);setLoginError("")}} helperText={loginError} error={loginError!==""} label="Password" type="password"  sx={{width:'100%'}}  />
                    </div>

                </div>

                <div style={{borderTop: theme.border}} className={classes.approve}>

                    <Button onClick={cancel} sx={{width:'90px'}} variant="outlined"> Cancel</Button>
                    <Button onClick={approve} sx={{width:'90px'}} variant="contained">  Approve</Button>

                </div>

            </div>

        </div>
        }
    </div>
  );
}


export default ApprovalView;