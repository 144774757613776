import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { FaArrowRight, FaBan ,FaExclamationCircle} from 'react-icons/fa';
import { themes } from '../../../ColorTheme';
import { NightContext } from '../../../NightModeProvider';
import NFTFormatEasyOnePage from '../../../components/NFT/NFTFormatEasyOnePage'; // Pfad ggf. anpassen
import { getAllMyTokenIDs_On_Off_chain } from '../../../node/NFTData';

const MyNFTsWidget = () => {
  const history = useHistory();
  const nightMode = useContext(NightContext);
  const [theme, setTheme] = useState(themes.bright);
  const [tokenIds, setTokenIds] = useState([]);
  const [loading, setLoading] = useState(true);


  // Theme aktualisieren
  useEffect(() => {
    nightMode ? setTheme(themes.dark) : setTheme(themes.bright);
  }, [nightMode]);

  // Asynchrones Laden der TokenIds und Auswahl der letzten 6 (reverse = neueste zuerst)
  useEffect(() => {
    const fetchTokenIds = async () => {
      try {
        const myAddress = JSON.parse(sessionStorage.getItem("userdata")).address;
        const tokens = await getAllMyTokenIDs_On_Off_chain(myAddress);
        const lastSixTokens = tokens.length > 6 ? tokens.slice(-6) : tokens;
        setTokenIds(lastSixTokens.reverse());
        setLoading(false)
      } catch (error) {
        console.error("Fehler beim Laden der Token IDs:", error);
        setLoading(false)
      }
    };

    fetchTokenIds();
  }, []);

  // Navigation bei Klick auf den Header
  const navigateToMyNFTPage = () => {
    history.push('/mynft');
  };

  return (
    <div style={{ width: '100%', fontFamily: 'Arial, sans-serif' }}>
      {/* Überschrift und Pfeil als klickbarer Container */}
      <div
        onClick={navigateToMyNFTPage}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          marginBottom: '10px',
        }}
      >
        <h2 style={{ fontSize: '18px', color: theme.font, margin: 10, marginBottom: 0 }}>
          My Latest NFTs
        </h2>
        <FaArrowRight style={{ color: theme.font, margin: 10, marginBottom: 0 }} size={25} />
      </div>
      {/* Container für die NFTs oder das Placeholder-Icon */}
      <div
        style={{
          display: tokenIds.length === 0 ? 'flex' : 'grid',
          gridTemplateColumns: tokenIds.length === 0 ? undefined : 'repeat(3, 1fr)',
          alignItems: tokenIds.length === 0 ? 'center' : undefined,
          justifyContent: tokenIds.length === 0 ? 'center' : undefined,
          gap: tokenIds.length === 0 ? undefined : '1rem',
          padding: '10px',
          height: tokenIds.length === 0 ? '250px' : 'auto',
        }}
      >
        {tokenIds.length === 0 ? (
          loading ? "Loading..." : 
          <FaExclamationCircle size={50} color={theme.font} title="No NFTs available" />
        ) : (
          tokenIds.map((tokenId) => (
            <NFTFormatEasyOnePage key={tokenId} tokenId={tokenId} />
          ))
        )}
      </div>
    </div>
  );
};

export default MyNFTsWidget;
