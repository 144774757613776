import React, { useState ,useEffect,useContext} from 'react';
import { FaLock, FaLockOpen } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'

function LockToggle(props) {
  const [locked, setLocked] = useState();
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  useEffect(()=>{
    setLocked(props.state)
  },[props.state])


  const containerStyle = {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    fontSize:"13px"
  };



  return (
    <div style={containerStyle} >
    <Tooltip  title={locked ? "click to decrypt":"unlocked"} placement="top" disableInteractive arrow>
       <IconButton onClick={locked? props.decryptButtonClicked: null} style={{fontSize: "14px",height:"18px",color:theme.font}}> {locked ? <FaLock />: <FaLockOpen />} </IconButton> 
    </Tooltip>
    
    </div>
  );
}



export default LockToggle;
