import React, { useState, useEffect } from "react";
import useWeb3 from './useWeb3';


export default function useEthBalance(address) {
    const [ethBalance,setEthBalance]= useState();

    const web3 = useWeb3()

    useEffect(()=>{
        if(address === "me"){
            const userdata = JSON.parse(sessionStorage.getItem("userdata"))
            address = userdata.address
        }

    },[address])

    useEffect(() => {
        web3.eth.getBalance(address).then(bal=>{setEthBalance(web3.utils.fromWei(bal,"ether").slice(0,9))})
        setEthBalance()
    },[])

    return ethBalance
}
  