
import * as React from 'react';
import backButton from '../../images/zuruck.png'
import IconButton from '@mui/material/IconButton';

import useNightMode from '../../customHooks/useNightMode';


const backButtonWrapperStyle = {
    display: 'flex',
    alignItems: 'center',

  };


export default function BackButton(props){
    const theme = useNightMode()

    return(

        <div style={backButtonWrapperStyle}>
        <IconButton onClick={props.onClickBack}><img src={backButton} style={{height: '25px',width: 'auto',filter: theme.png}}></img></IconButton>
        </div>
    )
}