import classes from './ListElement.module.css';
import closePic from '../../images/close.png';
import {shortAddr} from '../../web3/LoadingFunctions';
import {useHistory} from 'react-router-dom';
import {useEffect,useState,useContext} from 'react'
import useProfilePic from '../../customHooks/useProfilePicture'

import StandartProfilPic from '../../images/background.jpeg';

import {getNameFromAddress} from '../../node/betterFunctions'
//User Context
import {UserContext} from '../../UserProvider'



// wird von FriendProfilData & NFT Likes verwendet

function ListElement(props){
    const userData = useContext(UserContext)
    const [username,setUsername] =useState("unnamed");
    const [address,setAddress] =useState();

    const profilePic = useProfilePic(props.likesItem.follower || props.likesItem.address)


    useEffect(()=>{
        setAddress(props.likesItem.follower || props.likesItem.address) 
    },[props.likesItem.address,props.likesItem.follower])

    console.log(address)

    useEffect(()=>{
        if(!props.likesItem.name){
            getNameFromAddress(props.likesItem.address).then((res) =>setUsername(res))
        }else{
            setUsername(props.likesItem.name)  
        }
        
    })

   


    const history = useHistory();
    function goToProfile(){
            if(userData.address ===address){

                history.push({
                    pathname:"/me/"
                });
            }else{
                history.push(`/profile/${address}`);
                if(props.closePopup){
                    props.closePopup()
                }
            }
    }





    return (

        <div onClick={goToProfile} className={classes.container}>

            <img src={profilePic} className={classes.profilePicture}></img>

            <div className={classes.container2}>
                <div className={classes.name}>{username}</div>
                <div className={classes.addresse}>{shortAddr(address)}</div>
            </div>


        </div>


    )


}

export default ListElement;