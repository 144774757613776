import * as React from 'react';
import { useRef,useEffect,useContext,useState } from 'react'
import IconButton from '@mui/material/IconButton';
import classes from './MenuButton.module.css'
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

//props.menuIsOpen
export default function MenuButton(props){

       // Night Mode
       const nightMode = useContext(NightContext)
       const [theme,setTheme] =useState(themes.bright)
       useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  const balken1ref = useRef()
  const balken2ref = useRef()
  const balken3ref = useRef()

  useEffect(()=>{
    if(props.menuIsOpen){
      buttonAnimationOpen()
    }else{
      buttonAnimationClose()
    }
  },[props.menuIsOpen])
  



  function buttonAnimationOpen(){
    balken1ref.current.className =  classes.buttonBalken1
    balken2ref.current.className =  classes.buttonBalken2
    balken3ref.current.className =  classes.buttonBalken3
  }
  function buttonAnimationClose(){
    balken1ref.current.className =  classes.buttonBalken1close
    balken2ref.current.className =  classes.buttonBalken2close
    balken3ref.current.className =  classes.buttonBalken3close
  }

  return (
    <div  className={classes.menuButton}>
    <IconButton onClick={props.onClick}>
    <div  className={classes.menuButton}>
      <div style={{backgroundColor:theme.font}} ref={balken1ref}  className={classes.buttonBalken}></div>
      <div style={{backgroundColor:theme.font}} ref={balken2ref}  className={classes.buttonBalken}></div>
      <div style={{backgroundColor:theme.font}} ref={balken3ref}  className={classes.buttonBalken}></div>

    </div>
    </IconButton >
    </div>

  );
}