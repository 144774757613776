import * as React from 'react';

import {useEffect,useRef,useState,useContext} from 'react'
import classes from './AmountView.module.css'

import {shortAddr} from '../../web3/LoadingFunctions'
import etherSign from '../../images/ethereum.png'
import HistoryView from './HistoryView'

//material UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Send } from 'lucide-react';


//ColorTheme - Night Mode
import useNightMode from '../../customHooks/useNightMode';
import useWeb3 from '../../customHooks/useWeb3';
import useEthBalance from '../../customHooks/useEthBalance';

import {useMyTokens} from '../../customHooks/useMyTokens';



//User Context
import {UserContext} from '../../UserProvider'

import { ethers } from "ethers";

import SendViewAuswahl from './SendViewAuswahl'
import SendView from './SendView'



const ethPrice = require('eth-price');

//props.walletOpen:Boolean
//props.closeWalletFunc:Function
function AmountView(props) {

    const userdata = useContext(UserContext)
    const web3 = useWeb3()
    const tokens = useMyTokens(userdata.address) // {amount,tokenName,tokenURL,contractAddress}
    const theme = useNightMode()
    const [ethPrice1,setEthPrice] = useState("")
    const ethBalance = useEthBalance(userdata.address)

    ethPrice("usd").then(res=>{
        const price=parseFloat(res.toString().split(":").pop())
        setEthPrice(price)
    })


    function onSendClick(token){

        props.setCurrentView(prev => ({current:<SendViewAuswahl token={token} onClickBack={onClickBack} onFriendClick={onFriendClick}/>, before: prev.current}))
    }
    function onClickBack(){
        props.setCurrentView(prev => ({current: false, before: prev.current}))
    }

    function onFriendClick(friend,token = null){ // wird aus SendViewAuswahl aufgerufen
  
        props.setCurrentView(prev => ({current:<SendView  token={token} onClickBack={()=>{onSendClick(token)}} longAddr={friend.friend_addr} addr={shortAddr(friend.friend_addr)} friendName={friend.friend_name} text={`Send ${token? token.tokenName :"ether"} to ${friend.friend_name}`} />
        , before: prev.current}))
    }

  return (
        <div className={classes.integration}>


            {/*totalAmount*/}
            <div style={{backgroundColor:theme.color2,border:theme.border}} className={classes.totalAmount}>

                <div style={{marginTop:'10px',opacity:'0.6',color:theme.font}}> Total Amount</div>
                <div style={{fontSize:'24px',marginBottom:'10px',color:theme.font}}>{(ethPrice1* ethBalance).toFixed(2) + " USD"}</div>

                <div style={{width:'100%',borderTop:'1px solid rgb(212, 212, 212)',borderTop:theme.border}}>
                    <Button sx={{width:'100%'}}><div style={{fontSize:'15px'}}>Add Funds</div></Button>
                </div>

            </div>
            {/*asset*/}

            <div className={classes.assetList}>

            
            <AssetElement onSendClick={onSendClick}  name={"ETH"} balance={ethBalance} img={etherSign}/>

            {tokens.map(token => <AssetElement onSendClick={onSendClick} token={token} name={token.tokenName} balance={token.amount.toFixed(5)} img={token.tokenURL}/>)}
            </div>

        </div>
  );
}





function AssetElement(props) {
  const theme = useNightMode();
  const [isHovered, setIsHovered] = useState(false);



  return (
    <div
      className={classes.asset}
      style={{  backgroundColor:theme.color2, border: theme.border, }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ display: 'flex', margin: '10px', gap: '5px', color: theme.font }}>
        <img src={props.img} alt="Asset" style={{ height: '22px', width: 'auto' }} />
        {props.name}
      </div>
      <div style={{ margin: '10px', fontSize: '18px', color: theme.font }}>
        {isHovered ? (

            <SendButtonAsset onSendClick={()=>{props.onSendClick(props.token) } }/>
        ) : (
          props.balance
        )}
      </div>
    </div>
  );
}

function SendButtonAsset(props) {
    const [isHovered, setIsHovered] = useState(false);
    const theme = useNightMode();

  
return (
    <div className={classes.sendButtonAsset} onClick={props.onSendClick}  
    style={{backgroundColor: isHovered ? theme.hoverButton : theme.color2  }}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    >
        <Send size={28} />
    </div>
    );
  }





export default AmountView