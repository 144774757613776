import React, { useState, useEffect, createContext } from 'react';

// Kontext für den Night Mode Zustand
export const NightContext = createContext();
export const ChangeNightFunction = createContext();

// Funktion zum Speichern und Abrufen von Cookies
const getCookie = (name) => {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    return match ? match[2] : null;
};

const setCookie = (name, value, days = 365) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + value + "; path=/" + expires;
};

export function NightModeProvider(props) {
    // Initialwert wird aus dem Cookie geladen, Standard ist `false`
    const [nightMode, setNightMode] = useState(getLocalStorage("nightMode"));

    // Funktion zum Ändern des Night Mode + Speichern im Cookie
    function setNightModeFunc(bool) {
        const newMode = !nightMode
        console.log(bool)
        localStorage.setItem('nightMode', newMode);
        setNightMode(newMode);

    }

    return (
        <NightContext.Provider value={nightMode}>
            <ChangeNightFunction.Provider value={setNightModeFunc}>
                {props.children}
            </ChangeNightFunction.Provider>
        </NightContext.Provider>
    );
}


function getLocalStorage(key) {
    const stored = localStorage.getItem(key);
    if (!stored) {
      return false;
    }
    return JSON.parse(stored);
  }