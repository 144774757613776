import classes from './SendViewAuswahl.module.css';
import sendImg from '../../images/send.png';
import {useState,useContext,useEffect} from 'react';
import equalImg from'../../images/equal.png';
import {sendEtherInfura} from '../../web3/SendEtherInfura'




//Custom Hooks
import useWeb3 from '../../customHooks/useWeb3';
import useNightMode from '../../customHooks/useNightMode';
import {ChangeSnackbarFunction} from '../../SnackbarProvider'

import useAllMyFriends from '../../customHooks/useAllMyFriends';

import FriendList from '../../pages/home/Overview_comps/FreundesListe'

import IntegrationWrapper from './IntegrationWrapper';



//props.token // {amount,tokenName,tokenURL,contractAddress}
// to close: closeWalletFunc()
function SendViewAuswahl(props){

    //const allFriends = useAllMyFriends()

    function onFriendClick(friend_addr){
        props.onFriendClick(friend_addr,props.token)
    }

    return(
        <IntegrationWrapper onClickBack={props.onClickBack} headerText={props.token? `send ${props.token.tokenName}` : "send ether"} >

                <div style={{height:"100%",width:"100%",overflowY:"scroll"}}>
                    < FriendList onFriendClick={onFriendClick}/>
                </div>
        </IntegrationWrapper>

    )
}

function FriendElement(props){


    return(
        <div className={classes.friendElement}>

            {props.friend.friend_name}
            {props.friend.friend_addr}


        </div>

    )
}



export default SendViewAuswahl