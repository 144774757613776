
import {fetchi} from '../globalData'
import {getOptions} from './databank'
import {loadNameFromDB2} from './databank'
import {decryptedMessage_Metamask,encryptMessage_Metamask,encryptMessage_MCBWallet,decryptMessage_MCBWallet} from '../encryption'

import {getUsernameDB} from '../node/username'

//if no Messages amount = 0
// return return {messages:messages[0],amount:amountMessages}
//message = {message,from,to,date}
async function loadMessagesFromDB(me,partner,limit,offset){

    var messages = await fetch(fetchi+ "/databank",getOptions("getMessages",{from: me, to: partner,limit:limit,offset:offset} )).then(res => {return res.json()}).catch(console.log);
    const amountMessages =messages[0].length
    messages = messages[0]
    messages = messages.reverse()

    //Load Also names from DB
    const partnerName = await loadNameFromDB2(partner)


    messages = messages.map((ele)=> {
        ele.von === me ? ele.sender="me" : ele.sender=partnerName.friend_name
        return ele
    })
    const erg = {messages:messages,amount:amountMessages}
    //console.log(erg.messages)

    return erg
}


async function sendMessageToDB(message){
    console.log("sendMessageToDB Message: ")
    console.log(message)
    var res = await fetch(fetchi+ "/databank",getOptions("sendMessage",message )).then(res => {return res.json()}).catch(console.log);
}





export async function encryptMessageForDB_orForReactiveSend(message){

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    
    var senderMessage
    var receiverMessage
    if(userdata.metamask){ // Fehler. IIch muss auch gucken, das der Receiver ist !!!
        senderMessage = await encryptMessage_Metamask(message.message,message.from) //mit Public Key von Sender.
    }else{
        senderMessage = await encryptMessage_MCBWallet(message.message,message.from) //mit Public Key von Sender.
    }
    const usernameReceiver = await getUsernameDB(message.to) //return undefinded if not exist
    console.log("usernameReceiver")
    console.log(usernameReceiver)

    if(usernameReceiver){ // is MCB Wallet
        receiverMessage = await encryptMessage_MCBWallet(message.message,message.to) // mit Public Key von empfänger
        
    }else{ // is Metamask
        console.log("Ich verschlüssele FÜR METAMASK")

        receiverMessage = await encryptMessage_Metamask(message.message,message.to) // mit Public Key von empfänger
    }


    message.senderMessage=senderMessage
    message.receiverMessage=receiverMessage
    message.message ="" // delete real message
    console.log("sendMessageToDBEnrcypt2")
    console.log(message)

    return message

}

export async function sendMessageToDBEnrcypt(message){

    const userdata = JSON.parse(sessionStorage.getItem("userdata"))
    
    var senderMessage
    var receiverMessage
    if(userdata.metamask){ // Fehler. IIch muss auch gucken, das der Receiver ist !!!
        senderMessage = await encryptMessage_Metamask(message.message,message.from) //mit Public Key von Sender.
    }else{
        senderMessage = await encryptMessage_MCBWallet(message.message,message.from) //mit Public Key von Sender.
    }
    const usernameReceiver = await getUsernameDB(message.to) //return undefinded if not exist
    console.log("usernameReceiver")
    console.log(usernameReceiver)

    if(usernameReceiver){ // is MCB Wallet
        receiverMessage = await encryptMessage_MCBWallet(message.message,message.to) // mit Public Key von empfänger
        
    }else{ // is Metamask
        console.log("Ich verschlüssele FÜR METAMASK")

        receiverMessage = await encryptMessage_Metamask(message.message,message.to) // mit Public Key von empfänger
    }






    message.senderMessage=senderMessage
    message.receiverMessage=receiverMessage
    message.message ="" // delete real message
    console.log("sendMessageToDBEnrcypt2")
    console.log(message)




    var res = await fetch(fetchi+ "/databank",getOptions("sendMessageEnrcypt",message )).then(res => {return res.json()}).catch(console.log);

    console.log("sendMessageEnrcypt")

    console.log(res)
}



//messages : {message,von,zu,date,id,receivermessage,sendermessage}
export async function loadMessagesFromDBEncrypt(me,partner,limit,offset){

    var messages = await fetch(fetchi+ "/databank",getOptions("getMessages",{from: me, to: partner,limit:limit,offset:offset} )).then(res => {return res.json()}).catch(console.log);
    const amountMessages =messages[0].length
    messages = messages[0]
    messages = messages.reverse()



    messages = messages.map(ele=>{

        return {message: decryptedMessage_Metamask(ele.sendermessage) , von:ele.von,zu:ele.zu,date:ele.date,id:ele.id,receivermessage:ele.receivermessage,sendermessage:ele.sendermessage}
    })

    console.log(messages)

    //Load Also names from DB
    const partnerName = await loadNameFromDB2(partner)

    messages = messages.map((ele)=> {
        ele.von === me ? ele.sender="me" : ele.sender=partnerName.friend_name
        return ele
    })



    const erg = {messages:messages,amount:amountMessages}
    //console.log(erg.messages)

    return erg
}



// return {messsage,von,zu,date,id}
async function getLatestMessage(partner){

    const me = JSON.parse(sessionStorage.getItem("userdata")).address

    var latesMessage = await fetch(fetchi+ "/databank",getOptions("getLatestMessage",{from: me, to: partner}  )).then(res => {return res.json()}).catch(console.log);

    return latesMessage[0][0] || {message:""};

}





export {loadMessagesFromDB};
export {sendMessageToDB};
export {getLatestMessage};

