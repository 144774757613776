import classes from './ProfilData.module.css';
import settingsPic from '../../images/settings2.png';
import savePic from '../../images/save.png';
import StandartProfilPic from '../../images/background.jpeg';
import etherSign from '../../images/Crypto-Icons/eth-logo.svg';
//import {onLoad} from '../../web3/LoadingFunctions'
import {shortAddr} from '../../web3/LoadingFunctions'
import coverImage from '../../images/image.png';
import React, {useState,useEffect,useHistory,useContext} from 'react';
import {query,getOptions,queryFetch} from '../../node/databank';
import {getProfilePicURL} from '../../node/images'
import {getProfilePic} from '../../customHooks/useProfilePicture'
import useProfilePicture from '../../customHooks/useProfilePicture'


import useProfilePic from '../../customHooks/useProfilePicture';
import black_herz from '../../images/backherz.png'
import {fetchi} from '../../globalData'
//popup
import PopupFenster from '../PopupFenster/PopupFenster'
import LikesIntegration from '../PopupFenster/LikesIntegration'
//material UI 
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
//Night Mode
import useNightMode from '../../customHooks/useNightMode';
//User Context
import {UserContext} from '../../UserProvider'
// Components
import ProfilePicture from './ProfilePicture';
import UsernameField from './UsernameField';

//custom Hooks
import useMyFollowerList from '../../customHooks/useMyFollowerList';

function ProfilData(){
    const userData = useContext(UserContext)
    const theme = useNightMode()

    const [username,setUsername] =useState("");
    const [followListOpen,setFollowListOpen]= useState(false);
    const [profilePicSource,setProfilePicSource] = useState(StandartProfilPic)

   // const profilePicSource = useProfilePicture("me")

    const myFollowerList = useMyFollowerList()



    function closeFollowList(){
        setFollowListOpen(false);
    }
    function openFollowList(){
        setFollowListOpen(true);
    }

    // on Load get name from databank
    function loadNameFromDB(){
            const options=getOptions("find",{address: userData.address });
            fetch(fetchi+ "/databank",options).then(res => { return res.json()}).then(res=>{
                if(res[0].length===0){
                    setUsername("unnamed");
                }else{
                    setUsername(res[0][0].name);
                }
            });
    }
    function loadProfilePic(){
        getProfilePic("me").then(url =>{           
            setProfilePicSource(url)   
        })
    }

    useEffect(() => {
        loadProfilePic()
        loadNameFromDB()
    },[userData.address])

    const [copyClicked,setCopyClicked] = useState(false);
    function copyAddress(){
        copyToClipboard(userData.address);
        setCopyClicked(true)
    }

    function copyToClipboard(text) {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          navigator.clipboard.writeText(text)
            .then(() => {
              console.log('Text copied successfully!');
            })
            .catch((err) => {
              console.error('Error using Clipboard API, falling back', err);
              copyTextFallback(text);
            });
        } else {
          copyTextFallback(text);
        }
    }
    function copyTextFallback(text) {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        
        // Positioniere das Element weit außerhalb des sichtbaren Bereichs,
        // damit es keine Layout-Verschiebungen verursacht.
        textArea.style.position = "absolute";
        textArea.style.top = "-1000px";
        textArea.style.left = "-1000px";
        textArea.style.width = "1px";
        textArea.style.height = "1px";
        textArea.style.opacity = "0";
        textArea.setAttribute('readonly', ''); // optional, um Fokusprobleme zu vermeiden
      
        document.body.appendChild(textArea);
        textArea.select();
      
        try {
          const successful = document.execCommand('copy');
          console.log(successful ? 'Copy succeeded' : 'Copy failed');
        } catch (err) {
          console.error('Fallback: Unable to copy', err);
        }
        document.body.removeChild(textArea);
      
    }
    function closeTooltip(){
        setTimeout(() => {setCopyClicked(false)},200)
    }

    return (
        <div style={{backgroundColor:theme.color1}} id="cont" className={classes.container}>
            {/* Follow List Popup*/}
            {followListOpen && <PopupFenster text={"My Followers"} onCloseClicked={closeFollowList} integration={<LikesIntegration likesList={myFollowerList}/>} /> }
            {/*Background Picture */}
            <div className={classes.greyBox}>
             <img className={classes.backgroundPic} src={profilePicSource}></img>
            </div>
            
            {/*ProfilePicture */}
            <div className={classes.profilePicture}>
                <ProfilePicture profilePicSource={profilePicSource} setProfilePicSource={setProfilePicSource}/>
            </div>
            {/*Username */}
            <div  style={{color:theme.font}}>
                <UsernameField username={username} setUsername={setUsername}/>
            </div>
            {/*Crypto Address */}
            <Tooltip onClose={closeTooltip} title={copyClicked ? "Copied!" : "Copy"} disableInteractive arrow placement="bottom">
                <Button onClick={copyAddress} sx={{gap:'10px',border:'1px solid black'}}> <img id="cryptoSign" src={etherSign} className={classes.cryptoSign}></img> {shortAddr(userData.address)}</Button>
            </Tooltip>
            {/*Follow Wrapper */}
            <div className={classes.followWrapper}>
                    <img src={black_herz} className={classes.herz}></img>
                    <div style={{color:theme.font}} onClick={openFollowList} className={classes.text}> {"My Followers"} </div>
            </div>
        </div>
    );
}
export default ProfilData;

