import classes from '../ChatPage.module.css';
import React, {useState,useEffect,useContext,useLayoutEffect,useRef} from 'react';
import { useHistory } from "react-router-dom";
import Message from '../../../components/chatPage/Message'


import useProfilePic from '../../../customHooks/useProfilePicture'


import backImg from '../../../images/zuruck.png'
import {shortAddr} from '../../../web3/LoadingFunctions'
import {loadMessagesFromDB,loadMessagesFromDBEncrypt,sendMessageToDB,sendMessageToDBEnrcypt,encryptMessageForDB_orForReactiveSend} from '../../../node/cryptoMessages'

//popup
import PopupFenster from '../../../components/PopupFenster/PopupFenster'
import EncryptionPWIntegration from './EncryptionPWIntegration';
//mui
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import BadgeAvatars from './AvatarWithOnline'
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import ChatLoadingIndicator from './ChatLoadingIndicator';
import { FaLock, FaLockOpen } from 'react-icons/fa';

import ToggleButton from './ToggleButton'; 

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'

//User Context
import {UserContext} from '../../../UserProvider'
import MyVisible from './MyVisible'

import io from "socket.io-client";

import {fetchi} from '../../../globalData'

const socket = io(fetchi);




const OFFSET = 20
const LIMIT = 20




//id: latest Message
//selectedFriend: {friend_name: 'asdfasdf', friend_addr: '0x12f74dee165e21a3be88cfcfc53a87e4dbedcc37', blockchain: true, id: 28}
function ChatFenster({chatWindowRef,closeChatWindow,friendIsSelected,selectedFriend,messageSend_toUpdateFrindsList,chatOpen}){
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
    const userData = useContext(UserContext)
    const history =useHistory();
    //States
    const [inputValue,setInputValue] = useState("")
    const [currentMessages,setCurrentMessages] = useState([]) //[{message,from,to,date}]
    const [startMessages,setStartMessages] = useState([]) //[{message,from,to,date}]

    const [msgOffset,setMsgOffset] =useState(0)
    const [encryptionIntegrOpen,setEncryptionIntegrOpen] =useState(false)
    const [passwordSetFromPopUp,setPasswordSetFromPopUp] =useState(false) // ifSet go to messages and entcrypt all
    const [metamaskDecryptonClicked,setMetamaskDecryptonClicked] =useState(false) // ifSet go to messages and entcrypt all
    const [isLoadingMessages,setIsLoadingMessages] =useState(true)
    const pic = useProfilePic(selectedFriend.friend_addr)



    const scrollContainerRef = useRef(null)
    const endOfMessagesRef= useRef(null)

    useEffect(()=>{
        updateVh()
    },[])
    function updateVh() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    window.addEventListener('resize', updateVh);

    // Nur bei neurendern und beim senden einer Nachricht. Nicht beim neuladen beim Nochscrollen!!
    function scrollDown(){
        requestAnimationFrame(() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
            }
          });
    }


    function scrollDownTimout(time){
    // setTimeout gibt dem Browser Zeit, die Elemente zu rendern
    const timeout = setTimeout(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'auto' });
        }
        }, time); // 50ms Delay, ggf. anpassen
        return () => clearTimeout(timeout);
    }

    useEffect(() => {
        scrollDownTimout(700)
      }, []);

 




    const initialValueForEncryptionMode = () => {
        const storedValue = localStorage.getItem('encryptionMode');
        return storedValue ? JSON.parse(storedValue) : true;
    };
    const setsetEncryptionModeState = (newValue) => {
        localStorage.setItem('encryptionMode', JSON.stringify(newValue));
        setEncryptionMode(newValue);
    };
    const [encryptionMode,setEncryptionMode] =useState(initialValueForEncryptionMode) // For sent messages




    function decryptButtonClicked(){
        //Check if Metamask or MCB Wallet
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))
        if(userdata.metamask){
            setMetamaskDecryptonClicked(true) // Set State for metamaskDecryptonClicked
        }else{
            setEncryptionIntegrOpen(true) // open PopUp for Passwort
        }
    }

    function closePopupEncrypt(value){
        setEncryptionIntegrOpen(false)
    }
    function encryptAllMessages(pw){
        setPasswordSetFromPopUp(pw)
    }



    const [isChatPartnerOnline,setIsChatPartnerOnline] =useState(false)
    // For Reactive Chat App
    useEffect(() => {

        setIsChatPartnerOnline(false)

        socket.connect();

        socket.emit("setUserId", userData.address);


        // Benutzer öffnet einen Chat
        socket.emit("openChat", { userId:userData.address, chatPartnerId:selectedFriend.friend_addr });

        // Direkt den Online-Status des Chatpartners anfragen
        // socket.emit("checkUserOnline", selectedFriend.friend_addr);

        // // Antwort auf Online-Status empfangen
        // socket.on("userStatusResponse", ({ userId: checkedUserId, isOnline }) => {
        //     console.log("userStatusResponse: " + checkedUserId + " .. " + isOnline)
        // if (checkedUserId === selectedFriend.friend_addr) {
        //     setIsChatPartnerOnline(isOnline);
        // }
        // });

        // Online-Status von Chatpartner empfangen
        socket.on("updateUserStatus", ({ userId: updatedUserId, isOnline }) => {
            console.log("updateUserStatus1")
            console.log("updateUserStatus: " + updatedUserId + " .. " + isOnline)
            if (updatedUserId === selectedFriend.friend_addr) {
            setIsChatPartnerOnline(isOnline);
            }

            console.log("updateUserStatus: " + updatedUserId + " .. " + isOnline + "vgl.: " +updatedUserId === selectedFriend.friend_addr)
        });



        // Neue Nachrichten empfangen
        socket.on("receiveMessage", (message) => {

            // Empfängte alle nachrichten an mich. Desswegen checke ich hier, ob diese Nachricht für den aktuellen Chat ist
            console.log("message1")
            
            console.log(message)
            messageSend_toUpdateFrindsList(message)
            
            if(selectedFriend.friend_addr !== message.from){
                return
            }

            message.sender = selectedFriend.friend_name
            message.sendermessage = message.senderMessage
            message.receivermessage = message.receiverMessage
            setCurrentMessages(prevMessages=>[...prevMessages,message]   )
            scrollDownTimout(40)

        });
        return () => {
            console.log("Unmount")
          socket.off("receiveMessage");
          socket.emit("closeChat", userData.address);
          socket.off("updateUserStatus");
          socket.disconnect();
        };
      },[selectedFriend.friend_addr]);

      async function sendMessageReactive (from,to,message){


        var sendMessage 
        if(encryptionMode){
            sendMessage = await encryptMessageForDB_orForReactiveSend(message)
        }else{
            sendMessage = message
        }


        socket.emit("sendMessage", {
          sender: from,
          receiver: to,
          message: sendMessage,
        });
        
      };


    // send Message
    async function send(text){

        const myAddress = userData.address
        var message = {message:text,from:myAddress,to:selectedFriend.friend_addr,date:new Date().toLocaleString('en-US', { timeZone: 'UTC' })} //new Date().toLocaleString('en-US', { timeZone: 'UTC' })
        

        // For Reactive Chat App
        sendMessageReactive(myAddress,selectedFriend.friend_addr,message)
       
        messageSend_toUpdateFrindsList(message) // um die aktuellste Nachricht in der Freundesliste zu aktuallisieren
        // Send MEssage to DB
        if(encryptionMode){
            sendMessageToDBEnrcypt(message)
            message.justSendEncrypted_butUnlocked = true

        }else{
            sendMessageToDB(message)
        }
        // Also add direkt to State, that I see my new message immediatley. add message.sender, which also happens in cryptomessages.js
        message.sender="me"
 
        setCurrentMessages(prevMessages=>[...prevMessages,message]   ) // um den aktuellen chat zu aktualsieren
        

        scrollDownTimout(40)
    }
    function sendWithEnter(e){
        if(e.key === "Enter"){
            send(e.target.value)
            setInputValue("")
        }
    }
    function inputChanged(e){
        setInputValue(e.target.value)
    }
    function openProfile(){
        history.push(`/profile/${selectedFriend.friend_addr}`)
    }

    // Friend Change = MsgOffset=0 & currentMessages = []
    useEffect(()=>{
        setMsgOffset(0)
        setCurrentMessages([])
        console.log("Friend Change __ offset =0")
    },[selectedFriend.friend_addr])

    function iAmVisible(){
        console.log("I AM Visbile")
        setMsgOffset(prevmsgOffset => prevmsgOffset+OFFSET)
    }


    // load messages
    async function loadMessages(){

        setIsLoadingMessages(true)
        

        var bottom =0
        const myAddress = userData.address
        const partnerAddress = selectedFriend.friend_addr;
        // bevore new message calculate Bottom
        var messageArea = document.getElementById('messageArea');
        bottom = messageArea.scrollHeight -messageArea.scrollTop -messageArea.clientHeight
        // load Messages from DB
        const res = await loadMessagesFromDB(myAddress,partnerAddress,LIMIT,msgOffset) //{messages,amount}
        console.log("First")
        console.log(res)


        setCurrentMessages(prevMessages=>[...res.messages,...prevMessages])
        // after new messages Load set scrollTop
        setIsLoadingMessages(false)
        

        setTimeout(() => {

            messageArea.scrollTop = messageArea.scrollHeight - bottom - messageArea.clientHeight

            }, 100)
         
           

        return res;

    }

    // Load Messages everyTime SelectedFriend and msgOffset changes
    useEffect(() =>{

        setTimeout(() => { // nur um Verzögerung zu testen

            loadMessages()

            }, 0)
        
    },[selectedFriend.friend_addr,msgOffset])

    console.log("currentMessages")
    console.log(currentMessages)


return(
<div  className={chatOpen? classes.chatWindowOpen : classes.chatWindow} ref={ chatWindowRef} style={{backgroundColor:theme.color1}} >


    {encryptionIntegrOpen && <PopupFenster text={"Enter Passwort to encrypt"} onCloseClicked={closePopupEncrypt} integration={<EncryptionPWIntegration encryptAllMessages={(pw)=>{encryptAllMessages(pw)}} onClose={closePopupEncrypt}/>} />  }

    {/*chatPartner */}
    <div className={classes.chatPartner} style={{borderBottom:theme.border}}>

    <div style={{display:"flex"}} >
    {/*BackButton */}
    <div className={classes.backButton}><IconButton onClick={closeChatWindow}> <img style={{height:'25px',width:'auto',filter:theme.png}} src={backImg}></img>  </IconButton></div>
    {friendIsSelected &&
        <div style={{display: 'flex',alignItems:'center',cursor:'pointer'}} onClick={openProfile}>

            <div style={{marginLeft:'10px',marginRight:'10px'}}>
            <BadgeAvatars src={pic} online ={isChatPartnerOnline} sx={{height:'33px',width:'33px'}}/>
            </div>
            <div>
                <div className={classes.friendName} style={{color:theme.font}}>{selectedFriend.friend_name}</div>
                <div style={{opacity:'0.6',fontSize:'11px',color:theme.font}}>{shortAddr(selectedFriend.friend_addr)}</div>
            </div>
        </div>
    }
    </div>

    <Tooltip  title={"Click to decrypt all messages"} placement="top" disableInteractive arrow>
        <IconButton onClick={decryptButtonClicked}  sx={{marginRight:'10px',fontSize:"13px",color:theme.fontGrey}}> <FaLock style={{color:theme.fontGrey,marginRight:'3px',}}/> {"Decrypt All"} </IconButton>
    </ Tooltip>
    </div>

    {/*messageArea */}
    <div ref={scrollContainerRef} id="messageArea" className={classes.messageArea} style={{borderBottom:theme.border}}>

        < ChatLoadingIndicator isLoadingMessages={isLoadingMessages}  currentMessages={currentMessages} />

        { false &&<Button onClick={()=>{setMsgOffset(prevmsgOffset => prevmsgOffset+OFFSET)}} sx={{width:'200px',position:'relative',left:'50%',transform: 'translate(-50%, 0)'}}>load older messages</Button>
        }

        <MyVisible iAmVisible={iAmVisible} scrollContainerRef={scrollContainerRef}/>

        {false && <CircularProgress sx={{width:'20px',position:'relative',left:'50%',transform: 'translate(-50%, 0)'}}/>  }

    {  currentMessages.map(item =>{

        return <Message key={item.id} 
        metamaskDecryptonClicked={metamaskDecryptonClicked} 
        decryptButtonClicked={decryptButtonClicked}  
        encryptAllWithPassword={passwordSetFromPopUp} 
        person={item.sender} message={item.message} date={item.date} item={item}

        />

    })}
    <div ref={endOfMessagesRef} ></div>
    </div>

    {/*messageField */}
    <div className={classes.messageField} style= {{backgroundColor: theme.color1 }} >
        <TextField  
        inputProps={{ style: { color: theme.font,backgroundColor: theme.color1 }}} id="outlined-multiline-flexible" label="Your Message" fullWidth maxRows={3} value={inputValue} onChange={inputChanged} onKeyDown={sendWithEnter}>
        </TextField>

     


    < ToggleButton encryptionMode={encryptionMode} onToggle={(value)=>{setsetEncryptionModeState(value)}}/>

    { false && <Switch checked={encryptionMode} onChange={(e)=>setEncryptionMode(e.target.checked)}/>}
    
    </div>


</div>
)
}



export default ChatFenster;

