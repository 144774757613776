import * as React from 'react';
import { useRef,useEffect,useContext,useState } from 'react'
import IconButton from '@mui/material/IconButton';
import classes from './MyButton.module.css'
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

import brieftascheImg from '../../images/brieftasche.png'

//popup
import PopupFenster from '../PopupFenster/PopupFenster'
//import LoginIntegration from '../PopupFenster/LoginIntegration'
import LoginIntegration from '../../components/aufklappMenu/LoginIntegration'


//props.menuIsOpen
export default function MyButton(props){

  // Night Mode
  const nightMode = useContext(NightContext)
  const [theme,setTheme] =useState(themes.bright)
  useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  const [isHovered, setIsHovered] = useState(false);
  const [loginPageIsOpen, setLoginPageIsOpen] = useState(false);



  function openLoginPage(){
    setLoginPageIsOpen(true)
  }


  return (
    <>
    { loginPageIsOpen && <PopupFenster integration={<LoginIntegration nextPage={"/home"} onCloseClicked={()=>{setLoginPageIsOpen(false)}} />} onCloseClicked={()=>{setLoginPageIsOpen(false)}} text={"Connect Wallet"}/>   }
    
    <div  
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={openLoginPage}
    className={classes.menuButton} style={{
      backgroundColor: isHovered? theme.hoverButton: theme.color2
      
      }}>

      <img style={{height: '28px',width: 'auto',filter:theme.png}} src={brieftascheImg}></img>

        <div className={classes.text} style={{color:theme.font}}>
          Login
        </div>
    </div>
    </>
  );
}