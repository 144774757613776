import classes from './FriendListElement.module.css';

import {shortAddr} from '../../web3/LoadingFunctions';
import {useHistory} from 'react-router-dom';
import {useEffect,useState} from 'react'


import useProfilePicture from '../../customHooks/useProfilePicture'



import StandartProfilPic from '../../images/background.jpeg';
function FriendListElement(props){


    const profilePic = useProfilePicture()
    
    function send(){
        props.send(props.friendItem.friend_addr,props.tokenId);
    }


    return (

        <div  onClick={send} className={classes.container}>

            <img src={profilePic} className={classes.profilePicture}></img>



            <div className={classes.container2}>
                <div className={classes.name}>{props.friendItem.friend_name}</div>

                <div className={classes.addresse}>{shortAddr(props.friendItem.friend_addr)}</div>
            </div>

        </div>


    )


}

export default FriendListElement;