import * as React from 'react';
import ReactDOM from 'react-dom';
import {useEffect,useRef,useState,useContext} from 'react'
import classes from './Wallet.module.css'
//img
import keyImg from '../../images/schlussel.png'
//material UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// Wallet Views
import AmountView from './AmountView'
import ExtractKey from './ExtractKey'
//Componenten
import WalletAuswahl from './WalletAuswahl';
//import UniswapSwapWidget from './UniswapSwapWidget'
//User Context
import {UserContext} from '../../UserProvider'
//Custom Hooks 
import useProfilePic from '../../customHooks/useProfilePicture';
import useNightMode from '../../customHooks/useNightMode';
import useWeb3 from '../../customHooks/useWeb3';

//props.walletOpen:Boolean
//props.closeWalletFunc:Function
export default function Wallet(props) {

    const web3 = useWeb3()
    const [currentView,setCurrentView] = useState({current: props.integration , before: null})
    const userData = useContext(UserContext)
    const theme = useNightMode()
    const profilePicURL = useProfilePic("me")
    const wallet = useRef()
    const backdrop = useRef()

    function closeWallet(){
        if(window.innerWidth <= 800){
            wallet.current.style.top= '1000px';
        }else {
            wallet.current.style.right= '-500px';
        }
        backdrop.current.style.opacity= '0'

        setTimeout(()=>{props.closeWalletFunc()},500)
    }


    function openExtractKey(){
        setCurrentView(prevState => ({current: <ExtractKey goBack={goBack} />, before: prevState.current}))
    }

    function goBack(){
        setCurrentView(prevState => ({current: prevState.before, bevore: null}))
    }

    
    useEffect(()=>{
        window.addEventListener("scroll", updateVh);
        return () => {
            window.removeEventListener("scroll", updateVh);
          
        };
    },[])
    function updateVh() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    return ReactDOM.createPortal(
    <div className={classes.container}>

        <div ref={backdrop} onClick={closeWallet} className={classes.backdrop}> </div>


        {/*wallet*/}
         <div style={{backgroundColor:theme.color1opacity}}  ref={wallet} className={classes.wallet}>  

        
            {/*header*/}
            {!props.notification &&
            <div  className={classes.header}>
                <div style={{display: 'flex',alignItems: 'center'}}>
                    <Avatar src={profilePicURL}  sx={{ width: 33, height: 33,marginLeft:'10px',marginRight:'10px' }}  />
                    <div style={{}}>
                        <div style={{fontSize:'22px',color:theme.font}} >My Wallet</div>
                        { userData && <div style={{fontSize:'12px',color:theme.font}}>{userData.metamask === true ? "Metamask": "Integrated Wallet"}</div> }
                    </div>
                </div>
                <div>
                <Tooltip title="Copy" disableInteractive arrow placement="left" >
                    <Button  >{userData.shortAddress}</Button>
                </Tooltip>
                
                <Tooltip title="Copy" placement="right">
                    <IconButton disabled={userData.metamask}  onClick={openExtractKey} sx={{marginRight:'10px'}} ><img src={keyImg} style={{height: '20px',width: 'auto',filter:theme.png}}></img></IconButton>
                </Tooltip>

                </div>
            </div>
            }
            {!props.notification &&
            < WalletAuswahl currentView={currentView} setCurrentView={setCurrentView}/>
            }

            {!props.notification && !currentView.current &&
                <AmountView currentView={currentView} setCurrentView={setCurrentView}/>
            }

            {/*Integration*/}
            {true && currentView.current }
        
        </div>  {/*wallet*/}
    </div>,
    document.getElementById('portal-root')
    
  );
}
