import React, { useState, useRef, useEffect ,useContext ,} from 'react';
import classes from './NFTUbersicht.module.css';
import {getAllSingles,getTokenIdFromSearchLimit,
} from '../../../node/NFTData';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'

const LIMIT_LOAD = 15



function NFTSearchbar(props){
  // Night Mode
  const nightMode = useContext(NightContext)
  const [theme,setTheme] =useState(themes.bright)
  useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])


  const [searchValue,setSearchValue] =useState("")

  function search(e){
    if(e.key!== "Enter"){
        return;
    }
    props.setLoadOffset(0)
    props.setSearchValue(e.target.value)
    setSearchValue(e.target.value)

}



  const [prevSearch,setPrevSearch] = useState("")

  useEffect(()=>{
    searchSingle()
  },[searchValue, props.loadOffset])





  // search in nftInfo Database
  async function searchSingle(){
    var newLoaded =[]

    if(searchValue === ""){ // Wieder alle NFTs laden  // am Anfang: false. Wenn search ="" ausser beim 1.rendern

      newLoaded =[]
      props.setSearchedTokenList(newLoaded)
      return
      
    }else if(searchValue !== ""){ // Suche laden

      console.log("props.user")

      console.log(props.user)
      if(props.user){
        console.log("tokenList_myNFT")

        console.log(props.tokenList_myNFT) //[{tokenId:string}]
      }

      newLoaded = await getTokenIdFromSearchLimit(searchValue,LIMIT_LOAD,props.loadOffset);
    }

    if(searchValue !== prevSearch ){ // if neue Suche von vorne anhängen
      //setSearchResult(newLoaded)
      props.setSearchedTokenList(newLoaded)
      setPrevSearch(searchValue)
    }
    else{ // if offload erhöhr und keine neue Suche dann hinten anhängen
      //setSearchResult(searchResult => [...searchResult,...result])
      props.setSearchedTokenList(prev => [...prev,...newLoaded])
    }
    props.setLoading(false)

  }

 


 


return(


  <input style={{backgroundColor:theme.color2,color:theme.font,border:theme.border}} id="searchInput" className={classes.textInput} 
  placeholder="search items" onKeyDown={search} ></input>

);

}


export default NFTSearchbar;
