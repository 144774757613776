import classes from './NFTUbersicht.module.css';
import {useState,useEffect,useContext,useRef} from 'react'
import {highestTokenId,getTokenIdFromSearch,getAllMyTokenIDs_On_Off_chain} from '../../../node/NFTData'
import Single from './Single';
import Collections from './Collections';
import { useHistory } from "react-router-dom";
import Squares2 from '../../../images/2square.png'
import Squares3 from '../../../images/3square.png'
//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'
// Comps
import NFTFilter from './NFTFilter'
import NFTSearchbar from './NFTSearchbar';
import MyVisible from './MyVisible';
//material UI
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';
// Values
const LIMIT_LOAD = 15

// Das ist die NFT Übersicht für MyNFT. Die für den Marketplace heißt : NFTUbersicht_Market

// NFTUbersicht is used for MarketPlace and for MYNFTPAGE
//if not MarketPlace then props.user is true
function NFTUbersicht(props){
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

    // States
    const [loading,setLoading] = useState(true)
    const gridRef = useRef()
    //Button Toggle Collect/Sinlge
    const [alignment,setAlignment] = useState('single');

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            setSearchValue("")
          setAlignment(newAlignment);
        }
        if(newAlignment === 'single'){
            setCollectionMode(false)
            setSingleMode(true)

        }else if (newAlignment === 'collection'){
            setCollectionMode(true)
            setSingleMode(false)
        }
        else if (newAlignment === 'liked' || newAlignment === 'created'){
            // if liked or created stay in single Mode
            setCollectionMode(false)
            setSingleMode(true)
        }
    };


    const [singleMode,setSingleMode] = useState(true);
    const [collectionMode,setCollectionMode] = useState(false);
    // offset: select * from nftinfo limit 3 offset 3
    const [loadOffset,setLoadOffset] = useState(0);
    const [searchValue,setSearchValue] = useState("");

    const [tokenList,setTokenList] = useState([]); // wird in NFT FILTER Gesetzt
    const [searchedTokenList,setSearchedTokenList] = useState([]); // wird in NFT FILTER Gesetzt

    const [filter, setFilter] = useState(null); // onChain, offChain, myLikes
    const [sort, setSort] = useState(null); // mostLikes, recency

    console.log("sort")

    console.log(sort)

    // For User Mode
    const [tokenList_myNFT,setTokenList_myNFT] = useState([]); // wird in NFT FILTER Gesetzt
    const [searchedTokenList_myNFT,setSearchedTokenList_myNFT] = useState([]); // wird in NFT FILTER Gesetzt

    // FOR MYNFT PAGE -userMode
    async function getAllMyTokenIds(){
        const res = await getAllMyTokenIDs_On_Off_chain(props.user) 
        const allMY = res?.map(ele=>{return{tokenid:ele}}) 
        setTokenList_myNFT( allMY.slice(0,loadOffset+LIMIT_LOAD) )

        setLoading(false)
    }
    //User Mode
    useEffect(()=>{
        if(props.user){
            getAllMyTokenIds()
        }
    },[props.user,loadOffset])
    
 

    function loadMore(){
        setLoadOffset(prevOffset => prevOffset + LIMIT_LOAD);
    }

    const [threeFiveVisible,setThreeFiveVisible] = useState(false);
    const [threeFive,setThreeFive] = useState('three');
    const handleThreeFive = (event, newAlignment) => {
        if (newAlignment !== null) {
            setThreeFive(newAlignment);
        }
        if(newAlignment === 'three'){
            gridRef.current.style.gridTemplateColumns= 'repeat(3, 1fr)'
        }else if (newAlignment === 'five'){
            gridRef.current.style.gridTemplateColumns= 'repeat(5, 1fr)'
        }
    };
    useEffect(() => {
        if(window.innerWidth<=1000){
            handleThreeFive(0,'three')
            setThreeFiveVisible(false)
        }else{
            handleThreeFive(0,'five')
            setThreeFiveVisible(true)
        }
    },[])

    var kleiner = false
    var größer = false
    window.addEventListener("resize", ()=>{
        if(window.innerWidth<=1000 && !kleiner){
            kleiner = true
            größer = false
            handleThreeFive(0,'three')
            setThreeFiveVisible(false)
        }
        if(window.innerWidth>=1000 &&!größer){
            größer = true
            kleiner = false
            handleThreeFive(0,'five')
            setThreeFiveVisible(true)
        }
    });


    return (
        <div style={{backgroundColor:theme.color1}} className={classes.container}>



            <div style={{backgroundColor:theme.color2}} className={classes.topBox}>

                <div style={{color:theme.font}} className={classes.header}>{props.header}</div>

                <div className={classes.searchBox}>


                <div className={classes.toggleButtonWrapper}>
                <ToggleButtonGroup value={alignment} exclusive onChange={handleAlignment} aria-label="text alignment" sx={{height:'40px',width:'100%'}} >
                    <ToggleButton value="collection" aria-label="left aligned" sx={{width:'50%'}}>
                    <div style={{color:theme.font}}>Collection</div>
                    </ToggleButton>
                    <ToggleButton value="single" aria-label="right aligned" default sx={{width:'50%'}}>
                    <div style={{color:theme.font}}>Single</div>
                    </ToggleButton>
                    {false && props.user && <ToggleButton value="liked" aria-label="right aligned"  sx={{width:'50%'}}>
                    <div style={{color:theme.font}}>Liked</div>
                    </ToggleButton>}
                    {false && props.user && <ToggleButton value="created" aria-label="right aligned"  sx={{width:'50%'}}>
                    <div style={{color:theme.font}}>Created</div>
                    </ToggleButton>}
                 </ToggleButtonGroup>

                {/**NFT FILTER */}
                    <div style={{width:'100%'}}>
                    {   !props.user &&<NFTFilter setSort={setSort} setLoading={setLoading} setLoadOffset={setLoadOffset} loadOffset={loadOffset} setTokenList={setTokenList}/>}
                    </div>
                </div>


                { !props.user &&< NFTSearchbar user={props.user} setTokenList_myNFT={setTokenList_myNFT} tokenList_myNFT={tokenList_myNFT} setLoading={setLoading} setSearchedTokenList={setSearchedTokenList} loadOffset={loadOffset} setLoadOffset={setLoadOffset} setSearchValue={setSearchValue} />
                }
                {threeFiveVisible && 
                <ToggleButtonGroup value={threeFive} exclusive onChange={handleThreeFive} aria-label="text alignment" sx={{height:'40px',marginLeft:'20px'}}>
                    <ToggleButton value="three" aria-label="left aligned" >
                    <img style={{filter:theme.png,height:'20px'}} src={Squares2} ></img>
                    </ToggleButton>
                    <ToggleButton value="five" aria-label="right aligned" default >
                    <img style={{filter:theme.png,height:'20px'}} src={Squares3}></img>
                    </ToggleButton>

                    
                </ToggleButtonGroup>
                }

                

                </div>

                {loading && <LinearProgress color="inherit" sx={{width:'100%',position:'absolute'}}/>}
            </div>

            <div ref={gridRef} className={classes.grid}>


                { singleMode && < Single tokenList_myNFT={tokenList_myNFT} searchedTokenList={searchedTokenList} tokenList={tokenList}  user={props.user} loadOffset={loadOffset} searchValue={searchValue} setLoading={setLoading} />   }

                { props.user && singleMode && < Single userMode searchedTokenList_myNFT={searchedTokenList_myNFT} tokenList_myNFT={tokenList_myNFT}  searchedTokenList={searchedTokenList} tokenList={tokenList}  user={props.user} loadOffset={loadOffset} searchValue={searchValue} setLoading={setLoading} />   }


                { collectionMode && <Collections sort={sort} user={props.user} loadOffset={loadOffset} searchValue={searchValue} setLoading={setLoading} /> }

            </div>

            {false && <Button onClick={loadMore} sx={{marginBottom:'33px'}}variant="outlined">load more</Button>}


            <MyVisible iAmVisible={loadMore} />

        </div>

    );

}

export {MyVisible}
export default NFTUbersicht;

