import React, { useState, useRef ,useEffect,useContext} from 'react';
import classes from './UsernameField.module.css';
import { Edit3 } from 'lucide-react';
import {query} from '../../node/databank';
import {UserContext} from '../../UserProvider'


//ColorTheme - Night Mode
import {themes} from '../../ColorTheme'
import {NightContext} from '../../NightModeProvider'

const UsernameField = (props) => {
  const userData = useContext(UserContext)

  // Night Mode
  const nightMode = useContext(NightContext)
  const [theme,setTheme] =useState(themes.bright)
  useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])

  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef();

  const handleEditClick = (e) => {
    // Verhindern, dass auch der Container den Klick auffängt
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    query("add",{ address: userData.address, username: props.username}); // Datenbank

  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setIsEditing(false);
    }


    query("add",{ address: userData.address, username: props.username}); // Datenbank
  };

  return (
    <div className={classes.container}>
      {isEditing ? (
        <input
          type="text"
          value={props.username}
          onChange={(e) => props.setUsername(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className={classes.input}
          autoFocus

          style={{color:theme.font}}
          
        />
      ) : (
        <div className={classes.usernameDisplay}>
          <span>{props.username}</span>
          <div className={classes.overlay} onClick={handleEditClick}>
            <Edit3 size={20} color={theme.font} />
          </div>
        </div>
      )}
    </div>
  );
};

export default UsernameField;
