import { useState, useEffect } from 'react';
import blockies from 'ethereum-blockies';


export default function getEthereumAvatar(address, { size = 8, scale = 16 } = {}) {

  const icon = blockies.create({
        seed: "address.trim().toLowerCase()1", // Konsistenz der Adresse sicherstellen
        size,
        scale,
  });
  return icon.toDataURL()
}

