// When connect to the local test Backend server use const fetchi ="http://localhost:3001"
// otherwise use const fetchi ="https://backendserverreact.azurewebsites.net"

//const fetchi ="https://backendserverreact.azurewebsites.net"

//const fetchi ="http://localhost:3001"

const fetchi ="https://mcbbackend.azurewebsites.net"


const API_ETHERSCAN = 'PGIPM65RBRWDCMAX852B9A3DXET5IAS3UM'
export {API_ETHERSCAN}


export {fetchi}


const etherscanTX_mainnet = "https://etherscan.io/tx/"
const etherscanTX_sepolia = "https://sepolia.etherscan.io/tx/"


const sepolia = 'sepolia'
const mainnet = 'mainnet'
const CHAIN_ID_mainnet = 1
const CHAIN_ID_sepolia = 11155111



const currentNetwork = {chainName: mainnet, id: CHAIN_ID_mainnet, etherscanTx: etherscanTX_mainnet }

export {currentNetwork}
