import classes from './EncryptionPWIntegration.module.css';
import React, {useState,useEffect,useContext,useLayoutEffect,useRef} from 'react';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'
import {loginDB,PWdecrypt} from '../../../node/username'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


//props.encryptAllMessages(password)
// close: props.closeWalletFunc()
function EncryptionPWIntegration(props){
    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])


    const [password,setPassword] =useState()
    const [loginError,setLoginError] = useState("")   


    async function approve(){
        // checks if PW correct
        if(! await decryptPrivateKey()){ 
            return
        }

        if(props.encryptAllMessages){
            props.encryptAllMessages(password)
            props.onClose()
        }

    }


    // check PW and decrypt Private Key
    async function decryptPrivateKey(){
        const userdata = JSON.parse(sessionStorage.getItem("userdata"))

        // faster version to check PW: hash(PW) is in userdata.pw
        const res = await loginDB(userdata.name,password) // not exist: return "error" else: {name:'',pw:'',publickey,privatekey,address}

        console.log(res)
        if( res === "error"){
            setLoginError("unvalid password")
            return false
        }
        return true
    }

    return (

    <div style={{width:'100%',justifyContent: 'center',display: 'flex'}}>


        <div  className={classes.approve}>

        <div className={classes.pwWrapper}>
            <TextField  value={password} onChange={(e)=>{setPassword(e.target.value);setLoginError("")}} helperText={loginError} error={loginError!==""} label="Password" type="password"  sx={{width:'100%'}}  />
        </div>

        <Button onClick={approve} sx={{width:'90px'}} variant="contained">  Approve</Button>


        </div>


    </div>
    );



}

export default EncryptionPWIntegration;