import { useState, useEffect } from 'react';
import Web3 from 'web3';

// Erstelle die Web3-Instanz mit deinem Alchemy-Endpunkt
const web3 = new Web3("https://eth-mainnet.g.alchemy.com/v2/x28uYs8hGw3RS0qMd6n_7XWicg3GaP3y");


// {amount,tokenName,tokenURL,contractAddress}
export const useMyTokens = (walletAddress) => {
  const [tokens, setTokens] = useState([]);

  useEffect(() => {
    if (!walletAddress) {
      setTokens([]);
      return;
    }

    const fetchTokenData = async () => {
      try {
        let response;
        // Abruf der Token-Bilanzen (ohne Metadaten)
        if (web3.currentProvider.request) {
          response = await web3.currentProvider.request({
            method: "alchemy_getTokenBalances",
            params: [walletAddress, "erc20"],
          });
        } else {
          response = await new Promise((resolve, reject) => {
            web3.currentProvider.send(
              {
                jsonrpc: "2.0",
                method: "alchemy_getTokenBalances",
                params: [walletAddress, "erc20"],
                id: 1,
              },
              (error, result) => {
                if (error) reject(error);
                else resolve(result);
              }
            );
          });
        }

        if (response.result && response.result.tokenBalances) {
          // Filtere nur Token mit non-zero Balance
          let tokensWithBalance = response.result.tokenBalances.filter(token => {
            const balanceStr = web3.utils.hexToNumberString(token.tokenBalance);
            const balance = Number(balanceStr);
            return balance > 0;
          });

          // Für jeden Token, bei dem token.tokenInfo fehlt, rufe die Metadaten separat ab
          tokensWithBalance = await Promise.all(
            tokensWithBalance.map(async (token) => {
              if (!token.tokenInfo) {
                try {
                  let metadataResponse;
                  if (web3.currentProvider.request) {
                    metadataResponse = await web3.currentProvider.request({
                      method: "alchemy_getTokenMetadata",
                      params: [token.contractAddress],
                    });
                  } else {
                    metadataResponse = await new Promise((resolve, reject) => {
                      web3.currentProvider.send(
                        {
                          jsonrpc: "2.0",
                          method: "alchemy_getTokenMetadata",
                          params: [token.contractAddress],
                          id: 1,
                        },
                        (error, result) => {
                          if (error) reject(error);
                          else resolve(result);
                        }
                      );
                    });
                  }
                  token.tokenInfo = metadataResponse.result;
                } catch (e) {
                  console.error("Failed to fetch metadata for token", token.contractAddress, e);
                  token.tokenInfo = {};
                }
              }
              return token;
            })
          );

          // Mappe auf den gewünschten Output
          const formattedTokens = tokensWithBalance.map(token => {
            const tokenInfo = token.tokenInfo || {};
            const tokenName = tokenInfo.name ? tokenInfo.name : token.contractAddress;
            // Nutze tokenInfo.decimals, falls vorhanden – Standardwert 18
            const tokenDecimals = tokenInfo.decimals ? parseInt(tokenInfo.decimals, 10) : 18;
            // Umrechnung des Hex-Werts in einen regulären Wert
            const rawBalanceStr = web3.utils.hexToNumberString(token.tokenBalance);
            const rawBalance = Number(rawBalanceStr);
            const amount = rawBalance / Math.pow(10, tokenDecimals);
            // Erstelle die Token-Logo URL (TrustWallet-Repository)
            let tokenURL = '';
            try {
              const checksumAddress = web3.utils.toChecksumAddress(token.contractAddress);
              tokenURL = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${checksumAddress}/logo.png`;
            } catch (e) {
              tokenURL = '';
            }
            return {
              tokenURL,
              tokenName,
              amount,
              contractAddress: token.contractAddress,
            };
          });

          setTokens(formattedTokens);
        } else {
          setTokens([]);
        }
      } catch (err) {
        console.error("Error fetching tokens:", err);
        setTokens([]);
      }
    };

    fetchTokenData();
  }, [walletAddress]);

  return tokens;
};
