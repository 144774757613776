import React,{useEffect,useContext,useState} from 'react';
import classes from './ChatLoadingIndicator.module.css';

//ColorTheme - Night Mode
import {themes} from '../../../ColorTheme'
import {NightContext} from '../../../NightModeProvider'

const ChatLoadingIndicator = (props) => {

  const [state,setState] =useState(true)

  console.log(props.currentMessages === 0)

  useEffect(()=>{
    if(props.currentMessages){
      if(props.currentMessages.length  === 0){
        setState(false)
      }else{
        setState(true)
      }
    }
  },[props.currentMessages])


    // Night Mode
    const nightMode = useContext(NightContext)
    const [theme,setTheme] =useState(themes.bright)
    useEffect(()=>{ nightMode ? setTheme(themes.dark) : setTheme(themes.bright) },[nightMode])
    
  return (
    <div className={classes.loadingContainer}>
      { props.isLoadingMessages && state && <div className={nightMode? classes.spinnderDark : classes.spinner} ></div> }

    </div>
  );
};

export default ChatLoadingIndicator;
