
import StandartProfilPic from '../../images/background.jpeg';
import React, { useState, useRef, useEffect } from 'react';
import classes from './ProfilePicture.module.css';
import { Edit3 } from 'lucide-react'; // Import des gewünschten Icons

import {uploadProfileImage} from '../../node/images'

const ProfilePicture = (props) => {
  const fileInputRef = useRef();




  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const newPicUrl = URL.createObjectURL(file);
      props.setProfilePicSource(newPicUrl) // to State
      uploadProfileImage(file) // to Databank
    }
  };


  const handleClick = () => {
    // Öffnet das versteckte File-Input-Feld
    fileInputRef.current.click();
  };

  return (
    <div className={classes.container} onClick={handleClick}>
      <img src={props.profilePicSource} alt="Profilbild" className={classes.profilePicture} />
      <div className={classes.overlay}>
        <Edit3 size={24} color="white" />
      </div>
      <input 
        type="file" 
        ref={fileInputRef} 
        onChange={handleFileChange} 
        accept="image/*" 
        style={{ display: 'none' }} 
      />
    </div>
  );
};

export default ProfilePicture;
